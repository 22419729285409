<mat-sidenav-container class="live-container" autosize>
  <mat-sidenav #sidenav mode="side" class="live-sidenav" opened position="start" (opened)="mapResize()"
               (closed)="mapResize()">
    <div *ngIf="!openedTrip">
      <div class="sidebar-title">
        <div class="sidebar-title-icon">
          <div class="sidebar-container">
            <h2>Overall Trips Statuses</h2>
            <mat-icon svgIcon="presentation_icon"></mat-icon>
          </div>
          <span>An overview of the all trips statuses.</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-current-active">
        <div class="sidebar-title-icon"><mat-icon svgIcon="current_active_trip_icon"></mat-icon><h2>Currently Active Trips</h2></div>
        <div class="table border">
          <div class="ml24">
            <span>Total</span>
            <h1 class="total">{{(totalCounts?.En_route.total+totalCounts?.On_Board.total+totalCounts?.On_Location.total+totalCounts?.Wait.total)}}</h1>
          </div>
          <mat-divider></mat-divider>
          <div class="flex-row ">
            <div (click)="filterReservationStatus('En_route')" [class.cursor]="!openedTrip">
              <span>{{totalCounts?.En_route.name}}</span>
              <h1 class="en-route">{{totalCounts?.En_route.total}}</h1>
            </div>
            <div (click)="filterReservationStatus('On_Board')" [class.cursor]="!openedTrip">
              <span>{{totalCounts?.On_Board.name}}</span>
              <h1 class="on-board">{{totalCounts?.On_Board.total}}</h1>
            </div>
            <div (click)="filterReservationStatus('On_Location')" [class.cursor]="!openedTrip">
              <span>{{totalCounts?.On_Location.name}}</span>
              <h1 class="on-location">{{totalCounts?.On_Location.total}}</h1>
            </div>
            <div (click)="filterReservationStatus('Wait')" [class.cursor]="!openedTrip">
              <span>{{totalCounts?.Wait.name}}</span>
              <h1 class="booked">{{totalCounts?.Wait.total}}</h1>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-car-types">
        <div class="sidebar-title-icon"><mat-icon svgIcon="car_types_icon"></mat-icon><h2>Cars Types</h2></div>
        <div class="flex-row table border">
          <div (click)="filterReservationVehicle(2)" [class.cursor]="!openedTrip">
            <span>SUV</span>
            <h1>{{totalVehiclesCounts?.SUV}}</h1>
          </div>
          <div (click)="filterReservationVehicle(1)" [class.cursor]="!openedTrip">
            <span>Sedan</span>
            <h1>{{totalVehiclesCounts?.Sedan}}</h1>
          </div>
          <div (click)="filterReservationVehicle(6)" [class.cursor]="!openedTrip">
            <span>WAV</span>
            <h1>{{totalVehiclesCounts?.WAV}}</h1>
          </div>
          <div (click)="filterReservationVehicle(3)" [class.cursor]="!openedTrip">
            <span>ANY</span>
            <h1>{{totalVehiclesCounts?.ANY}}</h1>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="openedTrip">
      <div class="col-12 sidebar-header">
        <h3 class="col-5 close-trip close" (click)="closeTrip()">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Overall Trips Statuses
        </h3>
        <h3 class="col-7 align-right trip-name">{{openedTripData?.passenger?.name}}'s trip</h3>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="trip-statuses"></mat-icon>
          <h4>Trip Status</h4>
        </div>
        <div class="table border flex center">
          <h4 class="box-border {{openedTripData?.status}}">
            {{statusName(openedTripData?.status)}}
          </h4>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="customer-data"></mat-icon>
          <h4>Customer Data</h4>
        </div>
        <div class="table border">
          <div class="col-12">
            <span>Name</span>
            <h4>{{openedTripData?.passenger?.name}}</h4>
          </div>
          <div class="col-12" *ngIf="openedTripData?.passenger?.emailAddress">
            <span>Email</span>
            <h4>{{openedTripData?.passenger?.emailAddress}}</h4>
          </div>
          <div class="col-12" *ngIf="openedTripData?.passenger?.phoneNumber">
            <span>Mobile</span>
            <h4>{{openedTripData?.passenger?.phoneNumber}}</h4>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="driver-data"></mat-icon>
          <h4>Driver Data</h4>
        </div>
        <div class="table border">
          <div class="col-6">
            <span>Name</span>
            <h4>{{openedTripData?.driver?.first_name}} {{openedTripData?.driver?.last_name}}</h4>
          </div>
          <div class="col-6" *ngIf="openedTripData?.driver?.email">
            <span>Email</span>
            <h4>{{openedTripData?.driver?.email}}</h4>
          </div>
          <div class="col-6">
            <span>Mobile</span>
            <h4>{{openedTripData?.driver?.phone_number}}</h4>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="trip-preferences"></mat-icon>
          <h4>Trip Preferences</h4>
        </div>
        <div class="table border">
          <ul>
            <ng-container *ngFor="let note of openedTripData?.note">
              <li class="preferences-list" *ngIf="note!=''">{{note}}</li>
            </ng-container>
          </ul>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="trips-stations"></mat-icon>
          <h4>Trip Stations</h4>
        </div>
        <div class="table border stations">
          <ng-container *ngIf="openedTripData?.reservation_addresses">
            <div class="grid waypoint-item" *ngFor="let waypoint of openedTripData?.reservation_addresses">
              <div class="col-1" [ngClass]="{'no-dots': statusName(waypoint.type) === 'DROPOFF'}">
                <mat-icon class="active-trip-icon" svgIcon="current_active_trip_icon"></mat-icon>
              </div>
              <div class="col-8 pr-2">
                <h3 class="point-name mb-2">{{statusName(waypoint.type)}}</h3>
                <span class="point-address" *ngIf="waypoint.type!=='EN_ROUTE'">
                  {{waypoint.address.street_1}} {{waypoint.address.postal_code}}
                  ,{{waypoint.address.city}}, {{waypoint.address.region}},
                  {{waypoint.address.country}}
                </span>
                <span class="point-address" *ngIf="waypoint.type==='EN_ROUTE'">
                  {{waypoint.address.address}}
                </span>
              </div>
              <div class="col-3">
                <span class="date-title mb-2">
                  {{ (statusName(waypoint.type) === 'PICKUP' || statusName(waypoint.type) === 'DROPOFF') 
                    ? 'Arriving at:' 
                    : 'Moved by:' }}
                </span>
                <h4 class="date-value text-center">{{utils.clearDate(waypoint.passed_date) ? utils.clearDate(waypoint.passed_date) : '-' }}</h4>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-title-icon">
          <mat-icon svgIcon="actions-log"></mat-icon>
          <h4>Action Log</h4>
        </div>
        <div class="table border">
              <div class="actions-log"><h4>Trip Issued</h4><span>{{ utils.clearDate(openedTripData?.created)}}</span></div>
              <div class="actions-log"><h4>Trip Scheduled Pick Up Time</h4><span>{{utils.clearDate(openedTripData?.scheduled_pickup_time)}}</span></div>
              <div class="actions-log"><h4>Trip Drop Off Time</h4><span>{{utils.clearDate(openedTripData?.dropoff_time)}}</span></div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <button type="button" class="toggle-sidenav {{sidenav.opened}}" mat-button (click)="toggleSideNav()">
    <mat-icon svgIcon="arrow-circle-left"></mat-icon>
  </button>
  <div class="filter-side {{sidenav.opened}}" *ngIf="!openedTrip">
    <div>
      <button class="filter-button opened-{{t.menuOpen}} filter-status-{{filterStatus}}" mat-raised-button [matMenuTriggerFor]="tripStationsMenu" #t="matMenuTrigger">
        <mat-icon svgIcon="trip-station-filter-empty" *ngIf="!filterStatus && !t.menuOpen"></mat-icon>
        <mat-icon svgIcon="trip-station-filter" *ngIf="filterStatus && !t.menuOpen"></mat-icon>
        <mat-icon svgIcon="trip-station-filter-opened" *ngIf="t.menuOpen"></mat-icon>
        Trip Stations
        <span class="shadow-background" *ngIf="filterStatus">1</span>
      </button>
      <mat-menu #tripStationsMenu="matMenu"  class="custom-menu-filter {{filterStatus?'selected':''}} {{sidenav.opened}}">
        <button class="bg-button {{filterStatus}}  {{filterStatus=='Wait'}}" value="Wait" mat-menu-item (click)="filterStatus='Wait';getReservations()">Wait</button>
        <button class="bg-button {{filterStatus}}  {{filterStatus=='En_route'}}" value="En_route" mat-menu-item (click)="filterStatus='En_route';getReservations()">En Route</button>
        <button class="bg-button {{filterStatus}}  {{filterStatus=='OnLocation'}}" value="OnLocation" mat-menu-item (click)="filterStatus='OnLocation';getReservations()">On Location</button>
        <button class="bg-button {{filterStatus}}  {{filterStatus=='On_Board'}}" value="On_Board" mat-menu-item (click)="filterStatus='On_Board';getReservations()">On Boarded</button>
      </mat-menu>
    </div>
    <div>
      <button class="filter-button opened-{{c.menuOpen}} filter-status-{{filterVehicleType}}" #c="matMenuTrigger" mat-raised-button [matMenuTriggerFor]="carTypeMenu">
        <mat-icon svgIcon="car-type-filter-empty" *ngIf="!filterVehicleType && !c.menuOpen"></mat-icon>
        <mat-icon svgIcon="car-type-filter" *ngIf="filterVehicleType && !c.menuOpen"></mat-icon>
        <mat-icon svgIcon="car-type-filter-opened" *ngIf="c.menuOpen"></mat-icon>
        Car Type
        <span class="shadow-background" *ngIf="filterVehicleType">1</span>
      </button>
      <mat-menu #carTypeMenu="matMenu" class="custom-menu-filter-vehicle {{sidenav.opened}}">
        <ng-container *ngFor="let vehicle of vehicle_types">
          <button class="bg-button {{filterVehicleType}} {{filterVehicleType==vehicle.id}}" (click)="filterVehicleType=vehicle.id;getReservations()" value="{{vehicle.name}}" mat-menu-item>{{vehicle.name}}</button>
        </ng-container>
      </mat-menu>
    </div>
    <div *ngIf="filterStatus || filterVehicleType">
      <button class="filter-button reset-filter" mat-raised-button (click)="filterVehicleType=undefined;filterStatus=undefined;searchControl.reset();getReservations();">
        <mat-icon svgIcon="reset-all-filter"></mat-icon>
        Reset All Filter
      </button>
    </div>
  </div>
  <div class="filter-search {{sidenav.opened}}" *ngIf="!openedTrip">
    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Search for a trip, a driver, client or a trip code.</mat-label>
      <input type="text"
             placeholder="Search for a trip, a driver, client or a trip code."
             matInput
             [formControl]="searchControl"
             (keyup.enter)="getReservations()">
      <button matSuffix mat-icon-button aria-label="Clear" (click)="searchControl.value?getReservations():''">
        <mat-icon svgIcon="search_normal_icon"></mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mgl-map
          class="map match-parent"
          [style]="'mapbox://styles/envoyamerica2023/clh69h70v00s201p6agsv0mrl'"
          [zoom]="[4]"
          [center]="mapCenter"
          [cursorStyle]="cursorStyle"
          (mapLoad)="onMapLoad($event)"
  >
    <ng-container *ngFor="let marker of driverMarkers">
      <mgl-marker
        [lngLat]="[marker.position.lng, marker.position.lat]">
        <div>
          <img src="{{marker.options.icon}}" alt="icon" style="padding: 10px;"/>
        </div>
      </mgl-marker>
    </ng-container>
    <mgl-geojson-source *ngIf="data" id="trace" [data]="dataLine">
    </mgl-geojson-source>
    <mgl-layer
      *ngIf="dataLine"
      id="trace1"
      type="line"
      source="trace"
      [paint]="{
          'line-color': '#01a1ff',
          'line-opacity': 0.60,
          'line-width': 3
        }"
    >
    </mgl-layer>
    <mgl-geojson-source *ngIf="data" id="passed" [data]="dataLinePassed">
    </mgl-geojson-source>
    <mgl-layer
      *ngIf="dataLinePassed"
      id="passed1"
      type="line"
      source="passed"
      [paint]="{
          'line-color': '#2d2424',
          'line-opacity': 0.60,
          'line-width': 3
        }"
    >
    </mgl-layer>

    <ng-container *ngIf="trips && !openedTrip">
      <mgl-geojson-source
              #clusterComponent
              id="trips"
              [data]="trips"
              [cluster]="true"
      ></mgl-geojson-source>
      <mgl-markers-for-clusters source="trips" *ngIf="trips">
        <ng-template mglPoint let-feature>
          <mgl-marker #myMarker [feature]="feature" [popupShown]="feature.properties.id === hoveredTrip?.id">
            <div (click)="openTrip(feature)" (mouseover)="viewInfo(feature)" (mouseleave)="hideInfo()">
              <img src="{{feature.properties.icon}}" alt="icon" style="padding: 10px; max-width: 20px;" />
            </div>
          </mgl-marker>

          <mgl-popup [marker]="myMarker">
            <h2 class="trip-title">{{ hoveredTrip?.passenger?.name }}`s trip</h2>
            <mat-divider></mat-divider>
            <div class="trip-customer-driver">
              <div>
                <span>Customer Name</span>
                <h3>{{ hoveredTrip?.passenger?.name }}</h3>
              </div>
              <div>
                <span> Driver Name</span>
                <h3>{{ hoveredTrip?.driver?.first_name }} {{ hoveredTrip?.driver?.last_name }}</h3>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="trip-status">
              <span>Current Trip Status</span>
              <h4 class="box-border {{ hoveredTrip?.status }}">
                {{ statusName(hoveredTrip?.status) }}
              </h4>
            </div>
            <br/>
            <div class="trip-next-station">
              {{ hoveredTrip?.next_status }}
            </div>
            <mat-divider></mat-divider>

            <div class="trip-datetime" *ngIf="hoveredTrip?.scheduled_pickup_time">
              <span>Scheduled Pickup Date</span>
              <h4>{{ utils.clearDate(hoveredTrip?.scheduled_pickup_time) }}</h4>
            </div>
          </mgl-popup>
        </ng-template>
      </mgl-markers-for-clusters>
      <mgl-layer
              id="trips_circle"
              type="circle"
              source="trips"
              [filter]="['has', 'point_count']"
              [paint]="{'circle-color': '#00A1FF', 'circle-stroke-color': '#E6F4FC', 'circle-stroke-width': 3, 'circle-radius': 20}"
      ></mgl-layer>
      <mgl-layer
              id="trips_label"
              type="symbol"
              source="trips"
              [filter]="['has', 'point_count']"
              [paint]="{'text-color': '#fff'}"
              [layout]="{
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
                }"
      ></mgl-layer>
    </ng-container>

    <!--  Markers to view single trip points  -->
    <ng-container *ngIf="openedTrip">
      <ng-container *ngFor="let point of tripPoints">
        <mgl-marker #myMarker [lngLat]="point.geometry">
            <img src="{{point.icon}}" alt="icon" style="padding: 10px;" [class]="point.title"/>
        </mgl-marker>

        <mgl-popup [marker]="myMarker">
          <h2 class="trip-title">{{ point.data.passenger?.name }}`s trip</h2>
          <mat-divider></mat-divider>
          <div class="trip-customer-driver">
            <div>
              <span>Customer Name</span>
              <h3>{{ point.data.passenger?.name }}</h3>
            </div>
            <div>
              <span> Driver Name</span>
              <h3>{{ point.data.driver?.first_name }} {{ point.data.driver?.last_name }}</h3>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="trip-status">
            <span>Current Trip Status</span>
            <h4 class="box-border {{ point.data.status }}">
              {{ statusName(point.data.status) }}
            </h4>
          </div>
          <br/>
          <div class="trip-next-station">
            {{ point.data.next_status }}
          </div>
          <mat-divider></mat-divider>

          <div class="trip-datetime" *ngIf="point.data.scheduled_pickup_time">
            <span>Scheduled Pickup Date</span>
            <h4>{{ utils.clearDate(point.data.scheduled_pickup_time) }}</h4>
          </div>
        </mgl-popup>
      </ng-container>
    </ng-container>
  </mgl-map>
</mat-sidenav-container>
