<div class="col-12 static-page">
  <p style="text-align:center"><span style="font-family:Times New Roman,serif"><span style="font-size:large"><strong>National Mobility Eldercare Inc. Privacy Policy</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Welcome to Envoy America Driver Companion (the &ldquo;Application&rdquo; or &ldquo;app&rdquo;), a ride-share mobile application operated by National Mobility Eldercare Inc. (collectively, the &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;). This Privacy Policy describes our policy regarding the collection, use, and disclosure of the Personal Information (as defined below) you provide when you voluntarily register on or use our application. By voluntarily using or registering on our application, you agree to comply with and be bound by the following Privacy Policy.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>1. Introduction</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">At National Mobility Eldercare Inc., we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy describes our policy regarding the collection, use, and disclosure of the Personal Information (as defined below) you provide when you voluntarily register on or use our application. By voluntarily using or registering on our application, you agree to comply with and be bound by the following Privacy Policy.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>2. Collected Information</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">In addition to the information mentioned in Section 1, we also collect the following types of information:</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Location Information</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect location information for navigation purposes, enabling us to provide you with accurate ride-share services.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Background Location Collection</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect user location in the background to update our administrative dashboard with real-time user locations, enhancing the efficiency and safety of our services.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Alarm Function</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We use an alarm function to send reminders for upcoming trips, ensuring that you receive timely notifications and enjoy a seamless experience with our app.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Advertising ID</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect the Advertising ID for performance monitoring and crash analysis through Firebase. This helps us identify and address any issues that may arise while using our app, improving its overall functionality.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Personal Information</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">In addition to the information mentioned in Section 1, we also collect the following personal information:</span></span></p>

  <ul>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Name</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Email</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">User ID</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Phone Number</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Gender</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Postal Code</span></span></p>
    </li>
  </ul>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect this data to create user profiles on our administrative dashboard and internal trip log systems for new users, allowing us to provide personalized services and maintain accurate trip records.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Photos and Files</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect the following photos and files:</span></span></p>

  <ul>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Driver&#39;s License</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Vehicle Registration</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Vehicle Insurance</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Profile Picture</span></span></p>
    </li>
  </ul>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">These photos and files are necessary for verification and authentication purposes, ensuring the safety and security of our platform.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Application Info and Performance</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect application information and performance data, including crash logs and diagnostics, through Firebase. This information is crucial for identifying and resolving performance issues and crashes, providing you with a reliable and seamless experience.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em>Device or Other IDs</em></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We collect device or other IDs for performance monitoring and crash analysis, assisting us in maintaining and improving the functionality of our app.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>3. Our Use of the Information that You Provide to Us</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We use the information we collect for the following purposes (the &ldquo;Purposes&rdquo;):</span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Registering you to the application and maintaining your account.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Providing, maintaining, protecting, improving our services, and developing new services.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Sending notices to you and informing you about content and events.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Making your next use of our services more personalized.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">For research, analysis, testing, monitoring, risk management, and administrative purposes, or for any related purposes.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Help administer and protect the security of our services.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Prevent, detect, mitigate, and investigate fraud, security breaches, and potentially prohibited or illegal activities.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Where we have a legal right or duty to use or disclose your information.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>4. How do we collect information?</strong></span></span></p>

  <ul>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Information you give us. In certain occasions, we may ask you to provide certain information about yourself, for example: your name, email address, or telephone number. Please note that you are not obligated in any way to provide us with any information, but we may not be able to properly provide you with our services and/or parts of our application if you refuse to provide the required information.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Information we get from your use of our services: we collect information about your usage and interaction with our application. This information may include, among other things:</span></span></p>

      <ul>
        <li>
          <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Log data: Our servers automatically record information created by your use of the application. Such information is collected by server logs (see explanation below) and may include information such as the date and time of visits to the application, the pages viewed, time spent at our application.</span></span></p>
        </li>
        <li>
          <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Device event information such as crashes, system activity.</span></span></p>
        </li>
      </ul>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Contacting us: If you contact us, we may keep your message, email address, and contact information in order to respond to your request. Providing such information by you is entirely optional.</span></span></p>
    </li>
  </ul>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>5. Sharing Information with Third Parties</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We do not ordinarily disclose personally identifying information to third parties, except if we:</span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Believe in good faith that it is necessary or appropriate to comply with a law, regulation, legal request, or legal process or to prevent potentially prohibited or illegal activities; to protect the rights, property, or personal safety of the Company, its directors, officers, and employees, users, and any other person; to address fraud, security, or technical issues; to enforce our Terms of Use and to protect our rights (including investigation of potential violations thereof) or property.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Are involved in a bankruptcy, liquidation, merger, acquisition, reorganization, or sale of assets, where your Personal Information may be sold or transferred as part of that transaction. In any such transfer of information, we will take reasonable efforts to ensure the confidentiality of any Personal Information involved in such transactions and, where applicable, within a reasonable time after the closing of such transaction, we will post a prominent notice on the Website of any change in the ownership or use of such information, as well as any choices you may have regarding such information.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We may share with third parties certain pieces of aggregated, non-personal information, which does not identify you individually. By using our application, you agree, consent, and approve us to share or transfer Non-Personal Information to third parties.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">When you use the application, it may refer your inquiry or link you to a third-party provider (&quot;Provider&quot;). Any information you provide to a provider or collected by such Provider is subject to the Provider&#39;s privacy policy, and the Website shall not have any responsibility over such use. Note that if you provide a Provider with certain Personal Information, it may have access to the information we have collected about you.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">In the event we transfer our business or any part thereof to any third party, we may transfer your information to such third party, which may use them under the provisions of this Privacy Policy.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We take no responsibility and accept no liability for information posted by you. Any Personal Information you might post on the application and the sharing of such personal information are done at your own risk. Any personal information posted by you on the application may be saved, copied, re-sent, or otherwise used by other users, and we cannot control the use of such personal information by any such recipients. We cannot and do not guarantee that Personal Information posted by you will not be used by unauthorized persons. You understand and acknowledge that, even after Personal Information that was posted by you was removed from the application, such Personal Information may be collected, used, and redistributed if stored by other users.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>6. Storing Information</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Personal Information and Non-Personal information collected by Us and/or provided by you (&quot;Information&quot;) may be stored in two types of locations:</span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Local storage: We may store Information (including Personal Information) locally on your device.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Servers: We may store Information on our servers (and/or third parties&#39; servers we may use for this purpose) (the &quot;Servers&quot;).</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We store Personal Information for longer periods insofar as such Personal Information is required for any of the Purposes. We use reliable third-party services (e.g. Amazon, Microsoft, Google) for storing your personal data. We ensure these providers implement sufficient security measures; however, we do not always control the exact location of the servers on which your Personal Data is stored. Therefore, your Personal Data may be transferred to a third country or international organization to which an adequacy decision by the Commission (as defined in the GDPR) may or may not apply. If we transfer your Personal Data to a third country or international organization to which an adequacy decision by the Commission (as defined in the GDPR) does not apply, we will take reasonable measures to imply appropriate and suitable safeguards.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We do our best to ensure the appropriate security of the Personal Information, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical or organizational measures.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">You can notify us by sending an email, and we will make reasonable efforts to make any of the aforementioned, pursuant to any applicable privacy laws.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>7. Retention</strong></span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We keep your Information for only as long as we need to. How long we need your Information depends on what we are using it for, as set out in this Privacy Policy. For example, we may need to use it to answer your queries about the application and, as a result, may keep Information while you are still using our application.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We may also need to keep your Information for accounting purposes. If we no longer need your Information, we will delete it or make it anonymous by removing all details that identify you. If we have asked for your permission to process your Information and we have no other lawful grounds to continue with that processing, and you withdraw your permission, we will delete your Information. You also have the right to ask Us to delete your Information or restrict how it is used. There may be exceptions to the right to erasure for specific legal reasons which, if applicable, We will set out for you in response to your request. Where applicable, you have the right to object to processing of your Information for certain purposes.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">If in the future we intend to process the Information for a purpose other than that which it was collected, we will provide you with information on that purpose and any other relevant information.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>8. Accessing and Updating Information</strong></span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">You may access, update, or delete your personal information at any time by logging into your account settings.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">At your request, we can provide further information regarding what personal data is stored about you and will correct or erase personal data at your request. This excludes data held for legal obligations. To learn more or make any of the aforementioned requests, please email us.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>9. Data Controller</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We shall be the data controller of the master data you provide to us, your name and your email address, as well as registration of your personal information, your address, your age and gender, your employment preferences and searches, and other demographic information.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>10. Protection of Your Personal Information</strong></span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We take reasonable administrative and technical measures to safeguard your Personal Information against loss, theft, and misuse, as well as unauthorized access, alteration, disclosure, or destruction.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that cannot be resolved between us and an individual.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We retain information as long as it is necessary and relevant for our operations. In addition, we may retain Personal Information from closed accounts to comply with the law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Use, and take other actions permitted by law.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>11. Rights of Data Subjects</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">If you wish to exercise any of these rights, or if you have any questions about your privacy, your rights, or how to exercise them, please contact our data protection officer. We will respond to your request within a reasonable period upon verification of your identity. If you are unhappy with the way we are using your personal data, you can also contact and are free to lodge a complaint with your local data protection authority.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>12. External Websites</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We are not responsible for the practices employed by external websites linked to or from our application, nor the information or content contained therein. When you use a link to go from our Website to an external website, our Privacy Policy is no longer in effect. Your browsing and interaction on any external website, including without limitation internet websites which have a link on our application, is subject to that external website&rsquo;s own rules and policies.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>13. Online Privacy Policy</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">This online privacy policy applies only to information collected through our application and not to any information collected offline or information received when you contact us through our email address.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>14. Opt-Out</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">You may opt out of our collection of your Personal Information by not providing such information to us.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>15. Update or Change of Your Personal Information</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We do our best to ensure that Personal Information that is inaccurate are corrected or erased or rectified without delay. If you become aware of any inaccurate information you have provided us with and/or if there is any change in any information you have provided us with, please let us know promptly, so we may erase and/or correct such information.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>16. Changes to this Policy</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We may revise this Privacy Policy from time to time. Such revisions will be posted on this page. Your continued use of the application will be deemed to constitute your acceptance of any and all such changes. You are advised to consult this Privacy Policy regularly for any changes.</span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>17. Your Consent to Collection, Processing, and Transfer of Your Information</strong></span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">By using the application, you are consenting to our collection and processing of information as set forth in this Privacy Policy. Your information will ordinarily be transferred outside the European Economic Area to the United States and processed and stored there.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We may, knowingly or unknowingly, transfer your personal information to a country or international organization to which an adequacy decision by the European Commission does not apply and without implementing appropriate safeguards. Such transfer may put your personal information at risk. By entering the application and using it, you explicitly consent to such transfers, which may be necessary for the performance of the application.</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">By using or registering with us, you consent to such transfer, storage, and processing of your information in this manner.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>18. Security</strong></span></span></p>

  <ol>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">We do our utmost to ensure that all reasonable steps are taken to make sure your Personal Information and data are stored securely in accordance with the principles of the California Online Privacy Protection Act (CalOPPA).</span></span></p>
    </li>
    <li>
      <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">Data security is very important to us, and to protect your data, we have taken suitable measures to safeguard and secure data collected through our application. However, please note that the Internet and the electronic transmission of information can never be entirely secure. We cannot guarantee that the security measures we have in place to safeguard data will never be defeated or fail, or that those measures will always be sufficient or effective. Therefore, although we are committed to protecting your privacy, we cannot guarantee that your data will always remain confidential. You understand and agree that you assume all responsibility and risk for your conduct and use of the application and the information you send us electronically or otherwise, which you post to the application.</span></span></p>
    </li>
  </ol>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><strong>19. Contact Information</strong></span></span></p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium">If you have any questions or comments about this Privacy Policy, including if you have any concerns as to how your Information is processed, you are welcome to send us an email. We will make an effort to reply within a reasonable timeframe.</span></span></p>

  <p><br />
    &nbsp;</p>

  <p><span style="font-family:Times New Roman,serif"><span style="font-size:medium"><em><strong>Last Updated and Effective: September 27th, 2023.</strong></em></span></span></p>

  <p><br />
    &nbsp;</p>
</div>
