import { Component } from '@angular/core';

@Component({
  selector: 'app-help-admin-webview',
  templateUrl: './help-admin-webview.component.html',
  styleUrls: ['./help-admin-webview.component.scss']
})
export class HelpAdminWebviewComponent {

}
