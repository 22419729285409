import { IsActiveMatchOptions } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SystemSearchComponent } from '@core/components/header/system-search/system-search.component';
import { NotificationService, ChatService,  UtilsService } from '@core/services';
import { INFINITE_LIST_INITIAL_SIZE, InfiniteScroller } from '@core/directives';
import { AuthService } from '@modules/auth/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { INotification } from '@shared/models';
import { Subscription } from 'rxjs';

interface IMenuItem {
  title: string;
  route: `/${string}`;
  icon: string;
  permission: string | string[];
  options?: IsActiveMatchOptions | { exact: boolean };
}

@Component({
  selector: 'envoy-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [
    { provide: INFINITE_LIST_INITIAL_SIZE, useValue: 10 }
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  systemSearchOpened = false;
  viewInfinityLoader = false;
  notificationData: INotification[] = [];
  readonly menuItems: IMenuItem[] = [
    {
      title: 'Dispatch',
      route: '/reservations/live',
      icon: 'main-component',
      permission: 'admin/reservations',
      options: { paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored' }
    },
    {
      title: 'Maps',
      route: '/live',
      icon: 'live_icon',
      permission: '*'
    },
    {
      title: 'Trips',
      route: '/reservations',
      icon: 'trips_icon',
      permission: 'admin/reservations',
      options: { paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored' }
    },
    {
      title: 'Drivers',
      route: '/drivers',
      icon: 'drivers_icon',
      permission: 'admin/drivers'
    },
    {
      title: 'Vehicles',
      route: '/vehicles',
      icon: 'cov:vehicle_icon',
      permission: 'admin/vehicles'
    },
    {
      title: 'Profiles',
      route: '/profiles',
      icon: 'story',
      permission: ['admin/customers', 'admin/companies', 'admin/corporates']
    },
    {
      title: 'Invoicing',
      route: '/invoicing',
      icon: 'invoice',
      permission: 'admin/invoices'
    },
    {
      title: 'Settings',
      route: '/settings',
      icon: 'settings_icon',
      permission: '*'
    }
  ];
  @ViewChild(InfiniteScroller) infiniteScroller: InfiniteScroller | undefined;
  private _subscription!: Subscription;

  constructor(public notification: NotificationService, private dialog: MatDialog,
              public auth: AuthService, public utils: UtilsService, public chatService: ChatService
  ) {
  }

  @HostListener('window:keyup', ['$event'])
  SearchShortcut(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'f' && event.ctrlKey && event.shiftKey) {
      if (!this.systemSearchOpened) {
        this.openSearchDialog();
      }
    }
  }

  ngOnInit() {
    this.notification.notifications$.subscribe(data => {
      this.viewInfinityLoader = false;
      this.notificationData = [...data];
    });
    this._subscription = this.chatService.initChatStream().subscribe();
  }

  handleNotificationMenuState(opened: boolean) {
    this.notification.notificationsOpened = opened;
    if (!opened && this.infiniteScroller) {
      this.infiniteScroller.currentPage = 1;
    }
  }

  openSearchDialog() {
    this.systemSearchOpened = true;
    this.dialog.open(SystemSearchComponent, {
      width: '60vw',
      panelClass: 'search-panel',
      autoFocus: false
    }).afterClosed().subscribe(() => {
      this.systemSearchOpened = false;
    });
  }

  logout() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      disableClose: true,
      data: {
        id: 0,
        title: 'Logout',
        class: 'accent',
        api: 'close'
      }
    }).afterClosed().subscribe((status) => {
      if (status === 'yes') {
        this.auth.logout();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
