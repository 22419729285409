export enum EPermissionMethod {
  view = 'GET',
  update = 'PUT',
  create = 'POST',
  delete = 'DELETE',
}

export type TPermissionMethod = `${EPermissionMethod}`;

export interface IPermission {
  id: number;
  title: string;
  methods: TPermissionMethod[];
  permission_on: string;
}

export interface IStaffPermission {
  permission_id: number;
  permission: Record<'title' | 'permission_on', string>;
  method_allowed: TPermissionMethod[];
}

export interface IGroupPermission {
  role_permission: {
    id: number;
    permission_on: string;
    title: string;
  };
  method_allowed: TPermissionMethod[];
}

export interface IPermissionGroup {
  id: number;
  name: string;
}
