<div class="chat-list">
    <div class="chat-list__header">
        <mat-icon svgIcon="sms_open_icon"/>
        <h2 class="title">Drivers chat</h2>
    </div>
    <div class="chat-list__content">
        <mat-form-field appearance="outline" class="w-full mb-3 sm-form-field">
            <input matInput [formControl]="searchCtrl" (keyup.enter)="searchDrivers()"
                   placeholder="Type the driver name here">
            <button mat-icon-button matIconSuffix (click)="searchDrivers()" aria-label="Search for a chat">
                <mat-icon svgIcon="messages-search"/>
            </button>
        </mat-form-field>
        @if (filterApplied()) {
            @if (!filteredChats) {
                <div class="drivers-list flex center">
                    <mat-spinner mode="indeterminate" diameter="40"/>
                </div>
            } @else if (!filteredChats.length) {
                <div class="drivers-list flex flex-column center">
                    <mat-icon class="w-10rem h-10rem mb-3" svgIcon="empty-chat"/>
                    <h4 class="font-bold text-center">Can't find the driver you are looking for.</h4>
                </div>
            } @else {
                <cdk-virtual-scroll-viewport [appendOnly]="true" class="drivers-list" itemSize="80">
                    <app-driver-chat-item *cdkVirtualFor="let chat of filteredChats; trackBy: trackChatBy"
                                          [chat]="chat"/>
                </cdk-virtual-scroll-viewport>
            }
        } @else {
            @if (!chats) {
                <div class="drivers-list flex center">
                    <mat-spinner mode="indeterminate" diameter="40"/>
                </div>
            } @else if (!chats.length) {
                <div class="drivers-list flex flex-column center">
                    <mat-icon class="w-10rem h-10rem mb-3" svgIcon="empty-chat"/>
                    <h4 class="font-bold">No chats to view</h4>
                </div>
            } @else {
                <cdk-virtual-scroll-viewport [appendOnly]="true" class="drivers-list" itemSize="80">
                    <app-driver-chat-item *cdkVirtualFor="let chat of chats; trackBy: trackChatBy" [chat]="chat"/>
                </cdk-virtual-scroll-viewport>
            }
        }
    </div>
</div>
