import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Home';

  constructor(public auth: AuthService, private router: Router) {
  }

  isLandingPage(): boolean {
    return !['/', '/home', '/webview', '/privacy-policy', '/terms-of-use', '/marketing', '/delete-account']
      .includes(this.router.url);
  }

  onActivate(event: any): void {
    if (this.auth.getUserData() && this.auth.getUserData().status === 'PENDING' && window.location.href.indexOf('profile') === -1) {
      this.router.navigateByUrl('/profile');
    }
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
