<div class="w-full" style="height: calc(100vh - 67px)">
  <mat-grid-list cols="3" rowHeight="100%">
    <mat-grid-tile colspan="1" >
      <div class="w-full center">
        <div class="driver-chats center pt-2" #scrollDrivers>
          <mat-icon svgIcon="sms_open_icon"></mat-icon>
          <h2 class="active">Drivers chat</h2>
        </div>
        <div class="w-full">
          <mat-form-field appearance="outline" class="input-field detail-page w-full">
<!--            <mat-label>Type the driver name here</mat-label>-->
            <input type="text" placeholder="Type the driver name here" matInput
                   [formControl]="searchControl" (keyup.enter)="filterDrivers()">
            <button matSuffix mat-icon-button aria-label="Clear" (click)="searchControl.value ? filterDrivers() : ''">
              <mat-icon svgIcon="messeges-search"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <mat-card *ngFor="let driver of drivers" class="card-driver" [class.selected-driver]="driver.id === selectedDriver">
          <div class="w-full flex-right grid grid-nogutter" (click)="getMessages(driver.id)" style="cursor: pointer">
            <div class="col-3 text-center">
              <img src="{{driver.image || '/assets/blank_user.png'}}" width="55" height="55" alt="Driver image" class="driver-image">
            </div>
            <div class="col-9">
              <div class="flex justify-content-between" >
                <h3>{{driver.first_name}} {{driver.last_name}}</h3>
                <span>{{utils.clearDate(lastMessage(driver.id)?.date?.seconds * 1000)}}</span>
              </div>
              <span [matBadgeHidden]="!lastMessage(driver.id) || isChatRead(lastMessage(driver.id)) || lastMessage(driver.id)?.sender_id === admin.id"
                    matBadgeColor="warn" matBadge="&#8288;" class="overflow-last-message"
                    *ngIf="lastMessage(driver.id)">
                {{lastMessage(driver.id)?.sender_name}}: {{lastMessage(driver.id)?.message}}
              </span>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" class="messaging-grid hovered-over border">
      <div class="w-full hover-image flex center" *ngIf="selectedDriver === 0">
        <img src="assets/icons/emptySMS.svg" alt="">
        <p class="hint w-full">
          <span>Open message to preview</span>
        </p>
      </div>
      <ng-container *ngIf="driverData">
        <div class="message-header">
          <img src="{{driverData.image || '/assets/blank_user.png'}}" alt="{{driverData.last_name}}" width="55" height="55" class="driver-image pl-2"/>
          <h2 class="mb-0">{{driverData.first_name}} {{driverData.last_name}}</h2>
        </div>
        <div class="message-box">
          <div class="col-12 flex center" *ngIf="0">
            <p class="hint col-12">
              <span >Send message</span>
            </p>
          </div>
          <div *ngFor="let message of chat$ | async" class="staff-messages" >
            <div [class]="message.sender_id != selectedDriver ? 'sender' : 'received'"
                 matTooltipClass="tooltip-date"
                 matTooltip="{{message.sender_name}}"
                 matTooltipPositionAtOrigin="true"
                 [matTooltipPosition]="message.sender_id != selectedDriver? 'after' : 'before'">
              <h5>
                {{message.message}}
              </h5>
              <span class="date-message">{{utils.clearDate(message.date.seconds * 1000)}}</span>
            </div>
          </div>
          <div id="message-box" #scrollMe></div>
        </div>
        <div class="message-footer">
          <form class="grid grid-nogutter" [formGroup]="sendMessageForm">
            <div class="form-group col-11">
              <mat-form-field class="w-full message-input" appearance="outline">
                <mat-label>Type your message</mat-label>
                <input matInput formControlName="text">
              </mat-form-field>
            </div>
            <div class="col-1" style="margin-top: 2%">
              <button mat-button class="icon-button send_sms_icon" (click)="sendMessages()"><mat-icon svgIcon="send_sms_icon"></mat-icon></button>
            </div>
          </form>
        </div>
      </ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</div>
