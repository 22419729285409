<mat-accordion class="example-headers-align" multi togglePosition="before">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Home Page
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>
      Home page is the main page of our mobile application which display a summery for all Envoy Companion mobile application pages, from which the user could navigate to the following pages which highlighted in the bellow pictures.

    </p>
    <ul>
      <li>Once the Admin Assign trips to the driver, it will be listed under Assigned Trip section.</li>
      <li>If the Driver Accept the assigned trip, the trip will immediately move to My Trips section</li>
      <li>If the Driver does not have Any Assigned, not Accepted trip, he will not see Assigned Trips section at all.</li>
      <li>
        <ol>
          <li>Home icon, which will navigate the user to the Home Page.</li>
          <li>Chatting page, which will navigate the user to the Chatting Page.</li>
          <li>Notification Page, which will navigate the user to the Notification Page.</li>
          <li>User Profile page, which will navigate the user to the User Profile Page.</li>
          <li>Available Trips sections, which will display all Available trips to the driver.</li>
          <li>My Trips section, which will display all accepted trips to the driver.</li>
          <li>Assigned Trip section, which will display all assigned trip from the admin which are not accepted yet by the driver.</li>
          <li>Active Trips, which will display the current active trip each time.</li>
          <li>Urgent Trip! Will be labeled in Orange label color to give it more attention.</li>
        </ol>
      </li>
    </ul>
    <img src="../../../assets/help/mobile-home.png" alt="" style="border:1px solid #000"/>

  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Chatting page
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>Chatting page is the main page of our mobile application which allow the driver to contact</p>
    <p>his/her admin at any time.</p>
    <p>In our Chatting page the driver could call contact his/her admin phone at any time.</p>
    <img src="../../../assets/help/mobile-chat.png" alt="" style="border:1px solid #000"/>


  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Notification Page
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>In This Component the driver will see all new, and old notifications from their mobile application.</p>
<br>
    <p>      If the driver opens any notification, it will open a full screen for the notification, like the one below.</p>

    <img src="../../../assets/help/mobile-notification.png" alt="" style="border:1px solid #000"/>

  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Profile Page
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p> In This page the driver will be able to view, edit, add new information to each section.</p>
    <img src="../../../assets/help/mobile-profile.png" alt="" style="border:1px solid #000"/>
      <div>
        <h3>1- Driver could Update his/her Personal Information Section bellow</h3>
        <p> By Clicking on the top right Personal Information section pin the driver could be able to edit his/her email, Mobile Number, and Address.
          Clicking on Pin icon will open this page from where the driver could update his information, then click on Confirm Edits to save the new changes.
        </p>
        <img src="../../../assets/help/mobile-profile-1.png" alt="" style="border:1px solid #000"/>
      </div>
    <div>
      <h3>2- Driver could Update his/her Personal Your Documants Section bellow</h3>
      <p>Clicking on Pin icon will open this page from where the driver could update his documents, then click on Confirm Edits to save the new changes.</p>
      <ol>
        <li>Driver could Update his/her Driver License Section bellow.</li>
        <li>Driver could Update his/her Vehicle Registration Section bellow.</li>
        <li>Driver could Update his/her Vehicle Insurance Section bellow.</li>
        <li>The driver Could save changes by clicking on Confirm Edits button.</li>
        <li>The driver Could discard changes by clicking on Cancel button.</li>
      </ol>
      <img src="../../../assets/help/mobile-profile-2.png" alt="" style="border:1px solid #000"/>
    </div>
    <div>
      <h3>3- Driver could View All his/her Recent Trips Section bellow</h3>
      <p> In this section the driver could see all his/her recent trips listed
      </p>
      <img src="../../../assets/help/mobile-profile-3.png" alt="" style="border:1px solid #000"/>
    </div>

    <div>
      <h3>4- Driver could Add, and Update his/HER BANk Data Section bellow
      </h3>

      <img src="../../../assets/help/mobile-profile-4.jpeg" alt="" style="border:1px solid #000"/>
      <ol>
        <li>The driver Could save changes by clicking on Confirm Edits button.</li>
        <li>The driver Could discard changes by clicking on Cancel button.</li>
      </ol>
      <img src="../../../assets/help/mobile-profile-5.png" alt="" style="border:1px solid #000"/>

    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Settings Page
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p> Settings Wheel which will navigate the user to settings page.</p>
    <img src="../../../assets/help/mobile-settings.png" alt="" style="border:1px solid #000"/>

    <div>
      <h3>1- Terms of Use</h3>
      <p> Clicking on Terms Of Use will open a full documentation for our app terms of use, displayed in the pictures bellow.      </p>
    </div>
    <div>
      <h3>2- Privacy Policy</h3>
      <p> In this section the driver could see all his/her recent trips listed.</p>
    </div>
    <div>
      <h3>3- App Tour</h3>
      <p> Clicking on App Tour will display a short navigation tour to our app, displayed in the pictures bellow.</p>
    </div>

    <div>
      <h3>4- Help Center</h3>
      <p>Clicking on Help Center will open a list of common questions, and answers, clicking on any question will open a list of answers to that question, displayed in the pictures bellow.</p>
    </div>
    <div>
      <h3>5- Visit Website</h3>
      <p>Clicking on Visit Website will navigate the user to our website and open it using default mobile browser, displayed in the pictures bellow.</p>
    </div>
    <div>
      <h3>6- Delete Account</h3>
      <p>Clicking on Delete Account will open delete account page to the driver, displayed in the pictures bellow.</p>
      <ol>
        <li>The driver will be able to delete his/her account by clicking on Confirm Delete Account button >> Continue button; in this case the driver will be directed to log in page.</li>
        <li>The driver will be able to discard delete his/her account by clicking on Confirm Delete Account button >> Cancel button in this case the driver will be stayed in Delete Account page.</li>

      </ol>
    </div>

  </mat-expansion-panel>
</mat-accordion>
