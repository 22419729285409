import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  numberAttribute,
  Output
} from '@angular/core';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { exhaustMap, fromEvent, Observable, of, pairwise } from 'rxjs';

interface IScrollPosition {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

interface IScrollEvent {
  page: number;
  pageSize: number;
}

export const INFINITE_LIST_INITIAL_SIZE = new InjectionToken(
  'INFINITE_LIST_INITIAL_SIZE', {
    factory: () => 25
  });

/**
 * @description A custom directive build to handle infinite scrolling on tables and lists.
 * You can specify the percentage of scrolling needed to fire api loading and also view loading indicator.
 * The list may have initial size to load data at start and page size to load data each time you scroll down.
 * You can provide the initial size on the component you are using and the page size should be a duplicate value
 * of the initial size x2, x3, x4,...etc.
 */
@Directive({
  selector: '[envoyInfiniteScroller]',
  standalone: true
})
export class InfiniteScroller implements AfterViewInit {
  currentPage = 1;
  @Input() loading = false;
  @Input() scrollPercent = 50;
  @Input({ transform: numberAttribute }) length = 0;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() scrollInfinite = new EventEmitter<IScrollEvent>();
  @HostBinding('style.scroll-behavior') private readonly behaviour = 'smooth';
  @HostBinding('style.overflow-y') private readonly overflow = 'scroll';
  private initialPageSize = this.initialListSize;
  private userScrollDowns$!: Observable<any>;

  constructor(private elmRef: ElementRef<HTMLElement>,
              @Inject(INFINITE_LIST_INITIAL_SIZE) private initialListSize: number) {
  }

  /**
   * Number of items to load on each scroll. By default, set to 50. different from initially loaded page size
   * */
  private _pageSize = 100;
  @Input({ transform: numberAttribute })
  get pageSize() {
    if (this.initialPageSize === this._pageSize) {
      return this._pageSize;
    } else if (this.currentPage === 1) {
      return this.initialPageSize;
    } else {
      // calculate pages to compensate for the difference in page size
      const _pagesDiff = Math.ceil((this._pageSize - this.initialPageSize) / this.initialPageSize) + 1;
      if (this.currentPage < _pagesDiff) return this.initialPageSize;
      if (this.currentPage === _pagesDiff) {
        return this._pageSize - (this.initialPageSize * (this.currentPage - 1));
      }
      this.currentPage = 2;
      return this.initialPageSize = this._pageSize;
    }
  }

  set pageSize(value) {
    this._pageSize = Math.max(value || 0, 0);
  }

  /** Calculate the number of pages */
  get numberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }
    if (this.pageSize > this.initialListSize) {
      return Math.ceil((this.length - this.initialListSize) / this.pageSize) + 1;
    }
    return Math.ceil(this.length / this.pageSize);
  }

  get loading$() {
    return of(this.loading).pipe(distinctUntilChanged(), filter((_v) => !_v));
  }

  ngAfterViewInit() {
    this.streamScrollEvents().subscribe();
  }

  resetScroller() {
    this.currentPage = 1;
    this.initialPageSize = this.initialListSize;
    this.elmRef.nativeElement.scrollTop = 0;
  }

  private streamScrollEvents() {
    return this.userScrollDowns$ = fromEvent(this.elmRef.nativeElement, 'scroll')
      .pipe(
        filter(() => this.hasNextPage()),
        map((event) => ({
          scrollTop: (event.target as HTMLElement).scrollTop,
          scrollHeight: (event.target as HTMLElement).scrollHeight,
          clientHeight: (event.target as HTMLElement).scrollTop,
        })),
        pairwise(),
        filter(positions => this.isScrollingDown(positions) && this.isScrollPercentExpected(positions[1])),
        exhaustMap(() => this.loading$),
        tap(() => {
          this.currentPage += 1;
          this.loadingChange.emit(true);
          this.scrollInfinite.emit({ page: this.currentPage, pageSize: this.pageSize });
        })
      );
  }

  /**
   * check if the list has a next page to load data from
   * @private
   */
  private hasNextPage() {
    return this.pageSize !== 0 && this.currentPage < this.numberOfPages;
  }

  /**
   * check if the user is scrolling down or up
   * @param positions
   * @private
   */
  private isScrollingDown(positions: [IScrollPosition, IScrollPosition]) {
    return positions[0].scrollTop < positions[1].scrollTop;
  }

  /**
   * check if the height scrolled exceeded the percent to invoke action
   * @param position
   * @private
   */
  private isScrollPercentExpected(position: IScrollPosition) {
    return ((position.scrollTop + position.clientHeight) / position.scrollHeight) > (this.scrollPercent / 100);
  }

}
