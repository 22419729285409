<div mat-dialog-title>
    <h2 class="title-icon">
        <mat-icon svgIcon="shield"/>
        Two Factor Authentication
    </h2>
</div>
<mat-dialog-content class="px-3 py-5">
    <form [formGroup]="twoFactorForm">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Verify Code</mat-label>
            <input (keyup.enter)="onSubmit()" matInput name="verify_code" formControlName="verify_code">
            <mat-error *ngIf="twoFactorForm.get('verify_code')?.errors?.['required']">The Field is Required</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<div class="grid" mat-dialog-actions>
    <div class="col-6">
        <button (click)="onSubmit()" class="w-full" mat-raised-button color="primary">
            Login
        </button>
    </div>
    <div class="col-6">
        <button class="w-full" mat-stroked-button mat-dialog-close>Cancel</button>
    </div>
</div>
