import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export const billingValidator: ValidatorFn = (control: AbstractControl) => {
  const minimumControl = control.get('minimum');
  const estimatedControl = control.get('estimated');
  const rateControl = control.get('rate');

  if (!minimumControl || !estimatedControl || !rateControl) {
    return null;
  }
  const hasMinimum = !!minimumControl.value;
  const hasEstimated = !!estimatedControl.value;
  const hasRate = !!rateControl.value;

  if (!hasMinimum && !hasEstimated && !hasRate) {
    minimumControl.setErrors(null);
    estimatedControl.setErrors(null);
    rateControl.setErrors(null);
    return null;
  } else {
    let _required;
    for (let ctrl in (control as FormGroup).controls) {
      if (!control.get(ctrl)?.value) {
        _required ??= true;
        control.get(ctrl)?.setErrors({ required: true });
      }
    }
    return _required ? { required: true } : null;
  }
};
  