@if (newStyle) {
    <div class="confirm-dialog {{ class }}">
        <div class="dialog-icon" *ngIf="icon">
            <mat-icon svgIcon="{{icon}}"></mat-icon>
        </div>
        <h2 class="dialog-title">{{ title }}</h2>
        <div class="dialog-content">
            <p *ngIf="body" class="body" [innerHTML]="body"></p>
            <p *ngIf="question" class="question">{{ question }}</p>
        </div>
        @if (condition) {
            <div class="condition">
                <mat-checkbox name="agree_condition" color="primary" [formControl]="condition_checked">
                    {{ condition }}
                </mat-checkbox>
            </div>
        }
        @if (note) {
            <p class="note">{{ note }}</p>
        }
    </div>
    <mat-dialog-actions>
        <div class="w-full flex flex-row-reverse gap-2">
            <button mat-flat-button color="{{class}}" class="col font-semibold"
                    (click)="accept()">{{ primary_button }}
            </button>
            <button mat-stroked-button class="col-3" (click)="cancel()">{{ secondary_button }}</button>
        </div>
    </mat-dialog-actions>
} @else {
    <div class="dialog-content flex center flex-column {{class}}">
        <mat-icon *ngIf="icon" svgIcon="{{icon}}"></mat-icon>
        <h2 class="text-center">{{ title }}</h2>
        <p *ngIf="body">{{ body }}</p>
        <p><b>Are you sure you want to proceed?</b></p>
        <mat-divider class="col-12"></mat-divider>
        <div class="row mx-0 mt-4 p-0 d-flex justify-content-between">
            <button mat-raised-button color="{{class}}" class="button" (click)="accept()">{{ primary_button }}</button>
            <button mat-flat-button class="action-button" (click)="cancel()">{{ secondary_button }}</button>
        </div>
    </div>
}
