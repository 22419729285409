<div class="col-12 static-page">
  <h1>HIPAA Business Associate Agreement</h1>
  <p>
  This Business Associate Agreement, supplements and is made a part of the Technology Service Agreement (as defined below) by and between National Mobility Eldercare, Inc. d/b/a Envoy America (“Covered Entity”).
  </p>
  <p>
  WHEREAS, Covered Entity and Business Associate are parties to the Services Agreement to which Business Associate provides certain services to Covered Entity. In connection with Business Associate’s services, Business Associate creates, receives, maintains or transmits Protected Health Information from or on behalf of Covered Entity, which information is subject to protection under the Federal Health Insurance Portability and Accountability Act of 1996, Pub. L. No. 104-191 (“HIPAA”), the Health Information Technology for Economic and Clinical Health Act, Title XIII of the American Recovery and Reinvestment Act of 2009 (“HITECH Act”), and related regulations promulgated by the Secretary (“HIPAA Regulations”).
  </p>
  WHEREAS, in light of the foregoing and the requirements of HIPAA, the HITECH Act, and HIPAA Regulations, Business Associate and Covered Entity agree to be bound by the following terms and conditions.
  <br>
  NOW, THEREFORE, for good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows:
  <br>
  Definitions
  <br>
  General. Terms used, but not otherwise defined, in this BA Agreement shall have the same meaning given to those terms by HIPAA, the HITECH Act, and HIPAA Regulations as in effect or as amended from time to time.
  <br>
  Specific.
  <br>
  Breach. “Breach” shall generally have the same meaning as the term “breach” at 45 CFR § 164.402.
  <br>
  Business Associate. “Business Associate” shall generally have the same meaning as the term ‘business associate” at 45 CFR § 160.103, and in reference to the party to this agreement, shall mean Statewide Ambulette Service, Inc.
  <br>
  Covered Entity. “Covered Entity” shall generally have the same meaning as the term “covered entity” at 45 CFR § 160.103, and in reference to the party to this agreement, shall mean National Mobility Eldercare Inc. d/b/a Envoy America.
  <br>
  Electronic Health Record. “Electronic Health Record” shall generally have the same meaning as the term “electronic health record” in HITECH, Section 13400(5).
  <br>
  Electronic Protected Health Information. “Electronic Protected Health Information” shall generally have the same meaning as the term “electronic protected health information” at 45 CFR § 160.103.
  <br>
  Individual. “Individual” shall generally have the same meaning as the term “individual” at 45 CFR § 160.103, and shall include generally a person who qualifies as a personal representative in accordance with 45 CFR § 164.502(g).
  <br>
  Privacy Rule. “Privacy Rule” shall generally mean the Standards for Privacy of Individually Indefinable Health Information at 45 CFR Part 160 and Part 164.
  <br>
  Protected Health Information. “Protected Health Information” shall generally have the same meaning as the term “protected health information” at 45 CFR § 160.103.
  <br>
  Required by Law. “Required by Law” shall generally have the same meaning as the term “required by law” at 45 CFR § 164.103.
  <br>
  Secretary. “Secretary” shall mean the Secretary of the Department of Health and Human Services or his/her designee.
  <br>
  Security Rule. “Security Rule” shall mean the Security Standards at 45 CFR Part 160 and Part 164.
  <br>
  Services Agreement. “Services Agreement” shall generally mean any present or future agreements, either written or oral, between Covered Entity and Business Associate under which Business Associate provides services to Covered Entity which involve the use and disclosure or Protected Health Information. The Services Agreement is amended by and incorporates the terms of this BA Agreement.
  <br>
  Subcontractor. “Subcontractor” shall generally have the same meaning as the term “subcontractor” at 45 CFR § 160.103.
  <br>
  Unsecured Protected Health Information. “Unsecured Protected Health Information” shall generally have the same meaning as the term “unsecured protected health information” at 45 CFR § 164.402.
  <br>
  Obligations and Activities of Business Associate
  <br>
  Use and Disclosure. Business Associate agrees not to use or disclose Protected Health Information other than as permitted or required by the Services Agreement, this BA Agreement or as Required by Law. Business Associate shall comply with the provisions of this BA Agreement relating to privacy and security of Protected Health Information and all present and future provisions of HIPAA, the HITECH Act, and HIPAA Regulations that relate to the privacy and security of Protected Health Information and that are applicable to Covered Entity and/or Business Associate. Without limiting the foregoing, to the extent the Business Associate will carry out one or more of the Covered Entity’s obligations under the Privacy Rule, Business Associate shall comply with the requirements of the Privacy Rule that apply to the Covered Entity in the performance of such obligations.
  <br>
  Appropriate Safeguards. Business Associate agrees to use appropriate safeguards and comply, where applicable, with the Security Rule to prevent the use or disclosure of Protected Health Information other than as provided for by this BA Agreement. Without limiting the generality of the foregoing sentence, Business Associate will:
  <br>
  Implement administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity and availability of Electronic Protected Health Information as required by the Security Rule; and
  <br>
  Ensure that any Subcontractor to whom Business Associate provides Electronic Protected Health Information agrees in writing to implement reasonable and appropriate safeguards and comply, where applicable, with the Security Rule to protect Electronic Protected Health Information and comply with the other requirements of Section II(a) above.
  <br>
  Reporting. Business Associate agrees to promptly, and in any event within three (3) business days, report to Covered Entity any of the following:
  <br>
  Any use or disclosure of Protected Health Information not permitted by this BA Agreement of which Business Associate becomes aware.
  <br>
  Any Security Incident of which Business Associate becomes aware.
  <br>
  The discovery of a Breach of Unsecured Protected Health Information.
  <br>
  A Breach is considered “discovered” as of the first day on which the Breach is known, or reasonably should have been known, to Business Associate or any employee, officer or agent of Business Associate, other than the individual committing the Breach. Any notice of a Security Incident or Breach of Unsecured Protected Health Information shall include the identification of each individual whose Protected Health Information has been, or is reasonably believed by Business Associate to have been, accessed, acquired, or disclosed during such Security Incident or Breach as well as any other relevant information regarding the Security Incident or Breach. Any such notice shall be directed to Covered Entity pursuant to the notice provisions of the Services Agreement or to the Chief Compliance Officer of the Covered Entity.
  <br>
  Mitigation. Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a use or disclosure of Protected Health Information by Business Associate or its employees, officers, Subcontractors or agents in violation of the requirements of this BA Agreement (including, without limitation, any Security Incident or Breach of Unsecured Protected Health Information). Business Associate shall keep Covered Entity fully apprised of all mitigation efforts of the Business Associate required under this Section II(d).
  <br>
  Cooperation. Business Associate agrees to reasonably cooperate and coordinate with Covered Entity in the investigation of any violation of the requirements of this BA Agreement and/or any Security Incident or Breach. Business Associate shall also reasonably cooperate and coordinate with Covered Entity in the preparation of any reports or notices to the Individual, a regulatory body or any third party required to be made under HIPAA, the HITECH Act, HIPAA Regulations, or any other Federal or State laws, rules or regulations, provided that any such reports or notices shall be subject to the prior written approval of Covered Entity.
  <br>
  Subcontractors. Business Associate shall ensure that any Subcontractor to whom it provides Protected Health Information received from, or created, maintained, received or transmitted by, Business Associate on behalf of Covered Entity agrees in writing to the same restrictions and conditions that apply through this BA Agreement to Business Associate with respect to such information.
  <br>
  Access to Designated Record Sets. To the extent that Business Associate possesses or maintains Protected Health Information in a Designated Record Set, Business Associate agrees to provide access, at the request of Covered Entity, and within three (3) business days of such request, to Protected Health Information in a Designated Record Set, to Covered Entity or, as directed by Covered Entity, to an Individual in order to meet the requirements under HIPAA Regulations. If an Individual makes a request for access to Protected Health Information directly to Business Associate, Business Associate shall notify Covered Entity of the request within three (3) business days of such request and will cooperate with Covered Entity to allow Covered Entity to send the response to the Individual.
  <br>
  Amendments to Designated Record Sets. To the extent that Business Associate possesses or maintains Protected Health Information in a Designated Record Set, Business Associate agrees to make any amendment(s) to Protected Health Information in a Designated Record Set that the Covered Entity directs or agrees to pursuant to HIPAA Regulations at the request of a Covered Entity or Individual, within three (3) business days of any such request. If an individual makes a request for an amendment to Protected Health Information directly to Business Associate, Business Associate shall notify Covered Entity of the request within three (3) business days of such request and will cooperate with Covered Entity and allow Covered Entity to send the response to the Individual.
  <br>
  Access to Books and Records. Business Associate agrees to make its internal practices, books, and records, including policies and procedures and Protected Health Information, relating to the use and disclosure of Protected Health Information received from, or created or received by Business Associate on behalf of, Covered Entity available to the Covered Entity, or to the Secretary, within three (3) business days of such request or in the time and manner otherwise designated by the Secretary, for purposes of the Secretary determining Covered Entity’s compliance with the Privacy Rule.
  <br>
  Accountings. Business Associate agrees to document such disclosures of Protected Health Information and information related to such disclosures as would be required for Covered Entity to respond to a request by an Individual for an accounting of disclosures of Protected Health Information in accordance with HIPAA, the HITECH Act and HIPAA Regulations.
  <br>
  Requests for Accountings. Business Associate agrees to provide to Covered Entity or an individual, within twenty (20) days of a request by Covered Entity, information collected in accordance with Section II(j) of this BA Agreement, to permit Covered Entity to respond to a request by an Individual for an accounting of disclosures of Protected Health Information in accordance with HIPAA, the HITECH Act and HIPAA Regulations. If an Individual makes a request for an accounting directly to Business Associate, Business Associate shall notify Covered Entity of the request within three (3) business days of such request and will cooperate with Covered Entity and allow Covered Entity to send the response to the Individual.
  <br>
  Permitted Uses and Disclosures by Business Associate
  <br>
  Services Agreement. Except as otherwise limited in this BA Agreement, Business Associate may use or disclose Protected Health Information to perform functions, activities or services for, or on behalf of, Covered Entity as specified in the Services Agreement, provided that such use or disclosure would not violate HIPAA, the HITECH Act or HIPAA Regulation if done by Covered Entity or the minimum necessary policies and procedures of Covered Entity.
  <br>
  Use for Administration of Business Associate. Except as otherwise limited in this BA Agreement, Business Associate may use Protected Health Information for the proper management and administration of the Business Associate or to carry out the legal responsibilities of the Business Associate.
  <br>
  Disclosure for Administration of Business Associate. Except as otherwise limited in this BA Agreement, Business Associate may disclose Protected Health Information for the proper management and administration of the Business Associate, provided that (i) disclosures are Required by Law, or (ii) Business Associate obtains reasonable assurances from the person to whom the information is disclosed that it will remain confidential and used or further disclosed only as Required by Law or for the purpose for which it was disclosed to the person, and the person notifies the Business Associate of any instances of which it is aware in which the confidentiality of the information has been breached.
  <br>
  Permissible Requests by Covered Entity. Except as set forth in Section III of this BA Agreement, Covered Entity shall not request Business Associate to use or disclose Protected Health Information in any manner that would not be permissible under the Privacy Rule if done by Covered Entity.
  <br>
  Term and Termination.
  <br>
  Term. This BA Agreement shall be effective as of the date of this BA Agreement and shall terminate when all of the Protected Health Information provided by Covered Entity to Business Associate, or created, received, or maintained by Business Associate on behalf of Covered Entity, is destroyed or returned to Covered Entity, or, if it is infeasible to return or destroy Protected Health Information, protections are extended to such information, in accordance with the termination provisions in this Section.
  <br>
  Termination for Cause. Upon a material breach by Business Associate of the terms of this BA Agreement, notwithstanding anything contained in the Services Agreement to the contrary, Covered Entity may provide a written notice to the Business Associate terminating this BA Agreement and/or all portions of the Service Agreement, in the Covered Entity’s sole discretion; provided, however, the Covered Entity may determine in its sole discretion whether to provide the Business Associate with an opportunity to cure such breach before so terminating any of such agreements and/or provisions.
  <br>
  Effect of Termination.
  <br>
  Except as provided in Section V(c)(ii), upon termination of this BA Agreement, for any reason, Business Associate shall return or destroy all Protected Health Information received from Covered Entity, or created, maintained or received by Business Associate on behalf of Covered Entity. This provision shall apply to Protected Health Information that is in the possession of Subcontractors or agents of Business Associate. Business Associate shall retain no copies of the Protected Health Information.
  <br>
  In the event that Business Associate determines that returning or destroying the Protected Health Information is infeasible, Business Associate shall provide to Covered Entity notification of the conditions that make return or destruction infeasible. Upon mutual agreement of the Parties that return or destruction of Protected Health Information is infeasible, Business Associate shall extend the protections of this BA Agreement to such Protected Health Information and limit further uses and disclosures of such Protected Health Information to those purposes that make the return or destruction infeasible, for so long as Business Associate maintains such Protected Health Information.
  <br>
  Indemnity. Business Associate agrees to indemnify, defend and hold harmless Covered Entity and its employees, directors, trustees, members, professional staff, representatives and agents (collectively, the “Indemnitees”) from and against all claims (whether in law or in equity), obligations, actions, causes of action, suits, debts, judgments, losses, fines, penalties, damages, expenses (including attorney’s fees), liabilities, lawsuits or costs incurred by the Indemnities which arise or result from a breach of the terms and conditions of this BA Agreement or a violation of HIPAA, the HITECH Act or HIPAA Regulations by Business Associate or its employees, Subcontractors or agents. Business Associate’s obligations under this Agreement, including, without limitation, its indemnification obligations hereunder, shall not be subject to any limitations of liability or remedies in the Service Agreement. As such, in the event of breach of any of the covenants and assurances contained in this BA Agreement, Covered Entity shall be entitled to enjoin and restrain Business Associate from any continued violation. The remedies contained in this paragraph VI shall be in addition to (and not supersede) any action for damages and/or any other remedy Covered Entity may have for breach of any part of this BA Agreement.
  <br>
  Compliance with HIPAA Transaction Standards. When providing its services and/or products, Business Associate shall comply with all applicable HIPAA standards and requirements (including, without limitation, those specified in 45 CFR Part 162) with respect to the transmission of health information in electronic form in connection with any transaction for which the Secretary has adopted a standard under HIPAA (“Covered Transactions”). Business Associate will make its services and/or products compliant with HIPAA’s standards and requirements no less than thirty (30) days prior to the applicable compliance dates under HIPAA. Business Associate represents and warrants that it is aware of all current HIPAA standards and requirements regarding Covered Transactions, and Business Associate shall comply with any modifications to HIPAA standards and requirements which become effective from time to time. Business Associate agrees that such compliance shall be at its sole cost and expense, which expense shall not be passed on to Covered Entity in any form, including, but not limited to, increased fees. Business Associate shall require all of its agents and Subcontractors (if any) who assist Business Associate in providing its services and/or products to comply with the terms of this Section VII.
  <br>
  Miscellaneous.
  <br>
  No HIPAA Agency Relationship. It is not intended that an agency relationship (as defined under the Federal common law of agency) be established hereby expressly or by implication between Covered Entity and Business Associate for purposes of liability under HIPAA, the HITECH Act or HIPAA Regulations. No terms or conditions contained in this BA Agreement shall be construed to make or render Business Associate an agent of Covered Entity.
  <br>
  Regulatory References. A reference in this BA Agreement to a section in HIPAA, the HITECH Act or HIPAA Regulations means the section as in effect or as amended or modified from time to time, including any corresponding provisions of subsequent superseding laws or regulations.
  <br>
  Amendment. The Parties agree to take such action as is necessary to amend the Services Agreement and/or this BA Agreement from time to time as is necessary for Covered Entity to comply with the requirements of HIPAA, the HITECH Act and HIPAA Regulations.
  <br>
  Survival. The respective rights and obligations of Business Associate under Sections II(e), V(c), VI and VIII of this BA Agreement shall survive the termination of the Services Agreement or this BA Agreement.
  <br>
  Interpretation. Any ambiguity in this Agreement shall be resolved to permit Covered Entity to comply with HIPAA, the HITECH Act and HIPAA Regulations.
  <br>
  Notice to Covered Entity. Any noticed required under this Agreement to be give Covered Entity shall be made in writing to:
  <br>
  K. C. Kanaan<br>
  Chief Executive Officer<br>
  17767 N Perimeter Drive, Suite B109<br>
  Scottsdale, AZ 85255
  <br>
  Notice to Business Associate. Any noticed required under this Agreement to be give Business Associate shall be made in writing to (name and address):
  <br>

  <br>
  Third Party Beneficiaries. The Parties do not intend for this BA Agreement to be to the benefit of any other persons and no other third parties shall be entitled to enforce its terms and conditions.
  <br>
  Other Miscellaneous. The terms of this BA Agreement are hereby incorporated into the Services Agreement. Except as otherwise set forth in Section VIII(c) or VIII(f) of this BA Agreement, in the event of a conflict between the terms of this BA Agreement and the terms of the Services Agreement, the terms of this BA Agreement shall prevail. The terms of the Services Agreement which are not modified by this BA Agreement shall remain in full force and effect in accordance with the terms thereof. This BA Agreement shall be governed by, and construed in accordance with, the laws of the State of New York, exclusive of conflict of law rules. Each party to this BA Agreement shall only be brought in the courts of the State of New York in Kings County. The Services Agreement together with this BA Agreement constitutes the entire agreement between the parties with respect to the subject matter contained herein, and this BA Agreement supersedes and replaces any former business associate agreement or addendum entered into by the parties. This BA Agreement may be executed in counterparts, each of which when taken together shall constitute one original. Any PDF or facsimile signatures to this BA Agreement shall be deemed original signatures to this BA Agreement. No amendments or modifications to the BA Agreement shall be effected unless executed by both parties in writing.
  <br>
  IN WITNESS WHEREOF, the parties have executed the BA Agreement as of the date set forth above.
  <br>
  Name:							National Mobility Eldercare, Inc. d/b/a
  <br>
  Envoy America
</div>
