<div class="container py-3">
    <mat-tab-group [disablePagination]="true" color="primary">
        <mat-tab *appHasPermission="'admin/reservations'" #matLiveTripsTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="main-component{{matLiveTripsTab.isActive?'_open':''}}"/>
                Dispatch
            </ng-template>
            <p>Clicking on <b>Dispatch</b> opens a new screen where the admin can view trips that are completed, in
                progress, or scheduled by date. A date selector, shown in Figure 57 allows the admin to choose the
                specific date for which they wish to display trips. The following subsections will provide a detailed
                overview of the components of the <b>"Dispatch"</b> page.</p>
            <section>
                <h2 class="mb-1">Dispatch Overview Table</h2>
                <p>On the <b>"Dispatch"</b> page, a table summarizing the main trip information is displayed. The
                    default columns in the table include the following information, with admins being able to customize
                    the table by hiding or showing these and other columns. Default columns include:</p>
                <ol>
                    <li>Location</li>
                    <li>Envoy ID</li>
                    <li>Passenger Name</li>
                    <li>Driver Name</li>
                    <li>En Route Time</li>
                    <li>On Location Time</li>
                    <li>Scheduled Pickup Time</li>
                    <li>Actual Drop off Time</li>
                    <li>Trip Notes</li>
                    <li>Trip Status</li>
                </ol>
                <p>Different actions can be performed on the table, and most of these actions are discussed in
                    detail in User interface control section.</p>
                <div class="pl-2">
                    <h3 class="mb-1">Trip Filters: Based on Status</h3>
                    <p>A new filtering option has been introduced to allow users to display trips based on their status.
                        This filter is available above the table, with options illustrated in the figure below. Admins
                        can select a specific date and then choose a trip status, such as "Cancelled", to view the trips
                        for that status on the selected date.</p>
                    <p>The selected option is highlighted in grey to alert users that a specific filtering criterion is
                        currently active, showing only relevant trips in the table below.</p>
                </div>
                <div class="pl-2">
                    <h3 class="mb-1">Search</h3>
                    <p>A search bar has been added to the page, enabling admins to locate specific trips by selecting
                        criteria such as driver name, passenger name, reservation ID, reference PO, or location and
                        entering the information in the search box. Alternatively, admins can conduct a broader search
                        without specifying attributes by selecting <b>"Search All"</b>. The search bar and criteria are
                        illustrated in Figure 64.</p>
                </div>
            </section>
            <section>
                <h2 class="mb-1">Interacting with Trip Details</h2>
                <p>Clicking on the details of any trip in the table opens a summary window displaying key trip
                    information, including:</p>
                <ol>
                    <li>Trip Status: Editable with the option to select a new status from a dropdown menu.</li>
                    <li>Passenger and Driver Information: Displays the passenger's name, confirmed driver's name, and
                        preferred driver's name. A phone icon allows for direct calls to both the passenger and the
                        confirmed driver.
                    </li>
                    <li>Trip Addresses: Pickup and drop-off addresses, with a copy icon next to each for easy copying.
                    </li>
                    <li>Pickup Notes</li>
                    <li>Dispatcher Notes</li>
                    <li>Action Log: Includes the trip issuance date, trip start date, and driver acceptance date.</li>
                </ol>
                <p>The summary window has two buttons:</p>
                <ol>
                    <li>Track the Trip on the Map: Clicking this option opens a new screen that tracks the trip and
                        displays key details such as trip status, trip type, total distance, and time.
                    </li>
                    <li>View all Trip Details: This option opens a comprehensive screen showing the trip's main
                        information, including trip status, type, total distance and time, customer name, driver name,
                        preferred driver, trip times, trip preview, preferences, customer and driver data, trip
                        stations, and the actions log.
                    </li>
                </ol>
                <p>The three vertical ellipses on the right-hand side of the summary window, as shown in Figure 65 offer
                    additional options for editing the trip.</p>
                <p>Clicking on "Edit the Trip" redirects the user to the trip page, where the admin initially added the
                    trip, allowing for modifications and updates.</p>
            </section>
        </mat-tab>
        <mat-tab *appHasPermission="'admin/reservations'" #matMapsTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="live_icon{{matMapsTab.isActive?'_open':''}}"/>
                Maps
            </ng-template>
            This page, illustrated in Figure 3, shows all the trips happening right now on a map for monitoring
            purposes. We've added some handy features to make it easier for admins, like a search bar, filters for
            statuses and car types, and color codes. The following sections will talk about each component in detail.
            <figure>
                <img src="assets/documentary/Envoy_maps.webp" alt="Envoy Map Page screen">
                <figcaption>Figure 3: Maps Screen</figcaption>
            </figure>
            <h2 class="mb-1">Currently Active Trips per Station</h2>
            <p>
                The <b>"Maps"</b> page gives a count of the trips currently happening. This count is then broken into
                four categories: En route, On board, On location, and Wait; each represents a different trip status. The
                number below each category shows how many cars are currently in each status. For each status, a
                different color is used, making it easier for admins to quickly identify what’s going on with the cars
                on the live map we'll discuss shortly. The currently active trips per status counts is illustrated in
                Figure 4.
            </p>
            <figure>
                <img src="assets/documentary/Envoy_maps-active-trips-count.webp" width="400" alt="active trips count">
                <figcaption>Figure 4: Currently Active Trips per Status</figcaption>
            </figure>
            <h2 class="mb-1">Currently Active Trips per Car Types</h2>
            <p>On the <b>"Maps"</b> page, a count is displayed for the number of active trips per each car type,
                including SUV, Sedan, WAV, and any other types.
            </p>
            <figure>
                <img src="assets/documentary/Envoy_maps-active-trips-count-per-car-type.webp" width="400px"
                     alt="active trips count">
                <figcaption>Figure 5: Currently Active Trips per Car Type</figcaption>
            </figure>
            <h2 class="mb-1">United States’ Maps with Dots</h2>
            <p>One of the components of the "Maps" page is the US map with circles containing numbers, as shown in
                Figure 6. The numbers indicate the current count of active trips in each region of the US.</p>
            <figure>
                <img src="assets/documentary/Envoy_maps-the-US-map-with-circles.webp" alt="The US Map with Circles">
                <figcaption>Figure 6: The US Map with Circles</figcaption>
            </figure>
            <p>If the admin clicks on any of these circles, a zoom-in feature is activated, focusing on the selected
                area. The dots within that area represent each active trip, with different colors matching their
                respective statuses. A sample of the zoom-in is presented in the figure below.</p>
            <figure>
                <img src="assets/documentary/Envoy_maps-zoomed-in-view.webp" alt="Zoomed-in View of Active Trips">
                <figcaption>Figure 7: Zoomed-in View of Active Trips</figcaption>
            </figure>
            <p>Upon hovering over any dot on the map, a card displaying summary information will appear. This includes
                the trip name, customer and driver names, trip status, and the scheduled pick-up date. Figure 8
                illustrates the summary information card.</p>
            <figure>
                <img src="assets/documentary/Envoy_maps-summary-information-card.webp" width="400px"
                     alt="Summary Information Card">
                <figcaption>Figure 8: Summary Information Card</figcaption>
            </figure>
            <p>Clicking on any of these dots reveals the entire trip route, accompanied by a summary window containing
                key trip information, including:</p>
            <ol>
                <li>Trip status</li>
                <li>Customer data: Customer name, email, mobile number, last trip date, and first trip date</li>
                <li>Driver data: Driver name, email, and mobile number.</li>
                <li>Trip preferences</li>
                <li>Trip stations (i.e. Starting location, pick-up point, stop points (if any), and client drop-off
                    point)
                </li>
                <li>Actions log: Trip issued date, driver acceptance date, and trip starting date</li>
            </ol>
            <figure>
                <img src="assets/documentary/Envoy_maps-sample-of-trip-details.webp" alt="sample of trip details"/>
                <figcaption>Figure 9: Sample of trip details</figcaption>
            </figure>
            <h2 class="mb-1">Filters</h2>
            <figure>
                <img src="assets/documentary/Envoy_map-filters-applied.webp" width="550px" alt="Filters applied on map">
                <figcaption>Figure 10: Filters</figcaption>
            </figure>
            <h2 class="mb-1">Filter Based on Trip Stations</h2>
            <p>Active trips displayed on the live map can be filtered based on the trip station (i.e. status). This
                filtering option is placed at the upper side of the map. Admins can easily filter ongoing trips by
                selecting one of the options from the drop-down menu, which includes: Wait, En route, On location, and
                On board. Upon selecting one of these statuses, only relevant trips appear, as displayed in Figure
                11.</p>
            <figure>
                <img src="assets/documentary/Envoy_map-trip-station-filters.webp" width="400px"
                     alt="trip station filters">
                <figcaption>Figure 11: Filter based on Trip Stations</figcaption>
            </figure>
            <p>
                It's important to note that only one status can be selected at a time, with the system highlighting the
                chosen option. In case of no matching results, the system promptly shows a message indicating no search
                results.
            </p>
            <p>
                Users also have the alternative of filtering data by clicking on any of the statuses appearing in the
                right-side menu, displayed in Figure 4, which indicates their associated count. Finally, admins can
                reset all filters to display all active trips by clicking on the <b>"Reset All Filter"</b>button, as
                shown in Figure 10. This option is only activated when the filter option is used.

            </p>
            <h2 class="mb-1">Filter Based on Car Type</h2>
            <p>Active trips can also be filtered based on the car type. This filtering option is placed at the upper
                side of the map below the Trip Station Filter. Admins can easily filter ongoing trips by selecting one
                of the options from the drop-down menu, which includes: Sedan, SUV, WAV, and Any other car types added
                in the Settings under the Vehicle Type section. Once a car type is selected, only trips associated with
                that type will be displayed, as shown in Figure 12.</p>
            <figure>
                <img src="assets/documentary/Envoy_map-car-type-filters.webp" width="400px"
                     alt="trip station filters">
                <figcaption>Figure 12: Filter based on Car Type</figcaption>
            </figure>
            <p>It's important to note that only one car type can be selected at a time, with the system highlighting the
                chosen option. In case of no matching results, the system promptly shows a message indicating no search
                results.
                Users also have the alternative of filtering data by clicking on any of the car types appearing in the
                right-side menu, displayed in Figure 5, which indicates their associated count.
                Finally, admins can reset all filters to display all active trips by clicking on the <b>"Reset All
                    Filter"</b>
                button, as shown in Figure 10. This option is only activated when the filter option is used.
            </p>
            <h2 class="mb-1">Search Bar</h2>
            <p>A search bar has been added to the map, enabling admins to search for specific trips, drivers, clients,
                or a trip code.</p>
        </mat-tab>
        <mat-tab *appHasPermission="'admin/reservations'" #matTripsTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="trips_icon{{matTripsTab.isActive?'_open':''}}"/>
                Trips
            </ng-template>
            <h2 class="mb-1">Trip Counts</h2>
            <p>One primary element on the <b>"Trips"</b> page is a bar featuring counts for Total Trips, Active Trips,
                Upcoming Trips, and Unassigned Trips. Each count is presented in a distinct color: Grey, green, blue,
                and orange, respectively. The trip counts element is shown in the figure below.</p>
            <section>
                <h2 class="mb-1">Add a New Trip</h2>
                <p>On the <b>"Trips"</b> page, admins can add new trips and their details using an option on the right
                    side
                    of the user interface. Clicking this option opens a new screen with four sections: General,
                    Addresses
                    and Times, Pricing and Billing, and Notes and Preferences. We will describe each section in detail
                    below.</p>
                <div class="pl-2">
                    <h2 class="mb-1">General</h2>
                    <p>In the <b>"General"</b> section, admins complete fields across four main categories: Customer
                        Info,
                        Passenger Info, Requester Info, and Trip Info.</p>
                    <h3 class="mb-0">Customer Info:</h3>
                    <ul>
                        <li>Customer Name: selected from a dropdown list</li>
                        <li>Corporate: auto-selected from a dropdown list of added corporates based on the customer’s
                            name
                        </li>
                        <li>Location (Company): auto-selected from a dropdown list of added companies based on the
                            customer’s name
                        </li>
                    </ul>
                    <h3 class="mb-0">Passenger Info:</h3>
                    <ul>
                        <li>Passenger Name: This option allows you to view the list of passengers associated with the
                            selected customer and provides the functionality to create new entries. The addition window
                            is
                            illustrated in Figure 21 and upon successfully adding a new passenger, a confirmation pop-up
                            message will appear, as shown in Figure 22.
                        </li>
                        <li>Passenger Cell Phone and Email: Automatically populated based on the selected passenger,
                            with
                            the option to edit
                        </li>
                        <li>Number of Passengers: automatically set to 1 with possibility to be edited</li>
                        <li>Number of Bags: automatically set to 0 with possibility to be edited</li>
                    </ul>
                    <h3 class="mb-0">Requester Info:</h3>
                    <ul>
                        <li>Ordered by: Manual entry</li>
                        <li>Ordered by Cell Phone Number: Manual entry</li>
                    </ul>
                    <h3 class="mb-0">Trip Info:</h3>
                    <ul>
                        <li>Booking Source: select from the drop-down menu, which includes options configured under the
                            Booking Sources section in Settings
                        </li>
                        <li>Ref PO Number: Manual entry</li>
                        <li>Trip Category: select from the drop-down menu, which includes options configured under the
                            Trip Categories section in Settings.
                        </li>
                    </ul>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Move to Addresses and
                        Times"</b>to continue entering trip details.</p>
                </div>
                <div class="pl-2">
                    <h2 class="mb-1">Addresses & Times</h2>
                    <p>This section is divided into two subsections: Times and Addresses.</p>
                    <h3 class="mb-0">Times:</h3>
                    <ul>
                        <li>Time Zone: Select from a dropdown menu.</li>
                        <li>Appointment Time: Enter using the calendar view.</li>
                    </ul>
                    <h3 class="mb-1">Addresses:</h3>
                    <p>In the Address Book subsection, the addresses for at least the pickup and drop-off points must be
                        provided, along with any stop points if applicable. The initial screen is depicted in the figure
                        below.</p>
                    <p>Upon clicking the Pickup address, a new window shown in Figure 27 appears, prompting the
                        following fields to be completed:</p>
                    <ol>
                        <li>Map Address</li>
                        <li>Address 1</li>
                        <li>Address 2</li>
                        <li>City</li>
                        <li>State/Province</li>
                        <li>Postal Code</li>
                        <li>Country</li>
                    </ol>
                    <p>These fields must also be completed for the Drop-Off point.</p>
                    <p>It is worth mentioning that when the Map Address field is selected, a map appears (as shown in
                        Figure 28) allowing the admin to search for the location. As a result, the other fields are
                        automatically populated, though they remain editable.</p>
                    <p>Admins have the option to add stop points. For each stop point, all the aforementioned fields
                        must be filled out, in addition to the waiting time as depicted in Figure 29 . Multiple stop
                        points can be added if necessary.</p>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Move to Pricing & Billing"</b>
                        to continue entering trip details.</p>
                </div>
                <div class="pl-2">
                    <h2 class="mb-1">Pricing & Billing</h2>
                    <p>The <b>"Pricing & Billing"</b> section calculates both trip pricing and the driver's bill,
                        divided into two subsections: trip bill calculation and driver’s cost calculation.</p>
                    <h3 class="mb-1">Trip Bill Calculation</h3>
                    <p>The trip bill calculation is based on the following information:</p>
                    <ol>
                        <li><b>Number of Legs:</b> The segments of the trip, defined by the journey from one point to
                            another, including pickup, drop-off, and any stop points in between. A trip with only a
                            pickup and drop-off point consists of one leg. Adding a stop point creates two legs: one
                            from the pickup to the stop point, and another from the stop point to the drop-off.
                        </li>
                        <li><b>Estimated Trip Time in Hours:</b> Based on the locations of the pickup and drop-off
                            points.
                        </li>
                        <li><b>Customer Rate and Vehicle Type:</b> Special pricing for specific customers or corporates
                            is managed on their respective pages for each vehicle type. If a customer or corporate does
                            not have specified rates, the system defaults to the rates set on the settings page. If the
                            customer belongs to a corporate with its own pricing, the corporate rates will apply unless
                            the customer has individual special pricing, in which case the customer's specific rates
                            will override the corporate pricing.
                        </li>
                    </ol>
                    <p>The figure below illustrates the trip bill calculation process. For trips with a duration equal
                        to or exceeding the minimum hour specified in the Pricing and Billing section, the system
                        applies the default, customer, or corporate rate (as discussed above). If the trip duration is
                        less than the minimum hour, the billing is based on the minimum hour, multiplied by the
                        applicable rate (default, customer, or corporate) and the number of legs. Additionally, admins
                        can apply extra charges for extended waiting times and other necessary fees.</p>
                    <p>The system also accounts for additional factors such as working after hours/holidays, extra
                        mileage, extra passengers, late cancellations, and no-show charges. The admin can set rates for
                        any applicable category, and the system will calculate the total trip cost by summing all these
                        components with the base price, as shown in the figure below. It is important to note that both
                        the base price and detailed pricing information are editable.</p>
                    <h3>Driver’s Cost Calculation</h3>
                    <p>After calculating the trip bill, the next step is to determine the driver's pay. There are two
                        payment types for drivers:</p>
                    <ol>
                        <li><b>Hourly Rate:</b> This method calculates the driver's pay based on the number of hours
                            worked multiplied by the hourly rate. The total amount payable to the driver is the product
                            of these two figures. If the trip duration is less than one hour, the calculation uses one
                            hour multiplied by the hourly rate. It is important to note that both the hourly pay and
                            hourly rate are editable fields.
                        </li>
                        <li><b>Fixed Price:</b> In this method, the admin enters a fixed amount to be paid to the
                            driver, rendering the number of hours worked non-editable.
                        </li>
                    </ol>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Move to Notes &
                        Preferences"</b>to continue entering trip details.</p>
                </div>
                <div class="pl-2">
                    <h2 class="mb-1">Notes & Preferences</h2>
                    <p>This section is divided into two subsections: Preferences and Notes.</p>
                    <h3 class="mb-0">Preferences:</h3>
                    <ol>
                        <li>Trip Equipment: select from a drop-down list, which is displayed based on the configurations
                            in the Trip Equipment section of the Settings. Multiple selections are allowed
                        </li>
                        <li>Customer Preferences: this section is auto-populated with the trip preferences specified in
                            the customer profile at the time of creation but remains editable
                        </li>
                        <li>Preferred Driver: select from a dropdown menu</li>
                        <li>Preferred Car: select from multiple options</li>
                    </ol>
                    <h3 class="mb-0">Notes:</h3>
                    <p>Three notes can be added under the notes section:</p>
                    <ol>
                        <li>Dispatcher Notes</li>
                        <li>Trip Notes</li>
                        <li>General Notes</li>
                    </ol>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Create the Reservation"</b>.
                        This action will return you to the Trips page.</p>
                </div>
            </section>
            <section>
                <h2 class="mb-1">Trips Overview Table</h2>
                <p>On the "Trips" page, a table summarizing the main trip information is displayed. The default columns
                    in the table include the following information, with admins being able to customize the table by
                    hiding or showing these and other columns. Default columns include:</p>
                <ol>
                    <li>Company name</li>
                    <li>Reservation ID</li>
                    <li>Passenger name</li>
                    <li>Driver name</li>
                    <li>Type of trip</li>
                    <li>Scheduled pickup time</li>
                    <li>Trip status</li>
                </ol>
                <p>Different actions can be performed on the table, and most of these actions will be discussed in
                    detail in <b>User interface controls</b> section.</p>
                <div class="pl-2">
                    <h3 class="mb-1">Export Reservations with Signature</h3>
                    <p>Clicking the export icon, illustrated in Figure 52 on the right-hand side of the user's screen,
                        opens a new window.</p>
                    <p>This window, depicted in Figure 53, allows admins to specify a time by selecting the start and
                        end dates, corresponding to the scheduled pickup time. Only trips with signature data, where the
                        scheduled pickup time falls within the selected period, will be exported into an Excel (CSV)
                        file.</p>
                    <p>The exported data includes comprehensive details such as reservation ID, driver name, passenger
                        name, A2C code, pickup time, pickup location, drop-off time, drop-off location, driver
                        signature, passenger signature, location where the signature was captured, and more.</p>
                </div>
                <div class="pl-2">
                    <h3 class="mb-1">Upload CVS File</h3>
                    <p>This functionality enables the upload of Excel files from Access to Care, with the requirement
                        that the files adhere to a specific format. This format must include the following fields:
                        Business Unit Identifier, Trip Name, Resource, Rider ID, Name (including First, Middle, and Last
                        Names), Member Age, Member DOB, Member Weight, Case Number, Pickup Address (including Address
                        Line 1 & 2, City, State, Zip Code, County, Latitude), Member Phone, Vehicle Type, Directions,
                        Payable By, Destination Information (Name, Phone, Address, including Address Line 1 & 2, City,
                        State, Zip Code, Latitude, Longitude), Additional Passenger Count, Additional Passengers, Price,
                        Trip Date, Appointment Time, Pickup Time (Will Call), Mileage, Provider Notes, Pharmacy Stop
                        Auth, Stretcher, Wheelchair, Confirmation Number, Call Priority, and All Notes and Special
                        Needs.</p>
                    <p>This functionality processes the data from the file to automatically create new trips. The system
                        calculates the distance from the pickup to the drop-off based on the properties defined in the
                        file. Additionally, the state and county information provided in the file enables the system to
                        determine the appropriate customer ID by cross-referencing the Engine Configurations – AC2
                        Imports section, which lists the corresponding customer IDs for each state and county. This
                        ensures that the trip is accurately associated with the correct customer.</p>
                    <p>Additionally, the file's special needs information, such as wheelchair requirements, is used by
                        the system to calculate the trip time, factoring in the extra time associated with each special
                        need. This ensures that the time of the trip is accurately calculated, considering the specific
                        requirements of the passenger.</p>
                </div>
            </section>
            <section>
                <h2 class="mb-1">Interacting with Trip Details and Actions</h2>
                <p>Clicking on any of the trip details added to the table reveals a new summary window containing
                    essential trip information, including:</p>
                <ol>
                    <li>Trip status</li>
                    <li>Trip preview on a map</li>
                    <li>Passenger data: Passenger name and mobile number</li>
                    <li>Trip preferences</li>
                    <li>Trip stations (i.e. Starting location, pick-up point, stop points (if any), and client drop-off
                        point)
                    </li>
                    <li>Actions log: Trip issued date and scheduled starting date</li>
                </ol>
                <p>Additionally, admins can perform several actions from the same window, including:</p>
                <ol>
                    <li>Edit trip: This option redirects the admin to the page shown in Figure 18, where the trip
                        information was initially entered. Here, the admin can modify the previously entered trip
                        information
                    </li>
                    <li>Change a trip to urgent: so that this is reflected on the mobile app</li>
                    <li>Change trip status: Admins can change the trip status and select from a list of options that
                        includes:
                        <ul>
                            <li>Booked: Indicates that a new reservation has been made by a passenger.</li>
                            <li>Trip confirmed: Indicates that the dispatcher has already communicated with the
                                passenger, and the passenger has confirmed the reservation.
                            </li>
                            <li>Driver scheduled: Indicates that this trip is listed in the mobile app under the
                                "Available Trips" section, ready for drivers in that area to view and select.
                            </li>
                            <li>No show: It is the status assigned when the driver arrives at the pick-up point, but the
                                passenger does not appear. The dispatcher attempts to contact the passenger, and if
                                unsuccessful, the status is changed to "No Show". This adjustment ensures that the
                                driver is compensated according to the minimum fee for the trip.
                            </li>
                            <li>Driver confirmed: This status indicates that the trip has been assigned to a specific
                                driver using the "Assign Trip" feature. If the driver rejects the assignment, the status
                                changes to "Driver Scheduled" and becomes available for all drivers in that area.
                            </li>
                            <li>Dropped: Indicates that the trip has been successfully completed, and the drop-off point
                                has been reached.
                            </li>
                            <li>En route: Indicates that the journey has just started, and the driver is currently en
                                route to the pickup point.
                            </li>
                            <li>On location: It is the status of the trip when the driver has successfully reached a
                                designated point, be it the pick-up location or any intermediate stop point.
                            </li>
                            <li>Wait: It is the status of the trip when the driver is currently "On Location," and the
                                client needs to step out of the car for a specific purpose. During this time, the driver
                                pauses the trip, awaiting the client's return before resuming the journey.
                            </li>
                            <li>On board: It is the status of the trip when the driver is actively moving from one point
                                to another. For example: the transition from the pick-up point to the next designated
                                stop point in the trip.
                            </li>
                            <li>Canceled: Indicates that the trip has been canceled by the passenger.</li>
                        </ul>
                    </li>
                    <li>Assign a trip to a specific driver: Admins can simply assign trips to drivers by searching
                        for their names. Note that trips that have been dropped, cancelled, or are old cannot be
                        assigned.
                    </li>
                </ol>
            </section>
        </mat-tab>
        <mat-tab #matDriversTab="matTab" *appHasPermission="'admin/drivers'">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="drivers_icon{{matDriversTab.isActive?'_open':''}}"/>
                Drivers
            </ng-template>
            This page is designed to display the information of all registered drivers, as depicted in Figure 70.
            <figure>
                <img src="assets/documentary/Envoy_drivers.webp" alt="Companion Driver's Main Table">
                <figcaption>Figure 70: Companion Driver's Main Table</figcaption>
            </figure>
            <h2 class="mb-1">Driver Counts</h2>
            <p>One primary element on the " Drivers" page is a bar featuring counts for Total Drivers, Active Drivers,
                Drivers with About-to-Expire Driving Licenses, and Drivers with Expired Driving Licenses. Each count is
                presented in a distinct color: Blue, green, orange, and red, respectively. The driver counts element is
                shown in the figure above.</p>
            <h2 class="mb-1">Companion Driver’s Overview Table</h2>
            <p>On the "Companion Drivers" page, a table summarizing the main driver’s information is displayed. The
                default columns in the table include the following information, with admins being able to customize the
                table by hiding or showing these and other columns. Default columns include:</p>
            <ol>
                <li>Number</li>
                <li>Driver Name</li>
                <li>Email</li>
                <li>Phone Number</li>
                <li>Registration Date</li>
                <li>Driver Status</li>
            </ol>
            <p>Figure 70 depicts the table as shown when opened for the first time.</p>
            <p>Different actions can be performed on the table, and each of these actions will be discussed in detail in
                Customizing and Manipulating Data views section.</p>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Search</h2>
                <p>A search bar has been added to the page, enabling admins to search for specific drivers by entering
                    the driver’s name, email, phone number, or any relevant information. The search bar is visible in
                    Figure 70.</p>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Customize Table View</h2>
                <figure>
                    <img src="assets/documentary/table_actions-edit-columns.webp" width="250px"
                         alt="edit table columns view">
                    <figcaption>Figure 71: Customize Table Icon</figcaption>
                </figure>
                <p>Clicking on the edit icon, illustrated in Figure 71 and located on the user’s right-hand side, opens
                    a new window. This window allows admins to show and hide columns according to their preferences, as
                    depicted in Figure 72.</p>
                <div class="grid">
                    <figure class="m-0 col-6">
                        <img src="assets/documentary/Envoy_drivers-view-columns.webp" alt="edit table columns view">
                        <figcaption>Figure 72: Customize Table View Window</figcaption>
                    </figure>
                    <figure class="m-0 col-6">
                        <img src="assets/documentary/Envoy_drivers-edit-columns-view.webp"
                             alt="edit table columns view">
                        <figcaption>Figure 73: Rearrange by Dragging & Dropping</figcaption>
                    </figure>
                </div>
                <p>Admins can choose from a list of columns, which, in addition to the default columns, include:</p>
                <ol>
                    <li>Driver’s Image</li>
                    <li>Vehicle Type</li>
                    <li>Driver Type</li>
                </ol>
                <p>A checkbox is added next to each column, allowing admins to configure column visibility by checking
                    or unchecking. Additionally, users can rearrange the order of columns by simply dragging and
                    dropping, as demonstrated in the figure above.</p>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Driver Filters: Active, Approval Needed, and Deactivated</h2>
                <p>
                    By default, admins can view all drivers who registered using the mobile app under the "All"
                    category. However, for ease of management, they can filter drivers based on their current status
                    using the upper ribbon displayed in Figure 74.
                </p>
                <p>The options include:</p>
                <ol>
                    <li>Active Drivers: Registered in the mobile app and approved by Envoy admins.</li>
                    <li>Drivers Requiring Approval: Registered in the mobile app and awaiting admin approval.</li>
                    <li>Deactivated Drivers: Registered in the mobile app and deactivated by the admin for various
                        reasons.
                    </li>
                    Access to the mobile app is revoked, preventing login. Admins can reactivate them at any time using
                    the
                    "reactivate" button.
                </ol>
                <p>
                    The selected option is highlighted in blue to alert users that a specific filtering criterion is
                    currently active, showing only relevant drivers in the table below. In cases where there are no
                    related drivers to display, a blank table will be presented. To reset the filter, users can simply
                    click on the <b>"All"</b> option.
                </p>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Filters: Registration Date, Car Type, and Service Area </h2>
                <p>Clicking on the filter icon, illustrated in Figure 75 and located on the user’s right-hand side,
                    opens a new window.</p>
                <figure>
                    <img src="assets/documentary/table_actions-edit-filters.webp" width="250px"
                         alt="Show table filters applied">
                    <figcaption>Figure 75: Filter Icon</figcaption>
                </figure>
                <p>The new window, illustrated in Figure 76 allows admins to filter trips based on registration date,
                    car type, and service area. Admins can use multiple filters simultaneously or filter data based on
                    only one criterion, though only one option from each criterion can be selected.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-table-filters.webp" width="400px"
                         alt="Show table filters applied">
                    <figcaption>Figure 76: Filter Criteria</figcaption>
                </figure>
                <p>Regarding the driver’s registration date, admins can choose the period by selecting the start and end
                    dates. Clicking on any car type indicates its selection, changing the sign from + to -.
                    Additionally, admins are asked to enter the area name if they want to filter for a specific
                    region.</p>
                <p>Users can either select <b>"Apply Filter"</b> if they wish to apply it, or <b>"Reset Filter"</b> if
                    they want to restore the table to its unfiltered state.</p>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Export Drivers</h2>
                <p>Clicking on the export icon, illustrated in Figure 77 and located on the user’s right-hand side,
                    opens a new window.</p>
                <figure>
                    <img src="assets/documentary/table_actions-export-list.webp" width="250px"
                         alt="Show table filters applied">
                    <figcaption>Figure 77: Export Driver Icon</figcaption>
                </figure>
                <p>This new window, depicted in Figure 78, enables admins to select the period by choosing the start and
                    end dates. Data for drivers who registered during that period will be exported into an Excel (CSV)
                    file.</p>
                <p>In the new window, depicted in Figure 78, admins can select the status of the drivers they are
                    interested in (active, pending, and inactive) and export their information into a CSV file. The
                    exported data includes details such as ID, phone number, name, status, availability, vehicle type,
                    postal code, driver type, address, trip count, device type, verification date, approved date,
                    registration date, and area.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-export-drivers-dialog.webp" width="600px"
                         alt="Export Drivers Data Based on Their Status">
                    <figcaption>Figure 78: Export Drivers Data Based on Their Status</figcaption>
                </figure>
            </section>
            <h2>Interacting with Driver Details and Actions</h2>
            <section class="pl-3">
                <h2 class="mb-1">&dash; Viewing Driver Information: Active and Inactive Drivers</h2>
                <p>
                    Clicking on the details of any driver (active or deactivated) in the table opens a new page
                    featuring four sections with essential driver information. These sections are specific to active and
                    deactivated drivers only.
                </p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-view-driver-details.webp" alt="Driver details">
                    <figcaption>Figure 80: Driver Details Overview</figcaption>
                </figure>
                <p>
                    The newly opened window is divided into two sections: one displays the driver’s primary information
                    (e.g., name, address), and the other presents additional details related to their trips, banks, and
                    registration. The admin can navigate through four pages in the second section: Overview,
                    Registration Data, Bank Information, and Trip Log. The following sections will provide a detailed
                    overview of each page.
                </p>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">Driver Contact Details</h2>
                <figure>
                    <img height="400px" src="assets/documentary/Envoy_drivers-view-driver-contact-details.webp"
                         alt="Driver Contact details">
                    <figcaption>Figure 81: Driver Contact Details</figcaption>
                </figure>
                <p>All pages (Overview, Registration Data, Bank Information, and Trip Log) consistently feature the
                    driver's main information on the left-hand side, ensuring ease of access and management. This
                    section displays the following details, some of which are editable or can be copied by the
                    admin:</p>
                <ol>
                    <li>Driver’s Name</li>
                    <li>Registration Date (Added Since)</li>
                    <li>Employee ID (Editable by Admin)</li>
                    <li>Driver Type: Contract 99 or COV (Editable by Admin)</li>
                    <li>Gender</li>
                    <li>Car Type</li>
                    <li>Status: Active/Deactivated</li>
                    <li>Mobile Number</li>
                    <li>Email Address</li>
                    <li>Physical Address</li>
                </ol>
                <p>A copy icon is available next to the Mobile Number, Email Address, and Physical Address fields for
                    quick copying. Additionally, below the driver’s name, there are three icons (message, email, and
                    phone) that allow the admin to easily contact the driver via their preferred method. The message
                    icon, when clicked, directly opens the system chat with the admin.</p>
                <p>The three vertical ellipses, located on the right-hand side of the driver's contact details window,
                    provide additional options for managing driver accounts.</p>

                <h3>&bullet; For Active Drivers:</h3>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-active-driver-details-actions.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 82: Active Driver Options Menu</figcaption>
                </figure>
                <p><b>1. Reset Driver’s Password:</b> Selecting this option opens a new window where the admin can reset
                    the driver’s password. The admin must enter the new password twice for confirmation before
                    completing the reset. This process is depicted in the figure below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-reset-password.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 83: Password Reset Window</figcaption>
                </figure>
                <p><b>2. Deactivate Driver:</b> This option is available only for active drivers. Upon selecting it, a
                    pop-up message prompts the admin to confirm the intention to deactivate the driver. This is
                    illustrated in the figure below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-deactivate-driver.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 84: Deactivate Driver Window</figcaption>
                </figure>
                <p><b>3. Update Driver’s Info:</b> Selecting this option opens a new window where the admin can update
                    the driver’s info including first name, last name, email address, and phone number. This is
                    illustrated in the figure below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-edit-profile-info.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 85: Edit Driver’s Profile</figcaption>
                </figure>
                <h3>&bullet; For Inactive Drivers:</h3>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-reactivate-driver-options.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 86: Deactivated Driver Options Menu</figcaption>
                </figure>
                <p><b>1. Reactivate Driver:</b> For inactive drivers, the option to reset the driver’s password is not
                    available. Instead, only the "Reactivate" option is displayed in the three ellipses menu. A
                    confirmation message for reactivating the driver is shown in the figure below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-reactivate-driver-window.webp"
                         width="450px" alt="Active drivers actions">
                    <figcaption>Figure 87: Reactivate Driver Window</figcaption>
                </figure>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">Overview</h2>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-view-driver-details.webp" alt="Driver details">
                    <figcaption>Figure 88: Driver Overview Page Summary</figcaption>
                </figure>
                <p>The <b>"Overview"</b> page provides a comprehensive summary of driver information, organized into
                    distinct sections:</p>
                <h3 class="mb-1">&bullet; Driver Metrics:</h3>
                <ol>
                    <li><b>Total Trips Count:</b> Displays the total number of trips completed by the driver.</li>
                    <li><b>Total Distance Traveled:</b> Shows the cumulative distance covered.</li>
                    <li><b>Average Trip Duration:</b> Indicates the average duration of trips.</li>
                </ol>
                <h3>&bullet; Detailed Information:</h3>
                <ol>
                    <li><b>Driver Essential Dates:</b> Includes key dates such as the request for access and the
                        driver’s approval date.
                    </li>
                    <li><b>Service Area:</b> Lists the area where the driver operates. This section includes an edit
                        icon, allowing the admin to modify the service area. A copy icon is available for easy
                        duplication of this information. The window for editing the service area is shown in Figure 92
                        below.
                        <figure>
                            <img src="assets/documentary/Envoy_drivers-edit-service-area.webp" width="450px"
                                 alt="Change driver service area">
                            <figcaption>Figure 92: Service Area Edit Window</figcaption>
                        </figure>
                        Upon updating the driver's area, a confirmation pop-up message appears to verify the intention
                        of this change.
                    </li>
                    <li><b>Recent Trips:</b> Provides details of the driver’s recent trips, as illustrated in the figure
                        above.
                    </li>
                    <li><b>Action Log:</b> Records the driver’s history, including status changes, ID modifications, or
                        any other information updates, along with the corresponding dates and admin actions, as shown in
                        the figure below.
                        <figure>
                            <img src="assets/documentary/Envoy_drivers-actions-log-details.webp" width="450px"
                                 alt="Actions log section">
                            <figcaption>Figure 95: Action Log</figcaption>
                        </figure>
                    </li>
                </ol>
            </section>
            <section class="pl-3">
                <h2 class="mb-1">Registration Data</h2>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-registering-data.webp" width="450px"
                         alt="registering data view">
                    <figcaption>Figure 96: Registration Data Page</figcaption>
                </figure>
                <p>This page is organized into four key sections:</p>
                <ol>
                    <li><b>Outline Requirements:</b> Provides essential information about the driver, such as age,
                        experience with elderly passengers, and other details collected through their mobile app
                        responses.
                    </li>
                    <li><b>Experience:</b> Details the driver's professional experience.</li>
                    <li><b>Availability:</b> Specifies the driver's availability based on the times they provided during
                        registration.
                    </li>
                    <li><b>Documents:</b> Includes important documents such as the driver's license, vehicle
                        registration, drug test results, and other relevant paperwork.
                    </li>
                </ol>
                <h2 class="mb-1">Banking Information</h2>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-banking-information.webp" width="450px"
                         alt="Driver's bank info">
                    <figcaption>Figure 101: Banking Information Page</figcaption>
                </figure>
                <p>This section displays the driver's banking information, including the bank name and account number,
                    with the option to copy the number.</p>
                <h2 class="mb-1">Trips Log</h2>
                <figure>
                    <img src="assets/documentary/Envoy_drivers-trips-logs.webp" width="450px"
                         alt="registering trips log view">
                    <figcaption>Figure 102: Trips Log Page</figcaption>
                </figure>
                <p>This page is designed to display key information about trips completed by the driver, including
                    details such as location, Envoy ID, passenger name, trip type, scheduled pickup time, distance, and
                    more.</p>
            </section>
            <h2>Viewing Driver Information: Approval Needed</h2>
            <section>
                Clicking on the details of any driver awaiting approval in the table opens a new summary window that
                displays essential driver information.The information includes:
                <ol>
                    <li>Driver Data</li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-driver-data.webp" width="350px"
                             alt="Driver Data">
                        <figcaption>Figure 103: Driver Data as Shown in the Summary Window</figcaption>
                    </figure>
                    <li>Driver’s Availability</li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-driver-availability.webp" width="350px"
                             alt="Driver’s Availability">
                        <figcaption>Figure 104: Driver’s Availability as Shown in the Summary Window</figcaption>
                    </figure>
                    <li>Experience</li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-driver-experience.webp" width="350px"
                             alt="Driver’s Experience">
                        <figcaption>Figure 105: Driver’s Experience as Shown in the Summary Window</figcaption>
                    </figure>
                    <li>Outline Requirements</li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-driver-outlines.webp" width="350px"
                             alt="Outline Requirements">
                        <figcaption>Figure 106: Outline Requirements as Shown in the Summary Window</figcaption>
                    </figure>
                    <li>Actions log: Registration date</li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-actions-log.webp" width="350px"
                             alt="Actions Log">
                        <figcaption>Figure 107: Actions Log as Shown in the Summary Window</figcaption>
                    </figure>
                </ol>
                Additionally, admins can perform several actions from the same window, including:
                <ol>
                    <li>Change a driver’s type: When a driver registers for the first time, their driver type is
                        initially set to "Contract 99", indicating that they are not employees of Envoy but work with
                        them. For those operating company-owned vehicles, the driver's type is set to "COV". The two
                        types are depicted in Figure 108.
                        <figure>
                            <img src="assets/documentary/Envoy_drivers-change-driver-type.webp" width="350px"
                                 alt="Change Driver’s Type">
                            <figcaption>Figure 108: Change Driver’s Type Window</figcaption>
                        </figure>
                    </li>
                    <li>Set employee ID. Illustrated in figure 109.</li>
                    <li>Set driver’s area. Illustrated in figure 109.</li>
                    <li>Approve/Deny: Admins can also approve or deny newly registered drivers awaiting approval by
                        using the two buttons added to the screen.
                    </li>
                    <figure>
                        <img src="assets/documentary/Envoy_drivers-view-actions.webp" width="350px"
                             alt="view driver actions">
                        <figcaption>Figure 109: Approve/Deny Driver Registration Options</figcaption>
                    </figure>
                </ol>
                Accepting a driver triggers a confirmation pop-up message to verify the action.
                <figure>
                    <img src="assets/documentary/Envoy_drivers-approve-driver.webp" width="350px"
                         alt="Driver Acceptance Confirmation">
                    <figcaption>Figure 110: Driver Acceptance Confirmation Pop-Up</figcaption>
                </figure>
            </section>
        </mat-tab>
        <mat-tab #matCovTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="cov:vehicle_icon{{matCovTab.isActive?'_open':''}}"/>
                COV
            </ng-template>
            <h1>Company Owned Vehicles</h1>
            <p>This page is designed to display the information of the company owned vehicles, as depicted in Figure
                304.</p>
            <figure>
                <img src="assets/documentary/Envoy_COV.webp" alt="COV">
                <figcaption>Figure 304: COV's Main Table</figcaption>
            </figure>
            <p>On the "COV" page, a table summarizing the main COV’s information is displayed. The default columns in
                the table include the following information, with admins being able to customize the table by hiding or
                showing these and other columns. Default columns include:</p>
            <ol>
                <li>Vehicle ID</li>
                <li>Vehicle Type</li>
                <li>Service Area</li>
                <li>Current Location</li>
                <li>Vehicle Status</li>
                <li>Driver Status</li>
                <li>Miles Count</li>
                <li>Vehicle Log</li>
            </ol>
            <p>Figure 304 depicts the table as shown when opened for the first time.Different actions can be performed
                on the table, and each of these actions has been discussed in detail in
                Customizing and Manipulating Data views in User control section.</p>
            <p>Clicking on the details of any vehicle in the table opens a new summary window that displays essential
                vehicle information. The information includes:</p>
            <ol>
                <li>General Info</li>
                <li>Vehicle Basic Info</li>
                <li>Vehicle Manufacturing and Legal Info</li>
                <li>Vehicle Documents</li>
                <li>Recent Completed Trips</li>
            </ol>
            <figure>
                <img src="assets/documentary/Envoy_COV-summary.webp" width="650px" alt="COV Summary">
                <figcaption>Figure 305: COV Summary</figcaption>
            </figure>
            <p>Additionally, admins can change the vehicle’s status directly from the same window, selecting from
                several options such as "Good Condition", "In Review", "Needs Repair", and "Out of Service".</p>
            <figure>
                <img src="assets/documentary/Envoy_COV-change-status.webp" width="650px" alt="Change COV Status">
                <figcaption>Figure 306: Change COV Status Window</figcaption>
            </figure>
            <h2>Interacting via Action Menu (Three Ellipses)</h2>
            <p>Clicking on the three vertical ellipses next to any vehicle in the table reveals three options:</p>
            <figure>
                <img src="assets/documentary/Envoy_COV-menu-options.webp" width="650px" alt="COV Ellipses">
                <figcaption>Figure 317: Vehicle Action Menu Options</figcaption>
            </figure>
            <ol>
                <li>Change the Vehicle Assignee</li>
                <li>Vehicle Documents</li>
                <li>Vehicle Log</li>
            </ol>
            <section class="pl-2">
                <h2 class="mb-1">Change the Vehicle Assignee / Assign to Driver</h2>
                <p>Clicking this option opens a new window where the admin can select a driver to assign the vehicle to
                    from a dropdown list in the Driver section. The selected driver's name will appear in the box below,
                    which displays all drivers currently assigned to the vehicle. Multiple drivers can be added. This is
                    depicted in the figure below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_COV-change-vehicle-assignee.webp" width="650px"
                         alt="Change COV Assignee">
                    <figcaption>Figure 318: Change the Vehicle Assignee Window</figcaption>
                </figure>
                <p>After completing this action, a pop-up message will appear, informing the admin that they will
                    receive a notification once the driver accepts the assignment, as shown below.</p>
                <figure>
                    <img src="assets/documentary/Envoy_COV-change-vehicle-assignee-notification.webp" width="650px"
                         alt="Change COV Assignee Notification">
                    <figcaption>Figure 319: Change the Vehicle Assignee Notification</figcaption>
                </figure>
                <p>If the vehicle is currently unassigned, clicking on the ellipses will show the option "Assign to a
                    Driver". This opens a new window where the assignment can be completed, as illustrated below. </p>
                <figure>
                    <img src="assets/documentary/Envoy_COV-add-vehicle-assignee.webp" width="650px"
                         alt="Assign to Driver">
                    <figcaption>Figure 320: Assign to Driver Window</figcaption>
                </figure>
                <p>Clicking "Assign to a Driver" will display the same message as in the previous case.</p>
            </section>
            <section class="pl-2">
                <h2 class="mb-1">Vehicle Documents</h2>
                <p>This section enables admins to add, replace, or delete vehicle documents, including vehicle
                    registration, insurance, inspection documents, licenses, and addition documents. The creation and
                    update dates are displayed below each document. If no document is uploaded, a blank box appears
                    where admins can either upload documents from their device or drag and drop them. This is
                    illustrated in the figure below.</p>
                <p>Once documents are uploaded, they will appear as shown in the accompanying image.</p>
                <p>Hovering over any uploaded document reveals two options: one for replacing the document and another
                    for deletion, as depicted below.</p>
            </section>
            <section class="pl-2">
                <h2 class="mb-1">Vehicle Log</h2>
                <p>This section provides access to a comprehensive list of vehicle-related logs, including trip history,
                    assignment acceptance and return records (detailing who accepted and returned the vehicle and when),
                    and rejection logs with relevant information. Selecting this option opens a new screen with three
                    primary buttons, each leading to a dedicated screen:</p>
                <ol>
                    <li>Trips Log</li>
                    <li>Acceptance and Return Log</li>
                    <li>Rejection Log</li>
                </ol>
                <p>Each screen displays the vehicle status, which can be updated if needed, along with key trip
                    information such as the vehicle's model, make, and license. The following sections provide detailed
                    descriptions of each.</p>
                <div class="pl-1">
                    <h4 class="mb-1">1- Trips Log</h4>
                    <p>This screen presents a table summarizing key information related to trips completed by the
                        selected
                        vehicle. The table includes the following details:</p>
                    <ol>
                        <li>Location</li>
                        <li>Reservation ID</li>
                        <li>Passenger Name</li>
                        <li>Driver Name</li>
                        <li>Trip Type</li>
                        <li>Scheduled Pickup Time</li>
                    </ol>
                    <p>On the right side of the screen, an edit icon allows the admin to customize the table by
                        selecting
                        which columns to display or hide, with the option to reorder columns as needed. Additionally,
                        the
                        Trips Log screen includes a search bar, enabling the admin to search for specific trips using
                        relevant information.</p>
                </div>
                <div class="pl-1">
                    <h4 class="mb-1">2- Acceptance and Return Log</h4>
                    <p>This screen displays a table that provides key details about vehicle acceptance and return
                        activities, including:</p>
                    <ol>
                        <li>Driver Name</li>
                        <li>Acceptance Date</li>
                        <li>Hours in Custody</li>
                        <li>Return Date</li>
                        <li>Trip Count</li>
                        <li>Mile Count</li>
                        <li>Acceptance and Return Details</li>
                    </ol>
                    <p>An edit icon on the right side of the screen allows the admin to customize the table by choosing
                        which columns to show or hide, and by rearranging the order of columns as needed. The Accepting
                        and Returning Log screen also features a search bar that enables the admin to search for
                        specific drivers and retrieve their records.</p>
                    <p>One of the columns, titled “Acceptance & Return Details”, contains a “Show” link. Clicking “Show”
                        opens a new window that provides a summary of the vehicle’s condition at the time of acceptance
                        and return. This includes details on the interior and exterior condition, oil levels, light
                        checks, tire condition, and any noted damage. The information is supported by images of the
                        vehicle, and additional comments can be added regarding tire condition and overall vehicle
                        state, including any damages. The images below illustrate examples for different vehicles within
                        this section.</p>
                    <p>It is worth mentioning that these images are uploaded via the mobile application by the
                        driver. </p>
                </div>
                <div class="pl-1">
                    <h4 class="mb-1">3- Rejection Log</h4>
                    <p>This screen displays a table that provides key details about vehicle rejection activities,
                        including:</p>
                    <ol>
                        <li>Driver Name</li>
                        <li>Rejecting Date</li>
                        <li>Rejection Reason</li>
                    </ol>
                    <p>An edit icon on the right side allows the admin to customize the table by selecting which columns
                        to display or hide, and by rearranging the column order. The Rejection Log screen also includes
                        a search bar, enabling the admin to search for specific drivers and access their records.</p>
                </div>
            </section>
            <section class="pl-2">
                <h2 class="mb-1">Add a New COV</h2>
                <p>On the <b>"COV"</b> page, admins can add new COV and their details using an option on the right side
                    of the user interface.</p>
                <p>Clicking this option opens a new screen with four sections: Vehicle Info, Maintenance & Condition,
                    Documentation, and Photos & Visual Inspection</p>
                <div class="pl-1">
                    <h4 class="mb-1">1- Vehicle Information</h4>
                    <p>In the <b>"Vehicle Information"</b> section, admins complete fields across three main categories:
                        Leased Vehicle Program, Vehicle Specifications, and Vehicle Details.</p>
                    <ol>
                        <li>Leased Vehicle Program</li>
                        <ul>
                            <li>Leased Vehicle Program: This option can be enabled or disabled as needed.</li>
                        </ul>
                        <li>Vehicle Specifications</li>
                        <ul>
                            <li>Vehicle ID: Enter the ID manually.</li>
                            <li>Vehicle Type: Select from the available options.</li>
                            <li>Wheelchair Capacity: Choose from the listed options.</li>
                            <li>Passenger Capacity: Choose from the listed options.</li>
                            <li>Service Area: Select from the drop-down list.</li>
                            <li>Manufacture Year: Enter manually.</li>
                        </ul>
                        <li>Vehicle Details:</li>
                        <ul>
                            <li>Model: Manual entry</li>
                            <li>Make: Manual entry</li>
                            <li>Color: Manual entry</li>
                            <li>Tire Size: Manual entry</li>
                            <li>VIN Code: Manual entry</li>
                            <li>License Number: Manual entry</li>
                        </ul>
                    </ol>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Move to Maintenance &
                        Condition"</b>to continue entering COV details.</p>
                </div>
                <div class="pl-1">
                    <h4 class="mb-1">2- Maintenance & Condition</h4>
                    <p>This section is divided into three subsections: Current Status, Scheduled Maintenance, and
                        Condition checks.</p>
                    <ol>
                        <li>Current Status:</li>
                        <ul>
                            <li>Oil Capacity: Manual Entry.</li>
                            <li>Odometer Reading: Manual Entry.</li>
                            <li>Air Filter Condition: Select from two available options.</li>
                        </ul>
                        <li>Scheduled Maintenance:</li>
                        <ul>
                            <li>Mileage Due: Manual Entry.</li>
                            <li>Oil Due: Manual Entry.</li>
                            <li>Oil Change Date: Select from four available options.</li>
                        </ul>
                        <li>Condition Checks:</li>
                        <p>This section allows the admin to assess the condition of various vehicle parts by selecting
                            the current status of the vehicle. Multiple questions are provided, each accompanied by
                            options for the admin to choose from, as illustrated below.</p>
                        <p>When options such as "Damaged", "Not Working", or similar are selected, a comment box
                            appears, allowing the admin to input additional remarks. This is illustrated in the figure
                            below.</p>
                    </ol>
                    <p>After filling out these fields, admins can proceed by clicking <b>"Move to Vehicle
                        Documentation"</b> to continue entering COV details.</p>
                </div>
                <div class="pl-1">
                    <h4 class="mb-1">3- Documentation</h4>
                    <p>This section allows the admin to upload vehicle-related documents, including vehicle
                        registration, insurance certificates, license documents, inspection reports, and any other
                        necessary documents.</p>
                    <p>If a document needs to be deleted or replaced after uploading, the admin can hover over the
                        document to reveal two options, as illustrated in the figure below.</p>
                    <p>After uploading the required documents, admins can proceed by clicking <b>"Move to Photos and
                        Visual Inspection"</b> to continue entering COV details</p>
                </div>
                <div class="pl-1">
                    <h4 class="mb-1">4- Photos & Visual Inspection</h4>
                    <p>This section allows the admin to upload images of the vehicle, including: Front exterior, Rear
                        exterior, Driver side, Passenger side, Interior or exterior damage, Toll tag, Front interior,
                        Rear interior, Vehicle ramp, and additional photos.</p>
                    <p>Below is a sample of the uploaded images.</p>
                    <p>Once the images are uploaded, the admin can click on "Add the COV", which prompts a new window to
                        confirm the intention to create the COV. The confirmation message is shown in the figure
                        below.</p>
                </div>
                <p>Lastly, it is important to note that any previously created item (COV) can be edited. To do this, the
                    admin can click on the item in the COV table shown in Figure 343, then select the three ellipses
                    with the "Edit Vehicle" option. This option is illustrated in the figure below.</p>
                <p>Clicking on it opens the same window used for creation, but with the existing information already
                    populated, allowing the admin to edit the details and update the table with new information.</p>
            </section>
        </mat-tab>
        <mat-tab #matProfileTab="matTab" *appHasPermission="['admin/customers', 'admin/companies', 'admin/corporates']">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="story{{matProfileTab.isActive?'_open':''}}"/>
                Profiles
            </ng-template>
            <h2 class="mb-1">1- Customers</h2>
            <section class="pl-3">
                <h3 class="mb-0">&dash; Customer Counts</h3>
                <p>One primary element on the "Profiles - Customers" page is a bar featuring counts for Total Customers,
                    Active Customers, and Recently Registered Customers. Each count is presented in a distinct color:
                    Blue, green, and orange, respectively. The customer counts element is shown in the figure below.</p>
                <h3 class="mb-0">&dash; Customer Overview Table</h3>
                On the "Customer" page, a table summarizing the main customer’s information is displayed. The default
                columns in the table include the following information, with admins being able to customize the table by
                hiding or showing these and other columns. Default columns include:
                <ol>
                    <li>Number</li>
                    <li>Customer Name</li>
                    <li>Phone Number</li>
                    <li>Registration Date</li>
                </ol>
                Figure 114 depicts the table as shown when opened for the first time.Different actions can be performed
                on the table, and each of these actions will be discussed in detail in Customizing and Manipulating Data
                views section.
                <figure>
                    <img src="assets/documentary/Envoy_customers.webp" alt="Envoy Customers list">
                    <figcaption>Figure 114: Customer Overview Table</figcaption>
                </figure>
                <h3 class="mb-0">&dash; Viewing Customer Information</h3>
                <figure>
                    <img src="assets/documentary/Envoy_customers-view-customer-details.webp"
                         alt="Envoy Customers details overview">
                    <figcaption>Figure 121: Customer Details Overview</figcaption>
                </figure>
                <p>Clicking on the details of any customer in the table opens a new page featuring five sections with
                    essential customer information.</p>
                <p>The newly opened window is divided into two sections: one displays the customer’s primary information
                    (e.g., name, address), and the other presents additional details related to their contact
                    information, pricing, and others. The admin can navigate through five pages in the second section:
                    Overview, Contact Information, Passenger, Pricing & Billing, and Trips Log. The following sections
                    will provide a detailed overview of each page.</p>
                <div class="pl-2">
                    <h3 class="mb-0">Customer Contact Details</h3>
                    <p>All pages (Overview, Contact Information, Passenger, Pricing & Billing, and Trips Log)
                        consistently feature the customer's main information on the left-hand side, ensuring ease of
                        access and management. This section displays the following details, some of which can be copied
                        by the admin:</p>
                    <ol>
                        <li>Customer’s Name</li>
                        <li>Registration Date (Added Since)</li>
                        <li>Customer with Passenger Label</li>
                        <li>Mobile Number</li>
                        <li>Email Address</li>
                        <li>Physical Address</li>
                    </ol>
                    <p>A copy icon is available next to the Mobile Number, Email Address, and Physical Address fields
                        for quick copying. Additionally, below the customer’s name, there are three icons (email, and
                        phone) that allow the admin to easily contact the customer via their preferred method.</p>
                    <p>The three vertical ellipses, located on the right-hand side of the customer's contact details
                        window, provide additional options to <b>Edit Customer Data</b> Selecting this option opens a
                        new window where the admin can edit the customer data. This is illustrated in the figure below.
                    </p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-edit-customer-data.webp"
                             width="550px" alt="Edit Customer Data Window">
                        <figcaption>Figure 124: Edit Customer Data Window</figcaption>
                    </figure>
                </div>
                <div class="pl-2">
                    <h3 class="mb-0">Overview</h3>
                    The "Overview" page provides a comprehensive summary of customer information, organized into
                    distinct sections:
                    <p class="font-semibold">&bullet; Customer Metrics:</p>
                    <ol>
                        <li><b>Total Trips Count:</b> Displays the total number of trips completed for the customer.
                        </li>
                        <li><b>Total Distance Traveled:</b> Shows the cumulative distance covered.</li>
                        <li><b>Average Trip Duration:</b> Indicates the average duration of trips.</li>
                    </ol>
                    <p>&bullet; <b>Location and Corporate:</b> Displays the customer's location and corporate
                        information as depicted in Figure 121.</p>
                    <p>An edit icon allows the admin to modify these details, opening a new window for selecting a new
                        location and corporate. This window is shown in Figure 128.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-edit-location-corporate.webp"
                             width="550px" alt="Edit Customer location and corporate">
                        <figcaption>Figure 128: Location and Corporate Edit Window</figcaption>
                    </figure>
                    <p>&bullet; <b>Trip Preferences:</b> Displays the customer's trip preferences, as depicted in Figure
                        121.</p>
                    An edit icon is available for modifying these details, which opens a new window for making changes.
                    This window is shown in Figure 130.
                    <figure>
                        <img src="assets/documentary/Envoy_customers-edit-preferences.webp"
                             width="550px" alt="Edit Customer preferences">
                        <figcaption>Figure 130: Trip Preferences Edit Window</figcaption>
                    </figure>
                    <p>&bullet; <b>Recent Trips:</b> Displays the customer's recent trips in a table, including key
                        details such as location, leg ID, Envoy ID, passenger name, driver name, and more, as shown in
                        Figure 121.</p>
                    <p>&bullet; <b>Action Log:</b> Records the customer’s history, including joining date, status
                        changes, or any other information updates, along with the corresponding dates and admin actions,
                        as shown in the figure 121.</p>
                </div>
                <div class="pl-2">
                    <h3 class="mb-0">Contact Information</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-contact-info-page.webp" width="550px"
                             alt="contact info page">
                        <figcaption>Figure 133: Contact Info Page</figcaption>
                    </figure>
                    <p>This section displays the customer's email addresses, with one email marked as the primary using
                        the ❖ icon. Admins have the option to edit these emails.</p>
                    <p>Clicking the edit icon opens a new window, where the admin can manage the email addresses by
                        editing the primary email, deleting existing ones, or adding new emails. This is depicted in the
                        figure below.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-edit-contact-info.webp" width="550px"
                             alt="edit contact info window">
                        <figcaption>Figure 134: Customer Emails Edit Window</figcaption>
                    </figure>
                </div>
                <div class="pl-2">
                    <h3 class="mb-0">Passengers</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-passenger-list-page.webp" width="550px"
                             alt="passenger list page">
                        <figcaption>Figure 135: Passengers Page</figcaption>
                    </figure>
                    <p>This section displays the customer’s list of passengers, including details such as name, email,
                        phone number, preferred driver, and preferred gender. Admins have the option to add or edit the
                        passenger list. Clicking on <b>"Add & Edit Passengers"</b> opens a new window, as illustrated in
                        Figure 136.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-add-edit-passengers.webp"
                             alt="Add/Edit passenger list">
                        <figcaption>Figure 136: Add/Edit Passenger Window</figcaption>
                    </figure>
                    <p>In this window, admins can add new passengers by entering the following details:</p>
                    <ol>
                        <li><b>Basic Details:</b> Passenger name, email, phone number, trip preferences, passenger
                            preferences, preferred driver, and preferred driver's gender.
                        </li>
                        <li><b>Address:</b> Address type, street 1, street 2, city, county, state, and postal code.</li>
                    </ol>
                    <p>In the second part of the management page, admins can delete previously added passengers by
                        selecting "Delete".</p>
                    <p>Admins can also edit the information of previously added passengers. Clicking the edit icon next
                        to a passenger’s name returns the admin to the management section above, where the passenger’s
                        information is pre-populated, and the <b>"Add the Passenger"</b> button is replaced with <b>"Update
                            Passenger"</b>.</p>
                </div>
                <div class="pl-2">
                    <h3 class="mb-0">Pricing & Billing</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-pricing-billing-page.webp"
                             width="650px" alt="Pricing and billing">
                        <figcaption>Figure 138: Pricing & Billing Page</figcaption>
                    </figure>
                    <p>This page is organized into two key sections:</p>
                    <p class="ml-2"><b>Credit Cards:</b> This section presents the customer's credit card information
                        (for one or multiple cards) visually on a credit card image. The information can be easily
                        updated by clicking on <b>"Manage Credit Card"</b>, which opens a new window, as illustrated in
                        the figure below.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-manage-credit-cards.webp"
                             width="650px" alt="Manage credit cards">
                        <figcaption>Figure 139: Credit Card Management Window</figcaption>
                    </figure>
                    <p>As depicted in Figure 139, admins can add new credit cards by entering details such as card
                        number, expiration date, cardholder name, card nickname, address, billing notes, and CVC number.
                        In the second part of the management page, admins can delete previously added credit cards by
                        selecting "Delete", which triggers a confirmation pop-up, as shown in Figure 140.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-remove-credit-card.webp"
                             width="450px" alt="Manage credit cards">
                        <figcaption>Figure 140: Credit Card Deletion Confirmation Window</figcaption>
                    </figure>
                    <p>Admins can also change the primary credit card, with a confirmation pop-up displayed for this
                        action.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-set-primary-card.webp"
                             width="450px" alt="Manage credit cards">
                        <figcaption>Figure 141: Primary Credit Card Change Confirmation Window</figcaption>
                    </figure>
                    <p class="ml-2"><b>Pricing & Billing:</b> This table provides a detailed comparison of the
                        customer’s hourly rate in USD for each type of vehicle against the default hourly rates
                        established in the system settings. It also displays the minimum hours specified for the
                        customer compared to the minimum hours set in the settings for each vehicle type. The minimum
                        hours refer to the least number of hours applicable to pricing; for instance, if a trip lasts 30
                        minutes but the minimum hour is set at 1 hour, the trip is billed as if it lasted 1 hour for
                        pricing purposes.</p>
                </div>
                <div class="pl-2">
                    <h3 class="mb-0">Trips log</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-trips-log-page.webp"
                             width="650px" alt="Trips log">
                        <figcaption>Figure 142: Trips Log Page</figcaption>
                    </figure>
                </div>
                <h3>&dash; Add a New Customer</h3>
                <div class="pl-2">
                    <p>Clicking on the <b>"Add a New Customer"</b> button in the top right corner of the page displayed
                        in Figure 114 initiates a new window with three or four sections, depending on whether the
                        customer has passengers associated with them. For instance, an insurance company with passengers
                        will have four sections: <b>General, Credit Card, Passenger, and Pricing & Billing</b>. If the
                        customer is the passenger themselves, the same sections will be present except for the Passenger
                        section. The subsequent sections outline the fields that need to be filled in each section.</p>
                    <p>At the top of the page, there is an option labeled <b>"Customer also Passenger"</b>, which, when
                        disabled, displays all four sections, and when enabled, displays only three sections. The
                        following two figures illustrate these two cases.</p>
                    <div class="grid">
                        <div class="col-6">
                            <figure>
                                <img src="assets/documentary/Envoy_customers-add-new-customer.webp"
                                     alt="Add new customer">
                                <figcaption>
                                    Figure 144: New Customer Form – Sections for a Customer with Passengers
                                </figcaption>
                            </figure>
                        </div>
                        <div class="col-6">
                            <figure>
                                <img src="assets/documentary/Envoy_customers-add-new-customer-as-passenger.webp"
                                     alt="Add new customer as passenger">
                                <figcaption>
                                    Figure 145: New Customer Form - Sections for a Customer who is also the Passenger
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    <h3>General</h3>
                    <h4>&dash; Customer is not the Passenger</h4>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-new-customer-general.webp"
                             alt="General step in create customer">
                        <figcaption>
                            Figure 146: New Customer Entry Page – General Section: Customer is not the Passenger
                        </figcaption>
                    </figure>
                    <p>In the <b>"General"</b> section, specifically for cases where the customer is not the passenger,
                        admins need to complete a page, depicted in Figure 146, comprising three main sections: Customer
                        Info, Customer Address, and Trip Preferences. Each section has specific fields to be filled out.
                    </p>
                    <p>For Customer Info, the following fields must be filled:</p>
                    <ol>
                        <li>Customer Name</li>
                        <li>Customer Email: Clicking this field opens a new window, allowing the admin to add multiple
                            emails and select one as the primary. This window is shown in Figure 147.
                        </li>
                        <figure>
                            <img src="assets/documentary/Envoy_customers-edit-contact-info.webp"
                                 width="450px" alt="Customer Email Management">
                            <figcaption>Figure 147: Customer Email Management Window</figcaption>
                        </figure>
                        <li>Phone Number</li>
                        <li>Corporate: selected from a dropdown list of corporates added to the dashboard</li>
                        <li>Location (Company): selected from a dropdown list of companies added to the dashboard</li>
                    </ol>
                    <p>In the Customer Address section, the following fields are required:</p>
                    <ol>
                        <li>Address Type</li>
                        <li>Street 1</li>
                        <li>Street 2</li>
                        <li>City</li>
                        <li>Country</li>
                        <li>State</li>
                        <li>Postal Code</li>
                    </ol>
                    <p>The last section contains a box where the customer's trip preferences can be entered. Once these
                        fields are completed, admins can proceed by clicking on "Move to Credit Card" option to continue
                        the customer data entry process. </p>
                    <h4>&dash; Customer is the Passenger</h4>
                    <p>In the <b>"General"</b> section designated for scenarios where the customer serves as the
                        passenger, admins are required to populate the same page outlined in the preceding section.
                        However, three additional fields must be completed within the first section (Customer Info),
                        namely:</p>
                    <ol>
                        <li>Preferred Driver</li>
                        <li>Preferred Gender</li>
                        <li>Passenger Preferences</li>
                    </ol>
                    <p>The screen is displayed in Figure 148.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-new-customer-as-passenger-general.webp"
                             alt="Create new customer as passenger">
                        <figcaption>
                            Figure 148: New Customer Entry Page – General Section: Customer is the Passenger
                        </figcaption>
                    </figure>
                    <h3>Credit Card</h3>
                    <p>In the credit card section, admins input the card number, and the system automatically recognizes
                        the card type (Mastercard or Visa). They also enter the expiration date, card holder name, card
                        nickname, address, and billing notes. This page, as depicted in Figure 149, is relevant for both
                        passenger and non-passenger customers.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-new-customer-credit-step.webp"
                             alt="Create new customer credit step">
                        <figcaption>
                            Figure 149: New Customer Entry Page – Credit Card Section
                        </figcaption>
                    </figure>
                    <h3>Passengers</h3>
                    <p>The passenger page is structured into two distinct sections. The first section facilitates data
                        entry for passenger information and addresses, while the second section lists the added
                        passengers, comprising their names, cell phone numbers, and email addresses. In the first part,
                        admins input essential details about the passenger, including their full name, phone number,
                        email address, preferred driver, preferred gender, passenger preferences, and trip preferences.
                        This is depicted in Figure 150.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-add-edit-passengers.webp"
                             alt="add/edit passengers">
                        <figcaption>Figure 150: New Customer Entry Page – Passengers Section</figcaption>
                    </figure>
                    <p>In the subsequent part of this section, displayed in Figure 150, admins input the passenger's
                        address details, including address type, street 1, street 2, city, country, state, and postal
                        code.</p>
                    <p>Upon completion, clicking on <b>"Add the Passenger"</b> automatically populates the passenger
                        list below. The list is shown in Figure 150.</p>
                    <h3>Pricing and Billing</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_customers-new-customer-pricing-step.webp"
                             alt="Pricing and billing step">
                        <figcaption>Figure 153: New Customer Entry Page – Pricing and Billing Section</figcaption>
                    </figure>
                    <p>In the pricing and billing section, depicted in Figure 153, admins input the customer hourly rate
                        for each type of car. This rate is compared with the default hourly rate entered in the Settings
                        under Pricing and Billing. This customization is commonly utilized for customers with special
                        offers and pricing agreements. When invoiced, this customized price is applied.</p>
                    <p>Upon clicking <b>"Finish Creating Customer"</b>, a confirmation pop-up appears to verify the
                        intention of adding the new customer.</p>
                </div>
            </section>
        </mat-tab>
        <mat-tab *appHasPermission="'admin/invoices'" #matInvoicingTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="invoice{{matInvoicingTab.isActive?'_open':''}}"/>
                Invoicing
            </ng-template>
            <p>This page allows admins to manage invoices for dropped trips and no-show trips (where clients did not
                show up and the trip was not completed). It is divided into five subsections:</p>
            <ol>
                <li>Trip Finalizing</li>
                <li>Build Invoices (Customers)</li>
                <li>Build Invoice (Corporates)</li>
                <li>Manage Completed Invoices</li>
                <li>Driver Pay</li>
            </ol>
            <p>The following sections will provide detailed information on each subsection.</p>
            <section>
                <h2 class='mb-1'>&dash; Trip Finalizing</h2>
                <p>This subsection displays all dropped and no-show trips, allowing admins to prepare their invoices for
                    payment. On the <b>"Trip Finalizing"</b> page, a table summarizing the main trip information is
                    displayed. The default columns in the table include the following information,with admins being able
                    to customize the table by hiding or showing these and other columns:</p>
                <ol>
                    <li>Reservation ID</li>
                    <li>Customer Name</li>
                    <li>Corporate Name</li>
                    <li>Passenger Name</li>
                    <li>Scheduled Pick up Time</li>
                    <li>Status</li>
                    <li>Driver’s Hourly Pay</li>
                    <li>Driver Name</li>
                    <li>Trip Type</li>
                </ol>
                <p>Different actions can be performed on the table, and each of these actions has been discussed in
                    detail in User interface control section.</p>
                <h3 class='mb-1'>&dash; Interacting with Trip Details and Actions</h3>
                <p>Clicking on the three vertical ellipses next to any trip in the table reveals two options:</p>
                <ol>
                    <li>Trip Full Details</li>
                    <li>Finalize the Trip</li>
                </ol>
                <p>The following sections describe each option in detail.</p>

                <div class="pl-3">
                    <h3 class="mb-1">Trip Full Details</h3>
                    <p>Selecting this option opens a summary window containing essential trip information,
                        including:</p>
                    <ol>
                        <li>Trip Status.</li>
                        <li>Trip Preview on a Map.</li>
                        <li>Client and Driver Data.</li>
                        <li>Trip Preferences.</li>
                        <li>Trip Stations.</li>
                        <li>Actions log: Issue date, and scheduled date.</li>
                    </ol>
                </div>
                <div class="pl-3">
                    <h3 class="mb-1">Finalize the Trip</h3>
                    <p>Clicking on this option opens a new window displaying the following items:</p>
                    <div>
                        <h4>1- Main Trip Information:</h4>
                        <p>The first part presents the actual trip information for dropped trips, including:</p>
                        <ol>
                            <li>Travel time in minutes</li>
                            <li>Trip distance in meters</li>
                            <li>Driver hourly pay in dollars</li>
                        </ol>
                        <p>It is worth noting that the first two items are uneditable, while the last item can be
                            edited.</p>
                        <p>Additionally, the window displays the following uneditable information:</p>
                        <ol>
                            <li>Customer name</li>
                            <li>Passenger name</li>
                            <li>Corporate name</li>
                            <li>Driver name</li>
                        </ol>
                    </div>
                    <div>
                        <h4>2- Trip times:</h4>
                        <p>In the same window, the trip time is displayed in an editable format, both in local time (the
                            time of the PC being used) and the actual trip time, which is the time the trip was
                            conducted according to the trip area. The window shows the actual pickup time and drop-off
                            time, along with the total trip time and distance.</p>
                        <p>Admins can view full trip details by clicking on "Show All Trip Times," which includes:</p>
                        <ol>
                            <li>Scheduled pickup time.</li>
                            <li>Scheduled en route time.</li>
                            <li>On location time (scheduled drop-off point).</li>
                        </ol>
                        <p>By clicking on the edit icon next to "Trip Time", a new window is displayed (as shown in the
                            figure below) where the admin can edit the actual dates/times of the trip, including the
                            actual pickup and drop-off times.</p>
                    </div>
                    <div>
                        <h4>3- Base Pricing Information:</h4>
                        <p>In the system, the trip's total cost is calculated based on two main items: base and
                            detailed.</p>
                        <p>To calculate the Base Trip Cost, the system considers:</p>
                        <ol>
                            <li>Travel Time</li>
                            <li>Extended Wait Time</li>
                            <li>Other Charges</li>
                        </ol>
                        <p>The first item, Travel Time, is calculated as: <b>number of legs × actual trip time (at least
                            the minimum hour) × customer rate.</b></p>
                        <p>It is worth noting that the system automatically inserts this data based on the actual trip
                            information and time shown in Figure 230. The customer rate is automatically inserted based
                            on the customer’s agreed rate for the specific type of car used, or the default values set
                            in the settings if no specific rate is agreed upon. These values are still editable by the
                            admin if necessary.</p>
                        <p>Regarding the second and third items, these are input by the admin to account for extra
                            waiting time and any other charges deemed necessary.</p>
                        <p>Thus, the Base Trip Cost is the sum of the travel time cost, extended wait time cost, and
                            other charges.</p>
                    </div>
                    <div>
                        <h4>4- Detailed Pricing Information:</h4>
                        <p>These are manually input by admins to account for:</p>
                        <ol>
                            <li>Holidays</li>
                            <li>Extra mileage</li>
                            <li>Extra passengers</li>
                            <li>Late cancellation</li>
                            <li>No-show</li>
                        </ol>
                    </div>
                    <div>
                        <h4>5- Total Pay</h4>
                        <p>This is the total cost of the trip, calculated automatically based on the base and detailed
                            pricing inputs.</p>
                        <p>An option is available to charge the customer immediately if their credit card details are
                            provided. When this option, shown in Figure 235, is selected, a window appears as displayed
                            below.</p>
                        <p>The credit card number is automatically filled in from the customer’s data. The admin can
                            then specify the amount to be charged by the credit card, which may be less than the full
                            trip cost if other parties, such as insurance companies, are involved in the payment. It is
                            important to note that enabling the “Charge the customer’s credit card instantly” option
                            changes the button from “Finalize the Trip” (as shown in Figure 235) to “Build Invoice Now”
                            (as shown in Figure 236), requiring the admin to build the invoice directly. If this option
                            is disabled, the admin can either finalize the trip and build the invoice later or build it
                            directly by clicking on the arrow shown in the image below.</p>
                        <p>If the customer has no credit card on file, the system displays "This customer has no credit
                            card", as shown below.</p>
                        pLastly, clicking on either <b>"Build Invoice Now"</b> or <b>"Finalize the Trip"</b> will
                        display a pop-up message to confirm the action. This message is shown in Figure 239<p></p>
                    </div>

                </div>
            </section>
            <section>
                <h2 class='mb-1'>&dash; Build Invoices (Customers)</h2>
                <p>This page is displayed when the admin chooses to finalize the trip without immediately building the
                    invoice. A column listing the names of all customers with finalized trips is shown, allowing the
                    admin to select the customers' names. This selection adds the finalized trips for that customer to a
                    table on the same page. The admin can then build an invoice for a single trip or multiple trips by
                    selecting the desired trips. The default columns in the table include the following information,
                    with admins being able to customize the table by hiding or showing these and other columns:</p>
                <ol>
                    <li>Reservation ID</li>
                    <li>Scheduled Pick up Time</li>
                    <li>Passenger Name</li>
                    <li>Reference No.</li>
                    <li>Trip Payment</li>
                </ol>
                <p>Different actions can be performed on the table, and each of these actions will be discussed in
                    detail in User interface control section.</p>
                <div class="pl-3">
                    <h4>Interacting with Table of Trips:</h4>
                    <p>As previously mentioned, the "Build Invoices" page displays a column listing the names of all
                        customers with finalized trips. The admin can select a customer's name, which will add the
                        finalized trips for that customer to a table on the same page. This table allows the admin to
                        build an invoice for a single trip or multiple trips by selecting the desired entries. Below is
                        a sample table showing a customer's finalized trips.</p>
                </div>
                <div class="pl-3">
                    <h4>Invoice:</h4>
                    <p>By selecting trips, the admin wishes to include in the invoice, the trips are added to the
                        invoice form on the same page. Below is a sample invoice for the selected trips from the
                        table.</p>
                    <p>Clicking the print icon on the right-hand side of Figure 247 opens the printing settings
                        displayed below.</p>
                    <p>On the same page, clicking the icon shown in Figure 249 allows the admin to export the invoice to
                        Excel or PDF. The admin can also select which columns to include in the export. The following
                        images display the icon and the window for selecting columns to export.</p>
                </div>
                <div class="pl-3">
                    <h4>Invoice settings:</h4>
                    <p>Before finalizing and building the invoice, the admin needs to configure the invoice settings on
                        the Build Invoice page. The following selections are required:</p>
                    <ol>
                        <li>Billing Method: Choose from four options:</li>
                        <ul>
                            <li>Process</li>
                            <li>Prepaid</li>
                            <li>Billing</li>
                            <li>Collect</li>
                        </ul>
                        <li>Account: Select from seven options:</li>
                        <ul>
                            <li>Sales Dept</li>
                            <li>Account</li>
                            <li>Credit Card</li>
                            <li>Hotel Master Card</li>
                            <li>Room Charge</li>
                            <li>Voucher</li>
                            <li>Wire</li>
                        </ul>
                        <li>Invoice Actions:</li>
                        <ul>
                            <li>Take No Action</li>
                        </ul>
                        <li>Payment Method: Choose from MasterCard, Manual or no Payment. Depending on the choice:</li>
                        <ul>
                            <li>If MasterCard is selected > Enter the customer's credit card number and specify the
                                amount to be charged
                            </li>
                            <li>If manual is selected > Enter the payment amount and provide the cheque number.</li>
                        </ul>
                    </ol>
                </div>
            </section>
            <section>
                <h2 class='mb-1'>&dash; Build Invoices (Corporates)</h2>
                <p>This page is displayed when the admin chooses to finalize the trip without immediately building the
                    invoice. A column listing the names of all corporates with finalized trips is shown, allowing the
                    admin to select the corporates' names. This selection adds the finalized trips for that corporate to
                    a table on the same page.</p>
                <p>All Actions and options in this section is similar to to the previous section with customers.</p>
                <p>It's important to note that at any stage of building the invoice, whether for a customer or a
                    corporate client, the admin can revert the trip to the finalizing stage if necessary (for example,
                    to correct a mistake) by using the icon highlighted in the image below.</p>
            </section>
            <section>
                <h2 class='mb-1'>&dash; Manage Invoices</h2>
                <p>This page appears when the admin selects “Charge the credit card instantly”, “Build the Invoice now,”
                    or after completing the invoice for a corporate client or customer. On the “Manage Invoices” page, a
                    table summarizing key invoice information is displayed. The table's default columns include the
                    following details, but admins can customize the view by hiding or showing these and other columns as
                    needed:</p>
                <ol>
                    <li>Reservation ID</li>
                    <li>Date of Billing</li>
                    <li>Days in Billing</li>
                    <li>Invoiced to</li>
                    <li>Payment Status</li>
                    <li>Applied Payment</li>
                    <li>Emailed to Customer (Yes/No)</li>
                    <li>Invoiced Total</li>
                </ol>
                <p>Different actions can be performed on the table, and each of these actions will be discussed in
                    detail in User interface control section.</p>
                <h3 class="mb-1">&dash; Interacting with Invoice Details and Actions</h3>
                <p>Clicking on the three vertical ellipses next to any invoice in the table reveals six options:</p>
                <div class="pl-3">
                    <h3>1- Download as PDF:</h3>
                    <p>Selecting this option downloads the invoice in PDF format, as illustrated in the sample invoice
                        below.</p>
                </div>
                <div class="pl-3">
                    <h3>2- Email Invoice:</h3>
                    <p>This option sends the invoice to the customer’s email address linked to their profile. When
                        selected, a new window opens where the admin can choose the customer's email and add any
                        additional notes if needed. The "Email Invoice" button remains disabled until an email address
                        is selected. When “Confirm Emailing” is clicked, a confirmation pop-up message appears, as shown
                        below.</p>
                </div>
                <div class="pl-3">
                    <h3>3- Manual Pay Invoice:</h3>
                    <p>When the "Manual Pay Invoice" option is selected, a new window will open allowing the admin to
                        manually charge the customer. The admin can choose from a list of payment methods, including
                        cash, cheque, voucher, gift certificate, or other. Additionally, the admin must specify the
                        payment amount, and if applicable, provide the cheque number and a description for the
                        transaction. The available payment methods are displayed in a dropdown menu within the payment
                        method cell as shown below.</p>
                    <p>When “Confirm Payment” is clicked, a confirmation pop-up message appears, as shown below.</p>
                </div>
                <div class="pl-3">
                    <h3>4- Charge Credit Card:</h3>
                    <p>When the <b>"Charge Credit Card"</b> option is selected, a new window opens, allowing the admin
                        to charge the customer using their linked credit card selected from a dropdown list. The admin
                        also specifies the amount to be charged.</p>
                    <p>When <b>"Charge Customer Credit Card"</b> is clicked, a new pop-up message is displayed to
                        confirm the intention of charging the customer, as shown below.</p>
                </div>
                <div class="pl-3">
                    <h3>5- Adjust Invoice Pay:</h3>
                    <p>This option allows the admin to adjust the invoice amount by either adding an extra charge or
                        reducing the amount if necessary. The process involves:</p>
                    <ol>
                        <li>Selecting the action: increase or decrease the invoice amount.</li>
                        <li>Choosing the reason from a dropdown list (Edited from adjustment types in the Settings),
                            which includes:
                        </li>
                        <ul>
                            <li>Customer Reimbursement</li>
                            <li>First Ride Discount</li>
                            <li>Gift Certificate</li>
                            <li>Goodwill</li>
                            <li>Deposit Transferred to Another Invoice</li>
                        </ul>
                        <li>Entering the amount to be adjusted, along with a payment description if necessary.</li>
                    </ol>
                    <p>When <b>"Adjust Invoice Detail"</b> is clicked, a new pop-up message is displayed to confirm the
                        intention of adjusting the invoice, as shown below.</p>
                </div>
                <div class="pl-3">
                    <h3>6- Cancel Invoice:</h3>
                    <p>The final option in the menu is to cancel the invoice. When selected, the invoice is dismantled
                        along with all associated payment details, while retaining the ability to rebuild it if
                        necessary. Once <b>"Cancel Invoice"</b> is clicked, the invoice is immediately cancelled.</p>
                    <p>It is important to note the cancelled invoices don’t have the three ellipses next to them.</p>
                </div>

                <div>
                    <h3 class="mb-1">&dash; Interacting with Invoice Details and Actions via Table Selection:</h3>
                    <p>Double-clicking on an invoice in the table opens a new window displaying the invoice, as
                        displayed in Figure 289, with the following details:</p>
                    <ol>
                        <li>Envoy America Details</li>
                        <li>Customer Details: Name, address</li>
                        <li>Invoice Number and Reference</li>
                        <li>Total Invoice Value</li>
                        <li>Invoice Date</li>
                        <li>Trip Information</li>
                        <li>Payment Status: Total invoice value (amount paid / amount not paid yet)</li>
                        <li>Invoice Transaction Log: Transaction ID, transaction type, transaction amount.</li>
                    </ol>
                    <p>Admins have the ability to edit the transaction amount by clicking the edit icon, which opens a
                        new window for editing the invoice value. It is important to note that invoices paid via credit
                        card cannot have their values edited, and thus the edit icon will not be available in those
                        cases.</p>
                    <p>Clicking on the three vertical ellipses on the right-hand side of Figure 289 reveals seven
                        options. Six of these options were discussed in the previous section ( 2.7.4.2.1). The seventh
                        option, "Print Invoice", directly opens the printer settings. The menu options that appear when
                        clicking on the ellipses are displayed below.</p>
                </div>
            </section>
            <section>
                <h2 class='mb-1'>&dash; Driver pay</h2>
                <p>This page is designed to display the payment details for drivers. It features a table with the
                    following default columns, which admins can customize by hiding or showing specific columns:</p>
                <ol>
                    <li>ID</li>
                    <li>Employee ID</li>
                    <li>Driver Name</li>
                    <li>Driver Pay</li>
                    <li>Driver’s Phone Number</li>
                    <li>Payment Status</li>
                </ol>
                <p>Different actions can be performed on the table, and each of these actions will be discussed in
                    detail in User interface control section.</p>
                <div class="pl-3">
                    <h3 class="mb-1">- Export Driver Pay</h3>
                    <p>Admins can export the data from the table into Excel in a format specified by banking
                        requirements.
                        To do this, the admin should select the export icon on the right-hand side of the Driver Pay
                        page.</p>
                    <p>The admin will then need to specify the start and end dates for the reservation period of
                        interest
                        (i.e. based on the scheduled pickup time). The window for selecting the period is illustrated in
                        Figure 298.</p>
                    <h3 class="mb-1">- Driver Pay status</h3>
                    <p>Finally, Figure 300 shows the <b>"Mark as Fully Paid"</b> option, which changes the status of any
                        item to "Fully Paid". Selecting this option triggers a pop-up message to confirm the action.</p>
                    <p>For fully paid trips, the admin can select the "Mark as Not Paid Yet" option, as shown in the
                        figure
                        below. Selecting this option triggers a pop-up message to confirm the action.</p>
                </div>
            </section>
        </mat-tab>
        <mat-tab #matSettingsTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="settings_icon{{matSettingsTab.isActive?'_open':''}}"/>
                Settings
            </ng-template>
            <p>This page is designed for admins to manage various settings for drivers and the mobile app. It includes
                the following information for admins including:</p>
            <section>
                <h2 class="mb-1">Profile Settings</h2>
                The default page displays the admin information, including name and mobile number, which can be edited,
                this is displayed in Figure 344.
                <figure>
                    <img src="assets/documentary/Envoy_settings-profile.webp" alt="Profile Settings">
                    <figcaption>Figure 344: Admin Settings Page</figcaption>
                </figure>
                <p>Additionally, admins can change their password by clicking on <b>"Change Password"</b>, which opens a
                    new
                    screen displayed in Figure 345. Changing the password necessitates inputting the old password and
                    typing the new one twice, followed by confirming this action.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-change-password.webp" width="650px"
                         alt="Change Password">
                    <figcaption>Figure 345: Change Password Screen</figcaption>
                </figure>
            </section>
            <section>
                <h2 class="mb-1">App Configurations</h2>
                <p>The "App Configurations" page allows admins to adjust various settings of the mobile app. This
                    includes configurations for active trips, notifications, and time between trips. Detailed
                    discussions on each of these configurations will follow in the subsequent sections.</p>
                <h4>&dash; Button and Feature Activation Settings</h4>
                <p>In the first set of settings, admins need to define:</p>
                <ol>
                    <li>
                        En Route Time Criteria: The duration for which the driver is permitted to indicate themselves as
                        "on the road" before starting the trip, determining when the "en route" action can be initiated
                        by the driver.
                    </li>
                    <li>
                        Canceling Time Criteria: The time frame, in hours, before the trip's pickup time during which
                        the driver can cancel the trip without notifying the admin.
                    </li>
                    <li>
                        On Location - Distance: The distance, in meters, at which the driver can see the close button.
                    </li>
                    <li>
                        On Location -Minutes: The time, in minutes, during which the driver can see the close button.
                    </li>
                    When the real-time navigation to guide the driver to the next destination is activated, there is a
                    close button that ends the trip with no option to return to the navigation page. Admins can specify
                    when drivers are able to see the close button based on distance and time using settings 3 and 4.
                    <li>Geofencing Auto-Drop Distance: Admins can specify the distance, in meters, for auto-dropping via
                        geofencing. In the mobile app, the "Dropped" functionality is accompanied by a geofencing
                        mechanism. If a driver fails to click on the "Dropped" button, the system automatically updates
                        the status to "Dropped" when the driver travels a distance set by the admin away from the
                        drop-off point.
                    </li>
                </ol>
                <p>These settings are illustrated in Figure 346.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-app-configurations-feature-activation.webp"
                         alt="App Configurations" width="650px">
                    <figcaption>Figure 346: Button and Feature Activation Settings</figcaption>
                </figure>

                <h4>&dash; Feature Control Settings</h4>
                <p>In the second set of settings, admins can enable/disable certain features of the mobile app based on
                    their preferences. These include:</p>
                <ol>
                    <li>Car Auto-Google</li>
                    <li>Signature: The final step in a trip is the "Dropped" status. At this point, both the client and
                        the driver are requested to provide their signatures. Initially, the client is required to sign.
                        If the client refuses or forgets, the driver has the option to declare that the client has
                        forgotten by checking a box and proceeding with the "Next" button. This action leads to a new
                        screen where the driver can provide their signature, ending the entire trip. The availability of
                        this functionality can be customized based on the preferences set by the admin.
                    </li>
                    <li>Force Update -Android: Like other applications, this mobile app undergoes continuous
                        enhancements and updates. Upon the release of a new version, users receive an alert, and admins
                        have the capability to enforce an update, preventing the use of older app versions.
                    </li>
                    <li>Force Update -IOS: Same as the previous point.</li>
                    <li>Avoid Toll Roads: The "En Route" button initiates the driver's journey and displays an
                        interactive map illustrating the route from pick-up to drop-off, with potential stops in
                        between. The displayed route depends on whether the admin has enabled or disabled the "Avoid
                        toll road" feature. If activated, the map provides a toll-free route to adhere to this
                        preference.
                    </li>
                    <li>Disable Wallet App: This option allows the admin to enable or disable the wallet feature within
                        the mobile app.
                    </li>
                    <li>Disable Chat App: This option enables the admin to activate or deactivate the chat feature with
                        the admin within the mobile app.
                    </li>
                    <li>Disable COV Inside App</li>
                    <li>Use App Register</li>
                </ol>
                <p>These settings are illustrated in Figure 347.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-app-configurations-feature-control.webp"
                         alt="App Configurations" width="650px">
                    <figcaption>Figure 347: Feature Control Settings</figcaption>
                </figure>

                <h4>&dash; Notifications</h4>
                <p>The second part of the "App Configurations" section, which is shown in Figure 348, is dedicated to
                    defining when notifications are sent to drivers and admins to alert them for specific events.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-app-configurations-notifications-times.webp"
                         alt="App Configurations" width="650px">
                    <figcaption>Figure 348: Notifications Settings</figcaption>
                </figure>
                <p>The following times should be defined:</p>
                <ol>
                    <li>Driver Notification When Not En-route: This notification is triggered to alert the driver when
                        the remaining time until the scheduled pickup is equal to the duration specified in this
                        setting, and the driver's status is not "En Route".
                    </li>
                    <li>Admin Notification When Not En-route: This notification is triggered to alert the admin when the
                        remaining time until the scheduled pickup is equal to the duration specified in this setting,
                        and the driver's status is not "En Route".
                    </li>
                    <li>Driver Notification When Late for the Estimated Time: The admin specifies when to notify the
                        driver when they are late to the estimated end time, in minutes.
                    </li>
                    <li>Admin Notification When Late for the Estimated Time: The admin specifies when to notify the
                        admin when the driver is late to the estimated end time, in minutes.
                    </li>
                    <li>Admin Notification When En-route: The admin specifies when to notify the admin when the
                        reservation status is En Route and remains specified minutes before the scheduled pickup time.
                    </li>
                </ol>

                <h4>&dash; Time Between Trips</h4>
                <p>In this section, admins define the duration between trips for each type of driver, Contract 99 and
                    COV. This is illustrated in Figure 348.</p>
            </section>
            <section>
                <h2 class="mb-1">Zone Space Time Override</h2>
                <figure>
                    <img src="assets/documentary/Envoy_settings-zone-space-time-override.webp"
                         alt="Zone Space Time Override" width="850px">
                    <figcaption>Figure 350: Zone Space Time Override</figcaption>
                </figure>
                <p>The <b>"Zone Space Time Override"</b> page offers admins the ability to set exceptions for the time
                    interval between two trips taken by a driver, defining the time between finishing one trip and
                    starting the next. Admins can specify these exceptions for particular geographical areas. By
                    clicking on <b>"Add Zone Space Time"</b> shown in Figure 350, they can input the area code and
                    define a specific time interval for that area, as shown in Figure 351.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-zone-space-time-override-add-zone-space-time.webp"
                         alt="Add Zone Space Time" width="650px">
                    <figcaption>Figure 351: Add Zone Space Time</figcaption>
                </figure>
                <p>This customized time interval applies specifically to drivers categorized as <b>"Contract 99"</b>.
                    Consequently, the general time interval specified in the previous page under
                    <b>"App Configurations: Time Between Trips for Contract 99"</b> will not be enforced for this
                    designated area. However, it's important to note that this exception does not extend to COV drivers;
                    the time interval specified in <b>"App Configurations: Time Between Trips for COV"</b> remains valid
                    for them.</p>
                <p>Admins can edit the list at any time by clicking on the <b>"Edit"</b> icon displayed in Figure 349.
                    Additionally, a delete option is provided next to each item, which requires confirmation before
                    removal.</p>
            </section>
            <div>
                <h2 class="mb-1">Dashboard Configurations</h2>
                <p>Dashboard Configurations focuses on the customization of essential options utilized on the dashboard
                    interface. This includes Trip Categories, Trip Equipment, Booking Sources, Vehicle Types, Zones,
                    Adjustment Types, Group Permissions, Service Areas, and Mail Configurations. The Dashboard
                    configurations options are displayed in Figure 352.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-dashboard-configurations.webp"
                         alt="Dashboard Configurations" width="450px">
                    <figcaption>Figure 352: Dashboard Configurations</figcaption>
                </figure>
                <p>These lists of options are important throughout the dashboard, especially when adding new
                    reservations and for invoicing. Next, we'll explore the main screens and key details of these.</p>
                <section class="pl-2">
                    <h3 class="mb-1">Trip Categories</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-trip-categories.webp"
                             alt="Trip Categories" width="650px">
                        <figcaption>Figure 353: Trip Categories</figcaption>
                    </figure>
                    <p>This part lets admins set up different kinds of trips, like visits to the doctor, therapy
                        sessions, or going to the gym. They can do this by clicking <b>"Add New Trip Category"</b> at
                        the top of the page shown in Figure 353. This opens a new screen, as you can see in Figure 354 ,
                        where admins can type in the new category and save it.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-trip-category.webp"
                             alt="Add New Trip Category" width="450px">
                        <figcaption>Figure 354: Add New Trip Category</figcaption>
                    </figure>
                    <p>On the main <b>"Trip Categories"</b> page, you'll see a list of all the categories admins have
                        added. Here, you can edit or delete them. If you click on the "Edit" icon, you can change the
                        details of a category. This is depicted in Figure 355.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-edit-trip-category.webp"
                             alt="Edit Trip Category" width="450px">
                        <figcaption>Figure 355: Edit Trip Category</figcaption>
                    </figure>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the category. These categories are important when you're adding a
                        new trip in the <b>"Trip: Add a Trip"</b> section.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-delete-trip-category.webp"
                             alt="Delete Trip Category" width="450px">
                        <figcaption>Figure 356: Delete Trip Category</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Booking Source</h3>
                    <p>This section allows admins to establish various booking sources for trips. They can do this by
                        clicking <b>"Add New Booking Source"</b> at the top of the page shown in Figure 360.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-booking-source.webp"
                             alt="Add New Booking Source" width="450px">
                        <figcaption>Figure 360: Add New Booking Source</figcaption>
                    </figure>
                    <p>This opens a new screen, same as you have seen in Figure 354, where admins can type in the new
                        source and save it.</p>
                    <p>On the main <b>"Booking Source"</b> page, you'll see a list of all the booking sources admins
                        have added. Here, you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can
                        change the details of a source. same as depicted in Figure 355.</p>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the source. The booking source is important when you're adding a
                        new trip in the <b>"Trip: Add a Trip"</b> section.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Trip Equipment</h3>
                    <p>This part lets admins set up different kinds of equipment needed for a trip, like wheel chair, or
                        walker. They can do this by clicking <b>"Add New Trip Equipment"</b> at the top of the page
                        shown in Figure 357.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-trip-equipment.webp"
                             alt="Trip Equipment" width="650px">
                        <figcaption>Figure 357: Trip Equipment</figcaption>
                    </figure>
                    <p>This opens a new screen, same as you did in Figure 354, where admins can type in the new
                        equipment and save it.</p>
                    <p>On the main <b>"Trip Equipment"</b> page, you'll see a list of all the equipment admins have
                        added. Here, you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can change
                        the details of an equipment. This is same as depicted in Figure 355.</p>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the equipment. The equipment is important when you're adding a
                        new trip in the <b>"Trip: Add a Trip"</b> section.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Vehicle Type</h3>
                    <p>This section enables admins to include various types of vehicles. They can do this by clicking
                        <b>"Add New Vehicle Type"</b> at the top of the page shown in Figure 363.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-vehicle-types.webp"
                             alt="Vehicle Type" width="450px">
                        <figcaption>Figure 363: Vehicle Types</figcaption>
                    </figure>
                    <p>This opens a new screen, as you can see in Figure 364, where admins can enter the new vehicle
                        type, passenger capacity, and bag capacity, upload an image, and set the status to active or
                        inactive before saving.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-vehicle-type.webp"
                             alt="Add New Vehicle Type" width="450px">
                        <figcaption>Figure 364: Add New Vehicle Type</figcaption>
                    </figure>
                    <p>On the main <b>"Vehicle Type"</b> page, you'll see a list of all the vehicle types admins have
                        added. Here, you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can change
                        the details of a vehicle. This is depicted in Figure 365.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-edit-vehicle-type.webp"
                             alt="Update Vehicle Type" width="450px">
                        <figcaption>Figure 365: Editing a Vehicle Type Screen</figcaption>
                    </figure>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the vehicle type. Vehicle types are visible throughout the
                        dashboard.</p>
                </section>

                <section class="pl-2">
                    <h3 class="mb-1">Zones</h3>
                    <p>This section enables admins to include various zones. They can do this by clicking "Add New Zone"
                        at the top of the page shown in Figure 366.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-zones.webp"
                             alt="Zones" width="450px">
                        <figcaption>Figure 366: Zones</figcaption>
                    </figure>
                    <p>This opens a new screen, as you can see in Figure 367, where admins can enter the new zone,
                        county, code, and postal codes before saving.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-zone.webp"
                             alt="Add New Zone" width="450px">
                        <figcaption>Figure 367: Add New Zone</figcaption>
                    </figure>
                    <p>On the main <b>"Zones"</b> page, you'll see a list of all the zones admins have added. Here,
                        you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can change the
                        details of a zone. This is depicted in Figure 368.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-edit-zone.webp"
                             alt="Update Zone" width="450px">
                        <figcaption>Figure 368: Editing a Zone Screen</figcaption>
                    </figure>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the zone. It's important to note that this feature is used to
                        detect the zone of a trip based on the postal code, which helps automate processes within
                        the system rather than handling them manually.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Adjustment Types</h3>
                    <p>This section enables admins to include various adjustment types. They can do this by clicking
                        "Add New Adjustment Type" at the top of the page shown in Figure 369.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-adjustment-types.webp"
                             alt="Adjustment Types" width="450px">
                        <figcaption>Figure 369: Adjustment Types</figcaption>
                    </figure>
                    <p>This opens a new screen, same as you have seen in Figure 354, where admins can enter the new
                        adjustment
                        type before saving.</p>
                    <p>On the main <b>"Adjustment Type"</b> page, you'll see a list of all the adjustment types admins
                        have added. Here, you can edit or delete it. If you click on the <b> "Edit"</b> icon, you can
                        change the details of an adjustment type. This was depicted in Figure 355.</p>
                    <p>If you click on the "Delete" icon, you'll see a screen, shown in Figure 356, asking if you're
                        sure you want to delete the adjustment type. It's important to note that this feature is used in
                        the "Adjust Invoice Pay" process discussed earlier, where invoice amounts are increased or
                        decreased based on the reasons added in this section. The "Adjust Invoice Pay" window is
                        illustrated below.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_manage-invoice-adjust-invoice-pay.webp"
                             alt="Adjust invoice pay" width="450px">
                        <figcaption>Figure 372: Adjust Invoice Pay Window</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Group Permissions</h3>
                    <p>This section is linked to the Team Settings, which will be discussed later in that section. This
                        feature allows admins to create groups for team members (admin members) and assign them specific
                        permissions. Super Admins can create a group and select the permissions to be granted when
                        accessing the dashboard. For example, the super admin can choose whether the group has access to
                        view, edit, or create drivers, customers, and other elements, with a wide range of permissions
                        available for configuration. This can be done by clicking <b>"Add New Group Permission"</b> at
                        the top of the page, as shown in Figure 373.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-group-permissions.webp"
                             alt="Group Permissions" width="450px">
                        <figcaption>Figure 373: Group Permissions</figcaption>
                    </figure>
                    <p>This opens a new screen, as you can see in Figure 374, where admins can enter the new group and
                        select the permissions before saving.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-group-permission.webp"
                             alt="Add New Group Permission" width="450px">
                        <figcaption>Figure 374: Add New Group Permission</figcaption>
                    </figure>
                    <p>On the main <b>"Group Permissions"</b> page, you'll see a list of all the groups admins have
                        added. Here, you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can change
                        the details of a group. This is depicted in Figure 375.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-edit-group-permission.webp"
                             alt="Edit Group Permission" width="450px">
                        <figcaption>Figure 375: Edit Group Permission</figcaption>
                    </figure>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the group.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Service Areas</h3>
                    <p>This section enables admins to include various service areas. They can do this by clicking <b>"Add
                        New Service Area"</b> at the top of the page shown in Figure 376.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-service-areas.webp"
                             alt="Service Areas" width="450px">
                        <figcaption>Figure 376: Service Areas</figcaption>
                    </figure>
                    <p>This opens a new screen, as you can see in Figure 376 where admins can enter the new service
                        area, address, and zone before saving.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-add-new-service-area.webp"
                             alt="Add New Service Area" width="450px">
                        <figcaption>Figure 377: Add New Service Area</figcaption>
                    </figure>
                    <p>On the main <b>"Service Area"</b> page, you'll see a list of all the service areas admins have
                        added. Here, you can edit or delete it. If you click on the <b>"Edit"</b> icon, you can change
                        the details of a service area. This is depicted in Figure 378.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-dashboard-configurations-edit-service-area.webp"
                             alt="Edit Service Area" width="450px">
                        <figcaption>Figure 378: Edit Service Area</figcaption>
                    </figure>
                    <p>If you click on the <b>"Delete"</b> icon, you'll see a screen, shown in Figure 356, asking if
                        you're sure you want to delete the service area. It's important to note that this feature is
                        essential for locating the service area of each COV item added to the system.</p>
                </section>
            </div>
            <div>
                <h2 class="mb-1">Engine Configuration</h2>
                <section class="pl-2">
                    <h3 class="mb-1">Trip Types</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-trip-types.webp"
                             alt="Trip Types" width="650px">
                        <figcaption>Figure 379: Trip Types</figcaption>
                    </figure>
                    <p>This section is tied to the <b>"Trips – Upload CSV File"</b> feature, where admins can import an
                        Excel file containing trip details to automatically generate trips in the dashboard system. The
                        file must include key fields such as pickup and drop-off addresses, appointment times,
                        wheelchair requirements, specialized equipment, and specific keywords within the notes and
                        special needs columns.</p>
                    <p>The system uses this information to automatically create the trip and calculate the scheduled
                        pickup time. Additionally, for trips involving both drop-off and return for a passenger, the
                        system reads the keywords under the notes and special needs sections and matches them with the
                        data in the table above. This helps determine when the driver should return for the
                        passenger.</p>
                    <p>For instance, if the Excel sheet contains the keyword "kidney", the system will automatically
                        assume a 271-minute duration from the time the passenger is dropped off to the time the driver
                        should return to pick them up.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Special Requests</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-special-requests.webp"
                             alt="Special Requests" width="650px">
                        <figcaption>Figure 380: Special Requests</figcaption>
                    </figure>
                    <p>As mentioned earlier in the "Trips – Upload CSV File" section, admins can import an Excel file
                        containing trip details to automatically create trips in the dashboard system. The file must
                        include various fields, such as special needs, wheelchair requirements, and other specialized
                        equipment. The system uses these special needs information, like wheelchair requirements, to
                        adjust the trip time, accounting for the additional time needed for each special requirement.
                        For example, the table above indicates that if a wheelchair is required, an extra 8 minutes will
                        be added to the trip time, considering the distance between the pickup and drop-off points
                        without special needs.</p>
                    <p>Clicking on <b>"Add Special Request"</b> opens a new screen, as shown in Figure 381, where admins
                        can enter the keyword found in the Excel file, trip notes, and the additional time that should
                        be factored in.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-add-special-request.webp"
                             alt="Add Special Request" width="450px">
                        <figcaption>Figure 381: Add Special Request</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">AC2Import</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-ac2import.webp"
                             alt="AC2Import" width="650px">
                        <figcaption>Figure 382: AC2Import</figcaption>
                    </figure>
                    <p>As previously mentioned in the <b>"Trips – Upload CSV File"</b> section, the admin can import an
                        Excel file containing pickup state and county information to automatically create trips.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_reservations-import-reservations.webp"
                             alt="AC2Import" width="450px">
                        <figcaption>Figure 383: Import Reservations Data</figcaption>
                    </figure>
                    <p>The AC2Import section is designed to facilitate this by mapping each state and county to the
                        corresponding customer ID. This ensures that trips are correctly associated with the appropriate
                        customer.</p>
                    <p>When the "Leased Importing" feature is enabled (as shown in the image from the "Trips – Upload
                        CSV File" section), the system reads the Leased Customer ID column to correctly match the trip
                        with the appropriate customer. It's important to note that the leased functionality is tied to
                        customers whose names end with "WAV", indicating they have special pricing—typically higher than
                        customers without "WAV" in their names. For instance, "Huston WAV" is charged higher rates than
                        "Huston" customer. If the leased functionality is enabled but no Leased Customer ID is provided,
                        the system defaults to using the regular customer ID.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Booking Categories</h3>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-booking-categories.webp"
                             alt="Booking Categories" width="650px">
                        <figcaption>Figure 384: Booking Categories</figcaption>
                    </figure>
                    <p>When trips are automatically added, as outlined in the AC2Imports section, the system identifies
                        the customer linked to each trip upon CSV file upload. Once the customer is recognized, the
                        corresponding company (location) and state are also determined. The system then uses the
                        predefined default driver’s pay rates from the table to automatically populate the correct
                        driver pay in the "Add Trip – Driver Rate" section, as depicted in the image below. This ensures
                        accurate calculation of the driver's pay. This feature specifically applies to trips created
                        automatically from CSV files, where the system auto-fills the necessary data.</p>
                    <p>Clicking on "Add Booking Category" opens a new screen, as shown in Figure 386, where admins can
                        enter driver’s pay, company, and state.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-engine-configurations-add-booking-category.webp"
                             alt="Add Booking Category" width="450px">
                        <figcaption>Figure 385: Add Booking Category</figcaption>
                    </figure>
                </section>
            </div>
            <div>
                <h2 class="mb-1">Pricing and Billing</h2>
                <p>Within this section, admins can establish a default pricing structure for corporates, which is
                    utilized for invoicing purposes across various car types. Should no alternative values be specified
                    for a specific corporate or customer within their respective sections, these default prices are
                    applied during the invoicing process. The screen is illustrated in Figure 387. The "Min. Hour" field
                    in the figure indicates that if a trip lasts less than the specified minimum hour, this minimum
                    value will be used in the cost calculation instead of the actual trip duration.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-engine-pricing-and-billing.webp"
                         alt="Pricing and Billing" width="650px">
                    <figcaption>Figure 386: Pricing and Billing</figcaption>
                </figure>
            </div>
            <div>
                <h2 class="mb-1">Team Management</h2>
                <p>In this section, admins can view a list of team members along with their mobile numbers and
                    status—active or inactive. Admins can add new team members by clicking <b>"Add New Team Member"</b>
                    on the main page, shown in Figure 388.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-team-management.webp"
                         alt="Team Management" width="650px">
                    <figcaption>Figure 388: Team Management</figcaption>
                </figure>
                <p>Clicking this option opens a new screen, also shown in Figure 389, where admins can enter the new
                    admin's first and last name, email address, mobile number, and assign a temporary password. This
                    password can be changed later by the new user. The admin also needs to assign the new user to a
                    permission group, which determines the set of permissions granted to them.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-add-new-team-member.webp"
                         alt="Add New Team Member" width="450px">
                    <figcaption>Figure 389: Add New Team Member</figcaption>
                </figure>
                <p>After clicking <b>"Add a New Team Member"</b>, a confirmation pop-up message will appear to confirm
                    the action and send an onboarding email to the user. This is depicted in Figure 390.</p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-add-new-team-member-confirmation.webp"
                         alt="Add New Team Member Confirmation" width="450px">
                    <figcaption>Figure 390: Add New Team Member Confirmation</figcaption>
                </figure>
                <p>On the main <b>"Team"</b> page, admins can perform various actions for each team member by clicking
                    on the three dots next to their name. These actions, depicted in Figure 391, include Reset Password,
                    Update Permissions, Activate or Deactivate, depending on the member's status, and Make Super Admin.
                </p>
                <figure>
                    <img src="assets/documentary/Envoy_settings-team-management-actions.webp"
                         alt="Team Management Actions" width="450px">
                    <figcaption>Figure 391: Team Management Actions</figcaption>
                </figure>
                <section class="pl-2">
                    <h3 class="mb-1">Reset Password</h3>
                    <p>When selected, this option opens a new screen where the admin needs to enter a new password
                        twice. This is depicted in Figure 392.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-reset-team-member-password.webp"
                             alt="Reset Password" width="450px">
                        <figcaption>Figure 392: Reset Password</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Update Permissions</h3>
                    <p>Selecting this option opens a new screen where the super admin can specify the permissions
                        granted to the selected admin. A snapshot of this screen is displayed in Figure 393.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_settings-update-team-member-permissions.webp"
                             alt="Update Permissions" width="450px">
                        <figcaption>Figure 393: Update Permissions</figcaption>
                    </figure>
                    <p>These permissions include the ability to create, edit, delete, or view specific information on
                        the dashboard. Each information item is accompanied by a set of corresponding actions.</p>
                    <p>It's important to note that each admin user adheres to the group permissions assigned to them
                        upon creation. If the super admin wishes to grant specific exceptions to a user's permissions,
                        these adjustments can be made directly from this screen.</p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Activate / Deactivate</h3>
                    <p>Selecting this option opens a new screen where the super admin can either activate (grant access
                        to the dashboard) or deactivate (revoke access to the dashboard) a user. </p>
                </section>
                <section class="pl-2">
                    <h3 class="mb-1">Make Super Admin</h3>
                    <p>Selecting this option allows super admins to elevate an admin's permissions to super admin level,
                        granting them full access and control over all system features.</p>
                </section>
            </div>
        </mat-tab>
        <mat-tab #matInterfaceTab="matTab">
            <ng-template mat-tab-label>
                <mat-icon svgIcon="devices{{matInterfaceTab.isActive?'_open':''}}"/>
                User Interface Controls
            </ng-template>
            <div>
                <p>Under this heading, we have three primary components displayed in the below figure: Messages, System
                    Search, Notification Bell and Tables.</p>
                <figure>
                    <img src="assets/documentary/Envoy_user-interface-controls.webp"
                         alt="User Interface Controls">
                    <figcaption>Figure 394: User Interface Controls</figcaption>
                </figure>
                <p>In the following section, we will provide detailed descriptions of each element.</p>
                <section class="pl-2">
                    <h2 class="mb-1">Messages</h2>
                    <p>Clicking on the "Messages" icon opens a new page where the admin can initiate a direct
                        conversation with the driver. A list of previous chats conducted by the admin is displayed on
                        the left, arranged from latest to oldest, as depicted in Figure 395.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_messaging.webp" alt="Messages" width="850px">
                        <figcaption>Figure 395: Admin Communication Interface</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h2 class="mb-1">Notifications</h2>
                    <p>Clicking on the notification bell displays a list of various notifications, such as urgent trip
                        cancellations, assigned trip cancellations, and alerts when a driver's location has not been
                        updated for two minutes or more. This is depicted in Figure 396.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_notifications.webp" alt="Notifications" width="650px">
                        <figcaption>Figure 396: Notifications</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h2 class="mb-1">System Search</h2>
                    <p>Clicking on the search icon opens a new window where admins can search for any information within
                        the system. The system also displays recent search terms, as shown in the figure below.</p>
                    <p>If there are no matching results, a message will be displayed.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_system-search-no-result.webp" alt="System Search"
                             width="650px">
                        <figcaption>Figure 397: Figure 398: No Matching Results Found</figcaption>
                    </figure>
                    <p>If there are matching results, the system will show the results along with the pages where they
                        can be found (e.g., Trips, Driver’s page, etc.).</p>
                    <figure>
                        <img src="assets/documentary/Envoy_system-search-results.webp" alt="System Search"
                             width="650px">
                        <figcaption>Figure 398: Matching Results Found</figcaption>
                    </figure>
                </section>
                <section class="pl-2">
                    <h2 class="mb-1">Tables Overview</h2>
                    <p>On the main page of each module, a table summarizing the main information is displayed.
                        The default columns in the table include several different information, with admins being able
                        to customize the table by hiding or showing these and other columns.</p>
                    <p>Different actions can be performed on the table as illustrated in the figure bellow, and each of
                        these actions will be discussed in detail in Customizing and Manipulating Data views
                        section.</p>
                    <figure>
                        <img src="assets/documentary/Envoy_tables-overview.webp" alt="Tables Overview"
                             width="650px">
                        <figcaption>Figure 399: Tables Overview</figcaption>
                    </figure>
                    <div class="pl-1">
                        <h2>&dash; Search</h2>
                        <p>A search bar has been added to the page, enabling admins to search for specific drivers by
                            entering the driver’s name, email, phone number, or any relevant information. The search bar
                            is visible in Figure 399.</p>
                    </div>
                    <div class="pl-1">
                        <h2>&dash; Customize Table View</h2>
                        <figure>
                            <img src="assets/documentary/Envoy_tables-customize-table-view.webp"
                                 alt="edit table columns view" width="650px">
                            <figcaption>Figure 400: Customize Table View Action</figcaption>
                        </figure>
                        <p>Clicking on the edit icon, illustrated in Figure 400 and located on the user’s right-hand
                            side, opens a new window. This window allows admins to show and hide columns according to
                            their preferences, as depicted in Figure 401.</p>
                        <div class="grid">
                            <figure class="m-0 col-6">
                                <img src="assets/documentary/Envoy_drivers-view-columns.webp"
                                     alt="edit table columns view">
                                <figcaption>Figure 401: Customize Table View Window</figcaption>
                            </figure>
                            <figure class="m-0 col-6">
                                <img src="assets/documentary/Envoy_drivers-edit-columns-view.webp"
                                     alt="edit table columns view">
                                <figcaption>Figure 402: Rearrange by Dragging & Dropping</figcaption>
                            </figure>
                        </div>
                        <p>A checkbox is added next to each column, allowing admins to configure column visibility
                            by checking or unchecking. Additionally, users can rearrange the order of columns by
                            simply dragging and dropping, as demonstrated in the figure above.</p>
                        <p>The View you modify will be saved as the default view each time you open this table.</p>
                    </div>
                    <div class="pl-1">
                        <h2>&dash; Table Filters</h2>
                        <p>Clicking on the filter icon, illustrated in Figure 403 and located on the user’s right-hand
                            side, opens a new window.</p>
                        <figure>
                            <img src="assets/documentary/Envoy_tables-filters.webp"
                                 alt="edit table columns view" width="650px">
                            <figcaption>Figure 403: Table Filters</figcaption>
                        </figure>
                        <p>The new window allows admins to filter table records based on several keys changable
                            according to each table. Admins can use multiple filters simultaneously or filter
                            data based on only one criterion, though only one option from each criterion can be
                            selected.
                        </p>
                        <p>Users can either select <b>"Apply Filter"</b> if they wish to apply it, or <b>"Reset
                            Filter"</b> if they want to restore the table to its unfiltered state.</p>
                    </div>
                    <div class="pl-1">
                        <h2>&dash; Import / Export</h2>
                        <div class="grid">
                            <figure class="m-0 col-6">
                                <img src="assets/documentary/Envoy_tables-import.webp"
                                     alt="edit table columns view">
                                <figcaption>Figure 404: Import Records to the table</figcaption>
                            </figure>
                            <figure class="m-0 col-6">
                                <img src="assets/documentary/Envoy_tables-export.webp"
                                     alt="edit table columns view">
                                <figcaption>Figure 402: Export Table records</figcaption>
                            </figure>
                        </div>
                        <p>Clicking on the Import icon, illustrated in Figure 404 and located on the user’s right-hand
                            side, opens a new window. This window allows admins to import data from a CSV file. Also
                            user can export data to a CSV file.</p>
                    </div>
                </section>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
