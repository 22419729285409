<div class="login">
    <div class="w-full">
        <form [formGroup]="loginForm" class="login-form" autocomplete="off">
            <input autocomplete="false" name="hidden" type="hidden" class="hidden">
            <div class="w-full">
                <mat-label>Phone number</mat-label>
                <input class="w-11" matInput id="phone" type="tel" placeholder="Phone" name="phone"
                       formControlName="phone" required/>
            </div>
            <div class="mt-3 w-full">
                <mat-label>Password</mat-label>
                <input class="w-11" matInput id="password" placeholder="Password" name="password"
                       [type]="hide ? 'password' : 'text'" formControlName="password" required/>
                <mat-icon class="eye-password" matSuffix
                          (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}
                </mat-icon>
            </div>
        </form>
    </div>
    <div class="flex center">
        <button class="w-11 button" mat-button [ngClass]="{'button-enabled' : loginFormIsValid}"
               (click)="onSubmit()" [disabled]="!loginFormIsValid">Sign In</button>
    </div>
</div>
