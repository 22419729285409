import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dayDate',
  standalone: true
})
export class DayDatePipe implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string): string {
    if (!value) return '';

    const today = new Date();
    const dateValue = new Date(value);
    const datePipe = new DatePipe('en-US');

    // Clear the time portion from the dates for accurate comparison
    today.setHours(0, 0, 0, 0);
    dateValue.setHours(0, 0, 0, 0);

    const timeDiff = today.getTime() - dateValue.getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    if (timeDiff === 0) {
      return 'Today. ' + datePipe.transform(value, 'HH:mm');
    } else if (timeDiff === oneDay) {
      return 'Yesterday. ' + datePipe.transform(value, 'HH:mm');
    } else if(timeDiff < oneDay * 4) {
      return datePipe.transform(value, 'EEEE. HH:mm') ?? '';
    } else {
      // Return standard date format (can customize the format if needed)
      return datePipe.transform(value, format, timezone) ?? '';
    }
  }

}
