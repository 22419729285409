import { IStaffPermission } from '@modules/settings/models';

export class User {
  id: number;
  phone_number: string;
  email: string;
  first_name: string;
  last_name: string;
  type: string;
  status: string;
  image: string;
  user_permissions?: IStaffPermission[];

  constructor(currentUser: any) {
    this.id = currentUser.id;
    this.first_name = currentUser.first_name;
    this.last_name = currentUser.last_name;
    this.email = currentUser.email;
    this.phone_number = currentUser.phone_number;
    this.status = currentUser.status;
    this.type = currentUser.type;
    this.image = currentUser.image;
    this.user_permissions = currentUser.user_permissions;
  }

}
