import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { EPermissionMethod } from '@modules/settings/models';
import { map } from 'rxjs';

export const permissionGuard = (_method = EPermissionMethod.view, _path?: string): CanMatchFn => {
  return (route, segments) => {
    const auth = inject(AuthService);
    const path = _path || segments[0].path;
    const method = _method || EPermissionMethod.view;
    return auth.userData.pipe(map(user => {
      if (user && user.type === 'ADMIN') {
        return true;
      } else if (user && user.user_permissions) {
        const _index = user.user_permissions.findIndex((_per) => {
          return _per.permission.permission_on === `admin/${path}` && _per.method_allowed.includes(method);
        });
        return _index > -1;
      } else {
        return false;
      }
    }));
  }
}
