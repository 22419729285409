import { AbstractControl, ValidatorFn } from '@angular/forms';

const emailReg = new RegExp(
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
);

export const emailValidator: ValidatorFn = (control: AbstractControl<string>) => {
  if (control.value) {
    return control.value.toLowerCase().match(emailReg) ? null : { email: true };
  } else {
    return null;
  }
}
