import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';

export const authGuard: CanMatchFn = (route, segments) => {
  const auth = inject(AuthService);
  if (!auth.isAuthenticated()) {
    return auth.logout().then(res => false);
  } else {
    return true;
  }
};
