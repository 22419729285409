<h1 mat-dialog-title>Confirm Delete account</h1>
<div mat-dialog-content [formGroup]="deleteAccountForm">
  <br>
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Why you want to leave us</mat-label>
    <input matInput name="reason" formControlName="reason">
    <mat-error *ngIf="deleteAccountForm.get('reason')?.errors?.['required']">The Field is Required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Verify Code sent to verify delete</mat-label>
    <input matInput name="verify_code" formControlName="verify_code">
    <mat-error *ngIf="deleteAccountForm.get('verify_code')?.errors?.['required']">The Field is Required</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="two-fields">
  <button class="col-6" mat-raised-button color="primary" (click)="onSubmit()">Delete Account</button>
  <button class="col-6 button" mat-button color="primary" mat-dialog-close>Cancel</button>
</div>
