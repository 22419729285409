import { Component, Input, OnInit } from '@angular/core';
import { IChatDriver, IChatItem } from '@shared/models';
import { ChatService } from '@core/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-driver-chat-item',
  templateUrl: './driver-chat-item.component.html',
  styleUrl: './driver-chat-item.component.scss'
})
export class DriverChatItemComponent implements OnInit {
  @Input({ required: true }) chat!: IChatItem;
  openedChat$ = this.chatService.selectedDriver;
  driver: IChatDriver | null = null;
  _loading = true;

  constructor(private chatService: ChatService) {
  }

  ngOnInit() {
    if (this.chat.driver) {
      this.driver = this.chat.driver;
      this._loading = false;
    } else {
      this.chatService.getDriver(this.chat.driverId)
        .pipe(tap(() => this._loading = false))
        .subscribe(data => this.driver = data);
    }
  }

  openDriverChat() {
    if (!this.driver) return;
    this.chatService.selectedDriver = this.driver;
  }
}
