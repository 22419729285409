<div class="driver-chats">
    <div class="grid grid-nogutter gap-3">
        <div class="col-4">
            <app-driver-chat-list/>
        </div>
        <div class="col">
            @if (!selectedDriver) {
                <div class="landing-view">
                    <mat-icon svgIcon="users-chatting"/>
                    <h4 class="font-bold">Messages</h4>
                    <p>Select any message from the list to represent it here.</p>
                </div>
            } @else {
                <div class="chat-view">
                    <div class="chat-view__header">
                        <div class="driver-info">
                            <img class="driver-avatar" alt="driver avatar"
                                 [src]="selectedDriver.image || '/assets/blank_user.png'">
                            <h3 class="mb-0 text-lg">{{ selectedDriver.first_name }} {{ selectedDriver.last_name }}</h3>
                        </div>
                        <div class="driver-actions">
                            @if (selectedDriver.active_trip) {
                                <span class="active-trip">
                                    This driver going through a trip
                                    <a [routerLink]="['/reservations', 'live', selectedDriver.active_trip]"
                                       [queryParams]="{tab: 'details'}" class="active">Open it</a>
                                </span>
                                <mat-divider vertical/>
                            }
                            <a href="tel:{{selectedDriver.phone_number}}" mat-icon-button>
                                <mat-icon svgIcon="call_outlined"/>
                            </a>
                        </div>
                    </div>
                    <div #chatView class="chat-view__messages">
                        @if (!messages) {
                            @for (item of createSkeleton(9); track item; let even = $even) {
                                <div class="message-row">
                                    <div class="message pulse {{even ? 'message-in' : 'message-out'}}"></div>
                                </div>
                            }
                        } @else {
                            @for (message of messages; track message.message_id; let i = $index) {
                                @if (checkDateDiffs(i)) {
                                    <div class="message-date">
                                        <mat-divider class="flex-1"/>
                                        <span class="px-2">
                                            {{ (message.date.toMillis() | dayDate).split('.')[0] }}
                                        </span>
                                        <mat-divider class="flex-1"/>
                                    </div>
                                }
                                <div class="message-row">
                                    <div class="message" [matTooltip]="'By: ' + message.sender_name | titlecase"
                                         [ngClass]="message.sender_id === selectedDriver.id ? 'message-in' : 'message-out'"
                                         [matTooltipDisabled]="message.sender_id === selectedDriver.id"
                                         matTooltipPosition="left"
                                    >
                                        {{ message.message }}
                                        <span class="time">{{ message.date.toDate() | date: 'HH:mm' }}</span>
                                    </div>
                                    <span class="message-tail"><mat-icon svgIcon="message-tail"/></span>
                                </div>
                            }
                        }
                    </div>
                    <div class="chat-view__input">
                        <mat-form-field appearance="outline" class="sm-form-field flex-1">
                            <input matInput [formControl]="msgCtrl" placeholder="Type your message"
                                   (keyup.enter)="sendNewMessage()">
                        </mat-form-field>
                        <button (click)="sendNewMessage()" mat-icon-button>
                            <mat-icon svgIcon="send_sms_icon"/>
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
