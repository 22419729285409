import { ValidatorFn, Validators } from '@angular/forms';

export const postalCodeValidator = ((): ValidatorFn => {
  return Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
})();

// AA, AE, AP states are military states and not accepted
export const stateValidator = ((): ValidatorFn => {
  return Validators
    .pattern(/^(?:AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)$/i);
})();