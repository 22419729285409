import { ValidatorFn } from '@angular/forms';

export const priceValidator: ValidatorFn = (control) => {
  const minimum_hour = control.get('minimum_hour')?.value;
  const priceCtrl = control.get('hourly_price');
  if (minimum_hour && (!priceCtrl || !priceCtrl.value)) {
    priceCtrl?.markAllAsTouched();
    priceCtrl?.setErrors({ required: true });
    return { price_required: true };
  } else {
    priceCtrl?.setErrors(null);
  }
  return null;
}