import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { DataService } from '@core/services';
import { IDriverSummary } from '@modules/driver/models';

export const driverSummaryResolver: ResolveFn<IDriverSummary> = (route, state) => {
  const driverId = route.parent?.params['driverId'];
  const dataService = inject(DataService);
  return dataService.get(`admin/drivers/${driverId}/general`).then(response => {
    if (response.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response);
    }
  });
};
