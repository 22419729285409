import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, UtilsService } from '@core/services';
import { AuthService } from '@modules/auth/services/auth.service';
import { INotification } from '@shared/models';
import {
  BehaviorSubject,
  catchError,
  interval,
  merge,
  Observable,
  of,
  switchMap,
  takeWhile,
  withLatestFrom
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  totalCount = 0;
  private _loadedNotifications: INotification[] = [];
  private notificationStream$!: Observable<INotification[]>;
  private notificationPage$ = new BehaviorSubject(1);
  private newNotification$ = new BehaviorSubject(false);

  constructor(private http: HttpClient, private dataService: DataService,
              private router: Router, private utils: UtilsService, private auth: AuthService) {
    this.initNotificationStream();
  }

  get notifications$() {
    return this.notificationStream$;
  }

  get unreadNotification$() {
    return this.newNotification$.asObservable();
  }

  private _notificationsOpened = false;
  get notificationsOpened() {
    return this._notificationsOpened;
  }

  set notificationsOpened(value) {
    if (!value) {
      this._loadedNotifications = [];
      this.notificationPage$.next(1);
    }
    this._notificationsOpened = value;
  }

  triggerNotificationLoad(page: number) {
    this.notificationPage$.next(page);
  }

  /**
   * Mark the notification clicked as read and navigate to show more details
   * @param notification
   */
  readNotification(notification: INotification) {
    if (notification.read) {
      return this.openNotification(notification);
    }
    return this.dataService.get(`notifications/${notification.id}/read`)
      .then(data => {
        if (data.status_code === 200) {
          this.openNotification(notification);
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
  }

  private initNotificationStream() {
    // Create a timer that emits every 5 seconds on page 1
    const timer$ = interval(10000).pipe(
      map(() => 1),
      filter(() => !this.notificationsOpened)
    );
    // trigger notification stream every 10s or when page change while the user is an ADMIN
    const trigger$ = merge(timer$, this.notificationPage$);
    this.notificationStream$ = trigger$.pipe(
      withLatestFrom(this.auth.userData),
      takeWhile(([_page, _user]) => _user !== null && _user.type === 'ADMIN'),
      switchMap(([page]) => this.fetchNotifications(page))
    );
  }

  private fetchNotifications(page: number) {
    return this.http.get<any>(`admin/users/notifications?page=${page}&page_size=10`).pipe(
      catchError(err => {
        return of({ status_code: 500, data: [] });
      }),
      map(res => {
        this.totalCount = res.total_count ?? 0;
        if (res && res.status_code === 200 && Array.isArray(res.data)) {
          const _hasUnread = res.data.some((_i: INotification) => !_i.read);
          if (page === 1 && _hasUnread) {
            this.newNotification$.next(true);
            if (this._loadedNotifications.length) {
              (this._loadedNotifications[0].id !== res.data[0].id) && this.playNotificationSound();
            }
          }
          if (!_hasUnread) this.newNotification$.next(false);
          return this._loadedNotifications = page === 1 ? res.data : this._loadedNotifications.concat(res.data);
        }
        return [];
      })
    );
  }

  private openNotification(notification: INotification) {
    if (notification.key === 'PROFILE') {
      this.router.navigate(['/drivers'], {
        queryParams: { search: notification.body.split(' Driver,')[0] }
      });
    } else if (notification.reservation_id) {
      this.router.navigate(['/reservations'], {
        queryParams: { search: notification.reservation_id }
      });
    } else {
      //  TODO: something to handle that I don't know
    }
  }

  private playNotificationSound() {
    const sound = new Howl({
      src: ['assets/notification.wav'],
    });
    sound.play();
  }
}
