import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TwoFactorComponent } from "../two-factor/two-factor.component";
import { DataService, UtilsService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  private _subscription!: Subscription;
  loginForm = this.initFormGroup();
  invalidCredentials: boolean;
  maximumAllowedAttempts: boolean;
  loginFormIsValid: boolean = false;
  hide: boolean;

  constructor(public dialog: MatDialog, private dataService: DataService,
              private utils: UtilsService, private fb: FormBuilder) {
    this.invalidCredentials = false;
    this.maximumAllowedAttempts = false;
    this.hide = true;
  }

  ngOnInit() {
    this._subscription = this.loginForm.valueChanges.subscribe(() => {
      this.checkLoginFormValidity();
    });
  }

  onSubmit(): void {
    const payload = {
      password: this.loginForm.get('password')?.value,
      phone_number: this.loginForm.get('phone')?.value
    };
    this.dataService.post('admin/login/send_verify', payload).then((data) => {
      if (data.status_code === 200) {
        this.verifyLogin();
      } else {
        this.utils.openSnackBar(data.data.message, 5000, 'error');
      }
    });
  }

  verifyLogin() {
    this.dialog.open(TwoFactorComponent, {
      panelClass: 'envoy-dialog',
      disableClose: true,
      width: '550px',
      data: { loginForm: this.loginForm }
    }).afterClosed().subscribe((status) => {
      if (status == 'success') {
        this.dialog.closeAll();
      }
    });
  }

  checkLoginFormValidity() {
    const phoneNumberControl = this.loginForm.get('phone');
    const passwordControl = this.loginForm.get('password');

    if (phoneNumberControl !== null && passwordControl !== null &&
      phoneNumberControl.value !== null && passwordControl.value !== null) {
      const phoneNumber = phoneNumberControl.value as string;
      const password = passwordControl.value as string;
      const isValidPhoneNumber = phoneNumber.trim().length > 0;
      const isValidPassword = password.trim().length > 0;
      this.loginFormIsValid = isValidPhoneNumber && isValidPassword;
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private initFormGroup() {
    return this.fb.nonNullable.group({
      password: [null, [Validators.required]],
      phone: [null, [Validators.required]]
    });
  }
}
