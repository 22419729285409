import { AbstractControl } from '@angular/forms';

const phoneReg = new RegExp(/^(\+1)? ?\(?[1-9]\d{2}\)?[- ]?\d{3}[- ]?\d{4}$/);

export const phoneValidator = (control: AbstractControl<string>) => {
  if (control.value) {
    return control.value.match(phoneReg) ? null : { invalid: true };
  } else {
    return null;
  }
}