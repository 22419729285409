import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@modules/auth/services/auth.service';
import { DataService, UtilsService } from '@core/services';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  question: string;
  class: any;
  icon: any;
  body: any;
  primary_button = "Yes";
  secondary_button = "Cancel";
  api: string;
  method: string;
  post_body: {};
  newStyle!: boolean;
  note: string | undefined;
  condition: string | undefined;
  condition_checked = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private utils: UtilsService,
    private authService: AuthService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.class = this.data.class;
    this.icon = this.data.icon;
    this.title = this.data.title;
    this.body = this.data.body;
    this.question = this.data.question;
    if (this.data.primary_button) {
      this.primary_button = this.data.primary_button;
    }
    if (this.data.secondary_button) {
      this.secondary_button = this.data.secondary_button;
    }
    this.newStyle = data.newStyle;
    this.body = this.data.body;
    this.api = this.data.api;
    this.post_body = this.data.post_body;
    this.method = this.data.method;
    this.condition = this.data.condition;
    this.note = this.data.note;
  }

  cancel(): any {
    if (this.api !== 'close') {
      this.dialogRef.close('cancel');
    } else {
      this.dialogRef.close('no');
    }
  }

  accept(): any {
    if (this.method === 'put') {
      this.dataService.put(this.api, this.post_body).then((data) => {
        if (data.status_code === 200) {
          this.dialogRef.close('accept');
        } else {
          this.utils.openSnackBar('INTERNAL_SERVER_ERROR', 5000, 'error');
        }
      });
    } else if (this.api !== 'close') {
      this.dataService.post(this.api, this.post_body).then((data) => {
        if (data.status_code === 200) {
          this.dialogRef.close('accept');
        } else {
          this.utils.openSnackBar('INTERNAL_SERVER_ERROR', 5000, 'error');
        }
      });
    } else {
      if (this.condition && this.condition_checked.value) {
        this.dialogRef.close('yes_checked');
      } else {
        this.dialogRef.close('yes');
      }
    }
  }
}
