import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-system-search',
  templateUrl: './system-search.component.html',
  styleUrls: ['./system-search.component.scss']
})
export class SystemSearchComponent {
  searchCtrl = new FormControl('');
  searchItems = [
    { name: 'Trips', icon: 'trips_icon', url: '/reservations' },
    { name: 'Drivers', icon: 'captain-hat-profile', url: '/drivers' },
    { name: 'Customers', icon: 'user-tag', url: '/profiles/customers' },
    { name: 'Corporates', icon: 'buildings', url: '/profiles/corporates' },
    { name: 'Location (Company)', icon: 'story', url: '/profiles/companies' },
  ];

  constructor(private router: Router, private dialog: MatDialog) {
  }

  search(url: string) {
    this.dialog.closeAll();
    if (this.searchCtrl.value) {
      this.router.navigate([url], {
        queryParams: { search: this.searchCtrl.value }
      });
    }
  }
}
