import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { DataService } from '@core/services';
import { DriverModel } from '@modules/driver/models';

export const driverResolver: ResolveFn<DriverModel> = (route, state) => {
  const driverId = route.params['driverId'];
  const dataService = inject(DataService);
  return dataService.get(`admin/drivers/${driverId}`).then(response => {
    if (response.status_code === 200) {
      return new DriverModel(response.data);
    } else {
      throw new Error(response);
    }
  });
};
